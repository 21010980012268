import { jsx as _jsx } from "react/jsx-runtime";
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * SortCaret is a small UI component that renders a sort direction icon
 * (either up or down) based on the current sorting direction.
 * It is typically used in table headers to indicate active sorting.
 */
const SortCaret = ({ direction }) => {
    // If no direction is provided, don't render anything
    if (!direction)
        return null;
    // Select the correct icon based on the sort direction
    const icon = direction === 'asc' ? faCaretUp : faCaretDown;
    // Render the FontAwesomeIcon with some left margin
    return _jsx(FontAwesomeIcon, { className: "ms-2", icon: icon });
};
export { SortCaret };
