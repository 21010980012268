import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldConfig } from 'c1g-ui-library';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form, FormControl, InputGroup } from 'react-bootstrap';
import { getFieldConfigByResourceName } from '../utils/utils';

interface SearchInputProps {
  onSearch: (data: { query: string, filter: string | undefined }) => void;
  dropdownItems?: string[];
  reset?: boolean;
  hasFilters?: boolean;
  initialSearchTerm?: string
  initialSearchColumn?: string
  fieldConfigs?: FieldConfig[]
}

/**
 * SearchInput Component
 * 
 * This component provides a search input with optional dropdown filters and validation for 
 * minimum search length. It is designed for flexible search scenarios where users can select 
 * specific fields to filter by, and it includes built-in error handling and reset functionality.
 * 
 * Props:
 * - `onSearch`: Callback function that sends the search data (query and filter) to the parent component.
 * - `dropdownItems`: Array of string options for the dropdown filter (e.g., field names).
 * - `reset`: Boolean flag to reset the search input and dropdown to their initial states.
 * - `hasFilters`: Boolean to display the dropdown filter when true.
 * - `initialSearchTerm`: Optional initial search term to populate the input on load.
 * - `initialSearchColumn`: Optional initial filter to set the dropdown to a specific field.
 * - `fieldConfigs`: Array of `FieldConfig` objects for dynamic dropdown labels.
 * 
 * Example usage:
 * ```tsx
 * <SearchInput
 *   onSearch={(data) => console.log("Search Data:", data)}
 *   dropdownItems={["name", "email", "phone"]}
 *   reset={false}
 *   hasFilters={true}
 *   initialSearchTerm="example"
 *   initialSearchColumn="name"
 *   fieldConfigs={fieldConfigData}
 * />
 * ```
 * Keyboard Shortcuts:
 * - `Enter`: Executes search with the current term and filter.
 * - `Escape`: Clears the search term and resets the filter.
 */
const SearchInput: React.FC<SearchInputProps> = ({ onSearch, dropdownItems = [], reset, hasFilters, initialSearchTerm, initialSearchColumn = 'all', fieldConfigs }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInputError, setSearchInputError] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = useState<string>();

  const handleSetSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (searchInputError) {
      setSearchInputError(false);
    }
  };

  // performs the search and populates search data to the parent component
  const handleSearch = () => {
    if (!searchValue.trim()) {
      onSearch({ query: '', filter: 'all' });
    } else if (searchValue.length >= 3) {
      setSearchInputError(false);
      onSearch({ query: searchValue, filter: dropdownValue });
    } else {
      setSearchInputError(true);
    }
  };

  const handleSelectDropdown = (eventKey: string | null) => {
    if (eventKey) {
      setDropdownValue(eventKey);
      onSearch({ query: searchValue, filter: eventKey });
    }
  };

  useEffect(() => {
    if (!isFocused) {
      // use initial search info via component props
      if (dropdownValue || searchValue) {
        setSearchValue(initialSearchTerm || '');
        setDropdownValue(initialSearchColumn || 'all');
        onSearch({ query: initialSearchTerm || '', filter: initialSearchColumn || 'all' });
      }
    }
  }, [searchValue, dropdownValue]);

  useEffect(() => {
    if (reset) {
      setSearchValue('');
      setDropdownValue('all');
      setSearchInputError(false);
    }
  }, [reset]);

  return (
    <InputGroup className="search-input">
      {hasFilters && (
        <DropdownButton
          title={dropdownValue === 'all'
            ? 'Alle'
            : getFieldConfigByResourceName(fieldConfigs, dropdownValue!)?.fieldLabel || 'Filter'}
          onSelect={handleSelectDropdown}
        >
          <Dropdown.ItemText>
            <p className="mb-0 custom-font-size-08">
              FELD ZUM DURCHSUCHEN AUSWÄHLEN
            </p>
          </Dropdown.ItemText>
          <Dropdown.Item eventKey={'all'} key={'all'}>
            Alle
          </Dropdown.Item>
          {dropdownItems.map((item, index) => (
            <Dropdown.Item eventKey={item} key={index}>
              {getFieldConfigByResourceName(fieldConfigs, item)?.fieldLabel}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}

      <FormControl
        className="text-black custom-search-input"
        type="search"
        placeholder="Search..."
        value={searchValue}
        onChange={handleSetSearchValue}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          } else if (e.key === 'Escape') {
            setSearchValue('');
            setDropdownValue('all');
            onSearch({ query: '', filter: 'all' });
            setSearchInputError(false);
          }
        }}
      />
      <Button
        type="button"
        variant=""
        onClick={handleSearch}
        className="border-left-0 text-black bg-grey solid-simple-border"
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </Button>
      {searchInputError && (
        <Form.Control.Feedback type="invalid" className='d-block'>
          Suchebegriff muss min. 3 Zeichen lang sein
        </Form.Control.Feedback>
      )}
    </InputGroup>
  );
};

export default SearchInput;
