import { faBuilding, faCircleUser, faFileContract, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { FieldConfig } from 'c1g-ui-library';
import { Location, Partner, } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { formatDateWithoutTime, getFieldConfigByResourceName } from '../../../utils/utils';
import StatusIndicator from '../../StatusIndicator';

interface StatusDisplayProps {
    fieldConfigs: FieldConfig[];
    location: Location
    partner: Partner
}

/**
 * StatusDisplay Component
 * 
 * This component displays key status information for a location, including publication and certification 
 * statuses, contract information, office type, and partner association. Status icons are displayed with 
 * color indicators to highlight active statuses, contractual details, and important dates.
 * 
 * Props:
 * - `fieldConfigs`: Array of `FieldConfig` objects for dynamic data display.
 * - `location`: The `Location` object containing various status and configuration details.
 * - `partner`: The `Partner` object providing partner-related information.
 * 
 * Example usage:
 * ```tsx
 * <StatusDisplay fieldConfigs={fieldConfigData} location={locationData} partner={partnerData} />
 * ```
 */
const StatusDisplay: React.FC<StatusDisplayProps> = ({ location, fieldConfigs, partner }) => {
    return (
        <div className="d-flex flex-column flex-lg-row align-items-lg-center text-nowrap">
            <StatusIndicator type="publication" status={location.status} fieldConfigs={fieldConfigs} resourceName="locations.status" />
            <StatusIndicator type='certification' status={location.statusCertification} fieldConfigs={fieldConfigs} resourceName="locations.statusCertification" />

            <span className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${location.statusContract === 13 ? 'text-success' : 'text-warning'}`}>
                <FontAwesomeIcon width={15} icon={faFileContract} className="me-2" />
                {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.statusContract')?.options ?? {}, location?.statusContract.toString())}
            </span>

            {location?.contractEnd && location?.contractEnd !== '0000-00-00 00:00:00' &&
                <span className="d-flex align-items-center me-md-4 mb-2 mb-md-0 text-danger">
                    <FontAwesomeIcon width={15} icon={faTriangleExclamation} className="me-2" />
                    Vertragsende {formatDateWithoutTime(location.contractEnd)}
                </span>
            }
            <span className="d-flex align-items-center me-md-4 mb-2 mb-md-0 text-muted">
                <FontAwesomeIcon width={15} icon={faBuilding} className="me-2" />
                {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.officeType')?.options ?? {}, location?.officeType.toString())}
            </span>

            <span className="d-flex align-items-center me-md-4 mb-2 mb-md-0 text-muted">
                <FontAwesomeIcon width={15} icon={faCircleUser} className="me-2" />
                {getEnumValue(partner, location.partner)}
            </span>
        </div>
    );
};

export default StatusDisplay;
