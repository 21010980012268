import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Keyword } from '../../../interfaces';

import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import AddEditKeywordModal from '../modal/AddEditKeywordModal';
import { usePermissions } from 'c1g-ui-library';
import { PermissionsEnum } from '../../../utils/enum';
import { Permissions } from '../../../interfaces';

interface KeywordDetailsProps {
    isLoading?: boolean;
    keyword?: Keyword | null
    onSubmitSuccess: (keywordId: number) => void;
}

const KeywordDetails: React.FC<KeywordDetailsProps> = ({
    isLoading,
    keyword,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: keyword?.title || '-',
            },

        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Sichtbarkeit',
                value: keyword?.visibility || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{keyword?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Keywords, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {keyword && showAddEditModal && <AddEditKeywordModal
                modalTitle={`${keyword.title} bearbeiten`}
                keyword={keyword}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </AddEditKeywordModal>
            }
        </>
    );
};

export default KeywordDetails;
