import { AxiosError } from 'axios';
import { NotificationToast, usePermissions, useResetUrlParams, useSelection, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Col, Form, Row, Table, } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, {
  ComboButtonId,
} from '../../components/ComboButtonGroup';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';
import SearchInput from '../../components/SearchInput';
import Card from '../../components/bootstrap/card';
import AddEditKeywordModal from '../../components/keywords/modal/AddEditKeywordModal';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import { Keyword, Permissions } from '../../interfaces';
import {
  PermissionsEnum,
} from '../../utils/enum';

export const mockKeywords: Keyword[] = [
  {
    id: 1,
    name: "react",
    title: "React Framework"
  },
  {
    id: 2,
    name: "javascript",
    title: "JavaScript Programming"
  },
  {
    id: 3,
    name: "typescript",
    title: "TypeScript Language"
  },
  {
    id: 4,
    name: "nodejs",
    title: "Node.js Runtime"
  },
  {
    id: 5,
    name: "css",
    title: "CSS Styling"
  },
  {
    id: 6,
    name: "html",
    title: "HTML Markup"
  },
  {
    id: 7,
    name: "redux",
    title: "Redux State Management"
  },
  {
    id: 8,
    name: "graphql",
    title: "GraphQL Query Language"
  },
  {
    id: 9,
    name: "docker",
    title: "Docker Containerization"
  },
  {
    id: 10,
    name: "aws",
    title: "Amazon Web Services"
  }
];

interface KeywordsResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Keyword[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Keywords = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const { items: sortedKeywords, requestSort } = useSortableData(keywords);
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const getId = useCallback((keyword: Keyword) => keyword.id, []);

  const {
    selectedItems: selectedKeywords,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(keywords, getId);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const fetchKeywords = useCallback(async () => {
    setKeywords([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (limit.toString()) {
        queryParams += `&limit=${limit}`;
      }
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      // const response = await ApiClient.get(`/keywords${queryParams}`);
      //setLastQueryParams(queryParams)
      // const keywordsResponse = response.data as KeywordsResponse;
      // setTotalPages(keywordsResponse.amountPages);
      //setKeywords(keywordsResponse.list);
      setKeywords(mockKeywords);
      // setCurrentPage(keywordsResponse.page);
      // setLimit(keywordsResponse.itemsPerPage);
      // setTotalEntries(keywordsResponse.amountAllItems);
      //setAvailableFilter(keywordsResponse.searchFilters)  
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, selectedCombo, searchQuery, limit]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Keywords, 'read');

      if (hasPermission) {
        fetchKeywords();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    searchQuery,
    selectedCombo,
    fetchKeywords,
    permissionsLoaded,
    limit
  ]);


  const handleKeywordUpdateSubmit = () => {
    fetchKeywords();
  };

  const handleComboSelect = (newCombo: ComboButtonId) => {
    setSelectedCombo(newCombo);
    setCurrentPage(1);
  };

  const resetSearch = () => {
    resetUrlParams()
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>Keywords</h3>
          <Button disabled={!userHasPermissionByRight(PermissionsEnum.Keywords, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
            Keyword hinzufügen
          </Button>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={6}>
                {searchQuery ?
                  <div className="d-flex align-items-baseline">
                    <h4 className="m-0">Suchergebnisse</h4>
                    <span className="ms-3 d-flex align-items-baseline">
                      <Button
                        className="m-0 p-0 fs-6"
                        variant="link"
                        onClick={resetSearch}
                      >
                        Suche beenden
                      </Button>
                    </span>
                  </div>

                  : <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedCombo}
                    setSelectedCombo={handleComboSelect}
                    borderRadius="normal"
                  ></ComboButtonGroup>}
              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
              </Col>
            </Row>
            <Row>
              <Col>

              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.Keywords, 'write') && !userHasPermissionByRight(PermissionsEnum.Keywords, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('id')}
              >
                ID
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedKeywords}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                handleSelectAll={handleSelectAll}
                onSubmitSuccess={handleKeywordUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='keywords'
                queryParams={lastQueryParams}
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedKeywords.map((keyword) => (
                <tr key={keyword.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.Keywords, 'write') && !userHasPermissionByRight(PermissionsEnum.Keywords, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedKeywords[keyword.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(keyword.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/keywords/${keyword.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {keyword.title}
                    </Link>
                  </td>
                  <td>{keyword.id}</td>
                </tr>
              ))}
            {!isLoading && sortedKeywords.length === 0 && (
              <TableNoDataMessage
                message="Keine Keywords"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row>
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}

      {showAddEditModal && <AddEditKeywordModal
        modalTitle={`Keyword hinzufügen`}
        onSubmitSuccess={handleKeywordUpdateSubmit}
        onModalClose={() => setShowAddEditModal(false)}
      >
      </AddEditKeywordModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default Keywords;
