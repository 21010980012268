import { FieldConfig } from 'c1g-ui-library';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Location, RentIncrease } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { formatDate, formatDateWithoutTime, formatEuro, getFieldConfigByResourceName } from '../../../utils/utils';
import InfoDisplay, { RowConfig } from '../../InfoDisplay';
import SkeletonRows from '../../SkeletonRows';

type LocationCostsProps = {
    location?: Location;
    fieldConfigs: FieldConfig[]
};

/**
 * LocationCosts Component
 * 
 * This component displays detailed cost information for a location, including deposit, monthly rent, 
 * tax status, and contract termination terms. It organizes this data into structured sections 
 * like Deposit, Rent, Termination, and Rent Increase, using the `InfoDisplay` component for clarity 
 * and organization. If data is unavailable or still loading, `SkeletonRows` placeholders maintain 
 * layout consistency.
 * 
 * Props:
 * - `location`: Optional `Location` object containing various cost details.
 * - `fieldConfigs`: Array of `FieldConfig` objects used to dynamically render specific fields.
 * 
 * Example usage:
 * ```tsx
 * <LocationCosts location={locationData} fieldConfigs={fieldConfigs} />
 * ```
 */

const LocationCosts: React.FC<LocationCostsProps> = ({ location, fieldConfigs }) => {
    const depositInfo: RowConfig[] = [
        { label: "Kaution", value: formatEuro(location?.costsDeposit ?? 0) },
        { label: "Datum Kaution", value: location?.dateOfDeposit && location?.dateOfDeposit !== '0000-00-00 00:00:00' ? formatDateWithoutTime(location?.dateOfDeposit) : '-' },
    ];


    const rentInfo: RowConfig[] = [
        { label: "Miete, monatlich", value: <>{formatEuro(location?.costsMonthlyNet ?? 0)} (Netto) <br></br> {formatEuro(location?.costsMonthlyGross ?? 0)} (Brutto)</> },
        { label: "Umsatzsteuerpflichtig", value: getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.taxDuty')?.options ?? {}, location?.taxDuty) },
    ];

    const terminationInfo: RowConfig[] = [
        { label: "Kosten bei Kündigung", value: location?.costsOfTermination ? formatEuro(location?.costsOfTermination ?? 0) : '-' },
    ];

    const renderRentIncrease = (increase: RentIncrease) => {
        if (increase.type === 'onetime' && increase.oneTimePaymentDate) {
            // Display for one-time increase
            return (
                <div className='text-black mb-3'>
                    {increase.percentage ? `${increase.percentage} %` : `${formatEuro(increase.amount ?? 0)}`} Steigerung
                    <span className="text-muted"> einmalig am {formatDate(increase.oneTimePaymentDate, 'd.m.Y')}</span>
                </div>
            );
        } else if (increase.type === 'recurring' && increase.startDate && (increase.percentage || increase.amount)) {
            // Display for recurring increase
            const intervalText = increase.interval ? `alle ${increase.interval} Monate` : 'jährlich';
            return (
                <div className='text-black mb-3'>
                    {increase.percentage ? `${increase.percentage} %` : `${formatEuro(increase.amount ?? 0)}`} Steigerung
                    <span className="text-muted"> {intervalText} ab dem {formatDate(increase.startDate, 'd.m.Y')}</span>
                </div>
            );
        }
        return null;
    };

    return (
        <Row>

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={2} />
                        ) : (
                            <InfoDisplay title='Kaution' config={depositInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={2} />
                        ) : (<>
                            <h5 className="mb-3">Notizen zur Kaution</h5>
                            <p>{location.notesDeposit ?? '-'}</p>
                        </>
                        )}
                    </Card.Body>
                </Card>
            </Col>

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Miete' config={rentInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Kündigung' config={terminationInfo} />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <>
                                <h5 className="mb-3">Mietsteigerung</h5>
                                {location.rentCostIncreases.map((increase, i) => <div key={i}>{renderRentIncrease(increase)}</div>)}
                            </>

                        )}
                    </Card.Body>
                </Card>
            </Col>

        </Row>
    );
};

export default LocationCosts;
