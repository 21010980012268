import { usePermissions, Webhook } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Permissions } from '../../../interfaces';
import { ActiveInactiveMapping, getEnumValue, PermissionsEnum, WebhookModuleEnum } from '../../../utils/enum';
import { getWebhookUrl } from '../../../utils/media/utils';
import Card from '../../bootstrap/card';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import LogAccordion from '../../LogAccordion';
import GenericInfosCard from '../../products/GenericInfosCard';
import AddEditWebhookModal from '../modal/AddEditWebhookModal';

interface WebhookDetailsProps {
    isLoading?: boolean;
    webhook?: Webhook | null
    onSubmitSuccess: (webhookId: number) => void;
}

const WebhookDetails: React.FC<WebhookDetailsProps> = ({
    isLoading,
    webhook,
    onSubmitSuccess
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions<Permissions>();

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: webhook?.title || '-',
            },
            {
                label: 'Beschreibung',
                value: webhook?.description || '-',
            },
            {
                label: 'Status',
                value: getEnumValue(ActiveInactiveMapping, webhook?.status.toString()),
            },

        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'URL',
                value: webhook?.code ? getWebhookUrl(webhook) : '-',
                isCopyToClipboard: true
            },
            {
                label: 'Methode',
                value: webhook?.method || '-',
            },
            {
                label: 'Modul',
                value: getEnumValue(WebhookModuleEnum, webhook?.module.toString()),
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{webhook?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Webhooks, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <h5 className="mb-3">Aktivitäten</h5>
                            {webhook && webhook.logs?.length > 0 ? (
                                <LogAccordion logs={webhook?.logs} />
                            ) : (
                                <div>Keine Aktivitäten gefunden</div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {webhook && showAddEditModal && <AddEditWebhookModal
                modalTitle={`${webhook.title} bearbeiten`}
                webhook={webhook}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </AddEditWebhookModal>
            }
        </>
    );
};

export default WebhookDetails;
