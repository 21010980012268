import { memo, useEffect, useState } from 'react';
import { usePermissions } from 'c1g-ui-library';
import { useParams } from 'react-router-dom';
import { PermissionsEnum } from '../../utils/enum';
import { Permissions } from '../../interfaces';
import Card from '../../components/bootstrap/card';
import { Badge, Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import CountUp from 'react-countup';
import { LocationStats, ProductStats, StandardStats } from '../../interfaces';
import { ApiClient } from 'c1g-ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloud,
  faCloudExclamation,
  faCloudSlash,
  faCircle,
  faCircleCheck,
  faCircleDashed,
  faGlobe,
  faHandshake
} from '@fortawesome/pro-regular-svg-icons';
import RadialBarChart from '../../components/RadialBarChart';
import BarChart from '../../components/BarChart';
import { faChartPie, faGraduationCap } from '@fortawesome/pro-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

const Index = memo(() => {
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const [locationStats, setLocationStats] = useState<LocationStats | null>(null);
  const [productStats, setProductStats] = useState<ProductStats | null>(null);
  const [standardStats, setStandardStats] = useState<StandardStats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [selectedView, setSelectedView] = useState<string>('standard');

  useEffect(() => {
    if (permissionsLoaded) {
      fetchStats();
    }
  }, [permissionsLoaded, selectedView]);

  const fetchStats = async () => {
    setIsLoading(true);

    switch (selectedView) {
      case 'standard':
        const standardStatsResponse = await ApiClient.get(`/dashboard/standard`);
        const standardStats = standardStatsResponse.data as StandardStats;
        setStandardStats(standardStats);
        break;
      case 'products':
        if (userHasPermissionByRight(PermissionsEnum.Products, 'read')) {
          const productStatsResponse = await ApiClient.get(`/dashboard/products`);
          const productStats = productStatsResponse.data as ProductStats;
          setProductStats(productStats);
        }
        break;
      case 'locations':
        if (userHasPermissionByRight(PermissionsEnum.Locations, 'read')) {
          const locationStatsResponse = await ApiClient.get(`/dashboard/locations`);
          const locationStats = locationStatsResponse.data as LocationStats;
          const reOrderedLocationStats: { [key: string]: { label: string; amount: number } } = {};

          const knownFields = [
            'amountAll',
            'amountPublished',
            'amountDraft',
            'amountUnpublished',
            'amountRequiresEditing',
            'amountCertificated',
            'amountRequiresCertification',
            'amountInCertification',
            'amountOwn',
            'amountPartner'
          ];

          knownFields.forEach((field) => {
            if (locationStats[field]) {
              reOrderedLocationStats[field] = {
                label: locationStats[field].label,
                amount: locationStats[field].amount,
              };
            }
          });

          Object.keys(locationStats).forEach((key) => {
            if (!knownFields.includes(key)) {
              reOrderedLocationStats[key] = {
                label: locationStats[key].label,
                amount: locationStats[key].amount,
              };
            }
          });

          setLocationStats(reOrderedLocationStats as LocationStats);
        }
        break;
      default:
        break;
    }

    setIsLoading(false);
  };

  const getNameAndIcon = (key: string, label: string) => {
    switch (key) {
      case 'amountAll':
        return { label: 'Alle', icon: <FontAwesomeIcon icon={faGlobe} className="me-2" /> };
      case 'amountUnpublished':
        return {
          label: 'Unveröffentlicht',
          icon: <FontAwesomeIcon className="me-2" style={{ color: 'var(--bs-danger)' }} icon={faCloudSlash} />,
        };
      case 'amountPublished':
        return {
          label: 'Veröffentlicht',
          icon: <FontAwesomeIcon className="me-2" style={{ color: 'var(--bs-success)' }} icon={faCloud} />,
        };
      case 'amountDraft':
        return {
          label: 'Unveröff. Änderungen',
          icon: <FontAwesomeIcon className="me-2" style={{ color: 'var(--bs-warning)' }} icon={faCloudExclamation} />,
        };
      case 'amountRequiresEditing':
        return {
          label: 'Benötigt Bearbeitung',
          icon: <FontAwesomeIcon style={{ color: 'var(--bs-danger)' }} className="me-2" icon={faCircle} />,
        };
      case 'amountRequiresCertification':
        return {
          label: 'Benötigt Zertifizierung',
          icon: <FontAwesomeIcon style={{ color: 'var(--bs-danger)' }} className="me-2" icon={faCircle} />,
        };
      case 'amountInCertification':
        return {
          label: 'In Zertifizierung',
          icon: <FontAwesomeIcon style={{ color: 'var(--bs-warning)' }} className="me-2" icon={faCircleDashed} />,
        };
      case 'amountCertificated':
        return {
          label: 'Zertifiziert',
          icon: <FontAwesomeIcon className="me-2" style={{ color: 'var(--bs-success)' }} icon={faCircleCheck} />,
        };
      default:
        return { label, icon: <FontAwesomeIcon icon={faHandshake} className="me-2" style={{ color: 'var(--bs-info)' }} /> };
    }
  };

  const locationStatsData =
    locationStats &&
    Object.entries(locationStats).map(([key, value]) => {
      const { label, icon } = getNameAndIcon(key, (value as any).label);
      return {
        id: key,
        name: label,
        value: 100,
        start: 0,
        end: (value as any).amount,
        duration: 1,
        svg: icon,
      };
    });

  const productStatsData =
    productStats &&
    Object.entries(productStats).map(([key, value]) => {
      const { label, icon } = getNameAndIcon(key, value.label);
      return {
        id: key,
        name: label,
        value: 100,
        start: 0,
        end: value.amount,
        duration: 1,
        svg: icon,
      };
    });

  const handleSelectView = (view: string | null) => {
    if (view) {
      setSelectedView(view);
    }
  };

  const getSelectedIcon = (view: string) => {
    switch (view) {
      case 'standard':
        return <FontAwesomeIcon width={15} icon={faChartPie} className='me-2' />;
      case 'products':
        return <FontAwesomeIcon width={15} icon={faGraduationCap} className='me-2' />;
      case 'locations':
        return <FontAwesomeIcon width={15} icon={faLocationDot} className='me-2' />;
      default:
        return null;
    }
  };

  const getSelectedLabel = (view: string) => {
    switch (view) {
      case 'standard':
        return 'Standard';
      case 'products':
        return 'Produkte';
      case 'locations':
        return 'Standorte';
      default:
        return '';
    }
  };

  return (
    <div className='container-fluid p-40'>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3>Dashboard</h3>
        </div>
        <div className='dashboard-dropdown-button'>
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <div>
                {getSelectedIcon(selectedView)}
                {getSelectedLabel(selectedView)}
              </div>
            }
            onSelect={handleSelectView}
          >
            <Dropdown.Item eventKey="standard"><FontAwesomeIcon width={15} icon={faChartPie} className='me-2' /> Standard</Dropdown.Item>
            {userHasPermissionByRight(PermissionsEnum.Products, 'read') && <Dropdown.Item eventKey="products"><FontAwesomeIcon width={15} icon={faGraduationCap} className='me-2' /> Produkte</Dropdown.Item>}
            {userHasPermissionByRight(PermissionsEnum.Locations, 'read') && <Dropdown.Item eventKey="locations"><FontAwesomeIcon width={15} icon={faLocationDot} className='me-2' /> Standorte</Dropdown.Item>}
          </DropdownButton>
        </div>
      </div>

      {selectedView === 'standard' && standardStats && (
        <>
          <Row>
            <Col lg={4}>
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <h5>Produkte</h5>
                  <RadialBarChart
                    value={standardStats.products.percent}
                    value1={standardStats.products.published}
                    value2={standardStats.products.certified}
                    label1="veröffentlicht"
                    label2="zertifiziert"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <h5>Standorte</h5>
                  <RadialBarChart
                    value={standardStats.locations.percent}
                    value1={standardStats.locations.published}
                    value2={standardStats.locations.certified}
                    label1="veröffentlicht"
                    label2="zertifiziert"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <h5>Angebote <small>(mein NOW)</small></h5>
                  <RadialBarChart
                    value={standardStats.offers.percent}
                    value1={standardStats.offers.current}
                    value2={standardStats.offers.limit}
                    label1="aktuelle Angebote"
                    label2=""
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {userHasPermissionByRight(PermissionsEnum.Products, 'read') && userHasPermissionByRight(PermissionsEnum.Locations, 'read') && <Row>
            <Col>
              <Card className="card-block card-stretch">
                <Card.Body>
                  <h5>Accounts</h5>
                  <BarChart accounts={standardStats.accounts.map(account => ({ name: account.label, amount: account.amount, amountLabel: account.amountLabel, accountId: account.accountId }))} />
                </Card.Body>
              </Card>
            </Col>
          </Row>}

        </>
      )}

      {selectedView === 'products' && productStatsData && (
        <Row>
          <Col lg={12}>
            <Card className="card-block card-stretch">
              <Card.Body>
                <h5>Produkte</h5>
                <Row>
                  {productStatsData.map((item, index) => (
                    <Col md={4} xl={3} key={index}>
                      <div className="progress-detail mb-3">
                        <h4 className="counter fs-2 py-2">
                          <CountUp start={item.start} end={item.end} duration={item.duration} separator="." />
                        </h4>
                        <p className="mb-2">
                          <Badge className="bg-badge-gray p-2">
                            {item.svg} {item.name}
                          </Badge>
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {selectedView === 'locations' && locationStatsData && (
        <Row>
          <Col lg={12}>
            <Card className="card-block card-stretch">
              <Card.Body>
                <h5>Standorte</h5>
                <Row>
                  {locationStatsData.map((item, index) => (
                    <Col md={4} xl={3} key={index}>
                      <div className="progress-detail mb-3">
                        <h4 className="counter fs-2 py-2">
                          <CountUp start={item.start} end={item.end} duration={item.duration} separator="." />
                        </h4>
                        <p className="mb-2">
                          <Badge className="bg-badge-gray p-2">
                            {item.svg} {item.name}
                          </Badge>
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
})

Index.displayName = 'Index';
export default Index;
