import { useNavigate } from 'react-router-dom';
/**
 * Custom hook to navigate within the current company context.
 *
 * It automatically prepends the current `companyId` (from the route params)
 * to the target path, so consumers don’t need to handle company-specific routing manually.
 *
 * @param defaultCompanyId - Fallback company ID if none is present in the URL (default: 'oc')
 * @returns A navigate function that includes the current company ID in the path
 */
function useCompanyNavigate() {
    const navigate = useNavigate();
    const companyId = sessionStorage.getItem('companyId');
    /**
     * Navigates to a given path within the context of the current company.
     * Automatically prepends `/${companyId}` to the given route.
     *
     * @param to - The target route (e.g. '/dashboard' becomes '/{companyId}/dashboard')
     * @param options - Optional navigation options (e.g. replace: true)
     */
    const companyNavigate = (to, options) => {
        navigate(`/${companyId}${to}`, options);
    };
    return companyNavigate;
}
export { useCompanyNavigate };
