import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCircleCheck, faCircleExclamation, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast } from 'react-bootstrap';
/**
 * Toast notification component for displaying success and error messages
 *
 * @param props - Component props
 * @param props.show - Whether the toast should be visible
 * @param props.onClose - Function to call when the toast is closed
 * @param props.message - Message to display in the toast
 * @param props.error - Whether this is an error message (true) or success message (false)
 * @returns Toast notification component
 */
const NotificationToast = ({ show, onClose, error, message, }) => {
    // TODO: Move this to a global styles file
    const customToastStyle = {
        backgroundColor: '#232D42',
        color: 'white',
        borderRadius: '20px',
        width: 'auto',
        zIndex: 2000
    };
    return (_jsx(Toast, { show: show, onClose: onClose, delay: 5000, autohide: !error, className: "position-fixed bottom-0 start-50 translate-middle-x mb-3", style: customToastStyle, children: _jsx(Toast.Body, { children: _jsxs("div", { className: "d-flex justify-content-center align-items-center", children: [error ? (_jsx(FontAwesomeIcon, { className: "me-3 text-danger", icon: faCircleExclamation })) : (_jsx(FontAwesomeIcon, { className: "me-3 text-success", icon: faCircleCheck })), _jsx("span", { className: "me-3 cursor-pointer", children: message }), ' ', _jsx(FontAwesomeIcon, { onClick: onClose, icon: faX })] }) }) }));
};
export { NotificationToast };
