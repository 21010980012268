import { useLocation, useNavigate } from 'react-router-dom';
/**
 * Custom hook to reset (remove) all URL query parameters while keeping the current path.
 *
 * Useful in scenarios where filters, pagination, or other URL search params
 * should be cleared without changing the current route.
 *
 * Example: from `/dashboard?page=2&filter=open` → `/dashboard`
 *
 * @returns A function that resets the URL to the current pathname (without search params)
 */
const useResetUrlParams = () => {
    const location = useLocation();
    const navigate = useNavigate();
    /**
     * Navigates to the current path without any search parameters.
     * Replaces the current history entry to avoid polluting browser history.
     */
    const resetUrlParams = () => {
        const baseUrl = location.pathname;
        navigate(baseUrl, { replace: true });
    };
    return resetUrlParams;
};
export { useResetUrlParams };
