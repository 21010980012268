import { useState, useMemo } from 'react';
/**
 * Hook to enable sorting functionality for lists or tables.
 *
 * Features:
 * - Maintains sort config (field + direction)
 * - Sorts data based on current config
 * - Exposes a function to request sorting by a specific field
 * - Returns a helper for rendering sort icons (FontAwesome)
 *
 * @param items - The list of items to sort
 * @param showPopup - If true, disables sorting (useful for modal modes)
 * @param initialSortConfig - Optional initial sort state
 */
const useSortableData = (items, showPopup = false, initialSortConfig = { field: null, type: 'asc' }) => {
    const [sortConfig, setSortConfig] = useState(initialSortConfig);
    // Return sorted list of items based on current sort config
    const sortedItems = useMemo(() => {
        if (!items || items.length === 0)
            return items || [];
        const sortableItems = [...items];
        if (sortConfig.field !== null) {
            sortableItems.sort((a, b) => {
                const aValue = a[sortConfig.field];
                const bValue = b[sortConfig.field];
                if (aValue < bValue)
                    return sortConfig.type === 'asc' ? -1 : 1;
                if (aValue > bValue)
                    return sortConfig.type === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);
    /**
     * Requests sorting by a given field.
     * Toggles between ascending/descending when the same field is clicked.
     */
    const requestSort = (field) => {
        if (showPopup)
            return;
        let type = 'asc';
        if (sortConfig.field === field && sortConfig.type === 'asc') {
            type = 'desc';
        }
        setSortConfig({ field, type });
    };
    /**
     * Returns the current sort direction for a given column.
     *
     * @param columnField - The field/column to check sort direction for
     * @returns 'asc', 'desc', or null if the column is not currently sorted
     */
    const getSortDirection = (columnField) => {
        if (!sortConfig || sortConfig.field !== columnField) {
            return null;
        }
        return sortConfig.type;
    };
    return {
        items: sortedItems,
        requestSort,
        sortConfig,
        setSortConfig,
        getSortDirection
    };
};
export { useSortableData };
