import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Location } from '../../../interfaces';
import { usePermissions } from 'c1g-ui-library';
import { PermissionsEnum } from '../../../utils/enum';
import { Permissions } from '../../../interfaces';
import PortalWrapper from '../../PortalWrapper';
import DeleteModal from '../../DeleteModal';
import ArchiveModal from '../../ArchiveModal';

interface LocationHeaderDropdownProps {
    onActionClick?: (action: string) => void;
    onSubmitSuccess: (id: number) => void;
    location?: Location
}

/**
 * ParticipantHeaderDropdown Component
 * 
 * This component renders a dropdown menu with various actions that can be triggered for a participant.
 * It uses FontAwesome icons to visually represent each action in the dropdown.
 * 
 * Props:
 * - `onActionClick`: A callback function that is triggered when an action is selected. 
 *   It receives a string parameter representing the selected action.
 * - `participant`: The participant object.
 */
const LocationHeaderDropdown: React.FC<LocationHeaderDropdownProps> = ({ onActionClick, location, onSubmitSuccess }) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();

    const handleActionClick = (action: string) => () => {
        onActionClick && onActionClick(action);
    };

    return (
        <Dropdown className="me-2 rounded">
            <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow h-100 py-2 px-3"
            >
                <FontAwesomeIcon icon={faEllipsis} />
            </Dropdown.Toggle>

            <PortalWrapper>
                <Dropdown.Menu>
                {location && location?.status === 3 && <DeleteModal
                  modalTitle="Auswahl löschen?"
                  buttonName="Löschen"
                  entityIds={location?.id}
                  entityType='locations'
                  onSubmit={() => onSubmitSuccess(location?.id!)}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'delete')}>
                </DeleteModal>}

                {location && location?.status !== 3 && <ArchiveModal
                  modalTitle="Auswahl archivieren?"
                  buttonName="Archivieren"
                  entityIds={location?.id}
                  entityType='locations'
                  onSubmit={() => onSubmitSuccess(location?.id!)}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'delete')}>
                </ArchiveModal>}

                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default LocationHeaderDropdown;
