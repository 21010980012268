import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * A custom hook to dynamically set the document title based on the current route and optional context (e.g. entity name).
 *
 * This hook is useful in multi-module frontend apps where the title should reflect the current route, module, and/or context.
 * It supports route-based translation, dynamic titles (e.g. selected customer or deal), and special handling (e.g. login page).
 *
 * @param options - Configuration object
 * @param options.appName - The base app name (e.g. "ClientOffice")
 * @param options.moduleTranslations - Object mapping module names to translated display names
 *
 * @returns A setter function to optionally update the dynamic part of the title (e.g. selected entity)
 *
 * @example
 * const setTitle = useDocumentTitle({
 *   appName: 'ClientOffice',
 *   moduleTranslations: { dashboard: 'Übersicht', participants: 'Teilnehmer' },
 * });
 *
 * useEffect(() => {
 *   setTitle('Max Mustermann');
 * }, []);
 */
function useDocumentTitle(options) {
    const { appName, moduleTranslations } = options;
    const location = useLocation();
    // Local state for an optional dynamic title part (e.g. "Max Mustermann")
    const [dynamicTitle, setDynamicTitle] = useState(null);
    useEffect(() => {
        var _a;
        // Split the current path into segments and remove any empty parts
        const pathParts = location.pathname.split('/').filter(Boolean);
        let newTitle = appName; // Default fallback title
        const lastSegment = pathParts[pathParts.length - 1];
        // Special case: if current route ends with "login", set a fixed login title
        if (lastSegment === 'login') {
            newTitle = `${appName} - Anmelden`;
        }
        else {
            let moduleName = '';
            // Determine the module name based on the route structure
            if (pathParts.length === 1) {
                moduleName = 'dashboard'; // Root path → assume dashboard
            }
            else if (pathParts.length >= 2) {
                moduleName = pathParts[1]; // e.g. /oc/participants → moduleName = 'participants'
            }
            // Shorten prefix if nested module view (e.g. detail view) and not in 'media'
            let prefix = appName;
            if (pathParts.length === 3 && moduleName !== 'media') {
                // Extract uppercase letters from camelCase / PascalCase (e.g. 'ClientOffice' → 'CO')
                prefix = ((_a = appName.match(/[A-Z]/g)) === null || _a === void 0 ? void 0 : _a.join('')) || appName;
            }
            // Use translated module name if available, otherwise fallback to raw module name
            const translatedModule = moduleTranslations[moduleName] || moduleName;
            // Full title for deep routes (e.g. `/oc/participants/123`) with dynamic label
            if (pathParts.length === 3 && moduleName !== 'media' && dynamicTitle) {
                newTitle = `${dynamicTitle} - ${translatedModule} - ${prefix}`;
            }
            else {
                newTitle = `${prefix} - ${translatedModule}`;
            }
        }
        // Set the final document title
        document.title = newTitle;
    }, [location.pathname, dynamicTitle, appName, moduleTranslations]);
    // Expose a setter to update the dynamic title from the component (e.g. selected entity name)
    return setDynamicTitle;
}
export { useDocumentTitle };
