import { Setting, usePermissions } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ExportNames, Permissions } from '../../interfaces';
import { PermissionsEnum } from '../../utils/enum';
import SkeletonCard from '../locations/skeleton/SkeletonCard';
import GenericInfosCard from '../products/GenericInfosCard';
import EditExportNameConventionsModal from './modal/EditExportNameConventionsModal';

interface ExportNameConventionsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

const ExportNameConventions: React.FC<ExportNameConventionsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const exportNames = setting?.details as ExportNames

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Benennungsrichtlinie für Exporte',
                value: exportNames?.placeholder || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Settings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {setting && showAddEditModal && <EditExportNameConventionsModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </EditExportNameConventionsModal>
            }
        </>
    );
};

export default ExportNameConventions;
