import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Spinner,
  Dropdown,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive } from '@fortawesome/pro-regular-svg-icons';
import { ApiClient } from 'c1g-ui-library';
import { AxiosError } from 'axios';

type ArchiveModalProps = {
  modalTitle: string;
  buttonName: string;
  entityType: string;
  queryParams?: string;
  onSubmit: (message?: string, isError?: boolean) => void;
  onArchiveModalChange?: (open: boolean) => void;
  isDropdownItem?: boolean;
  isDisabled?: boolean;
  entityIds: number[] | number;
};

const ArchiveModal: React.FC<ArchiveModalProps> = ({
  modalTitle,
  buttonName,
  entityIds,
  entityType,
  onSubmit,
  onArchiveModalChange,
  isDropdownItem,
  isDisabled,
  queryParams = ''
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onArchiveModalChange && onArchiveModalChange(false);
    setShowModal(false);
  };

  const handleShow = () => {
    if (typeof entityIds === 'number' || (Array.isArray(entityIds) && entityIds.length === 1 && !isDropdownItem)) {
      submit();
      return;
    }

    onArchiveModalChange && onArchiveModalChange(true);
    setShowModal(true);
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      let url = `/${entityType}`;

      if (typeof entityIds === 'number') {
        url += `/${entityIds}`;
      } else if (Array.isArray(entityIds) && entityIds.length === 0) {
        url += `${queryParams}`;
      } else if (Array.isArray(entityIds)) {
        const ids = entityIds.map(id => `id[]=${id}`).join('&');
        url += `${queryParams}&${ids}`;
      }

      await ApiClient.put(url, {
        status: 3,
      });
      onSubmit(`Erfolgreich gespeichert`, false);
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      {isDropdownItem ? (
        <Dropdown.Item
          disabled={isDisabled}
          as="div"
          className={`text-danger dropdownDeleteButton ${isDisabled ? 'disabled-item' : ''}`}
          onClick={handleShow}
        >
          <FontAwesomeIcon width={30} icon={faBoxArchive} />{buttonName}
        </Dropdown.Item>
      ) : (
        <Button disabled={isDisabled} variant="link" className='text-danger' onClick={handleShow}>
          {buttonName}
        </Button>
      )}
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Bist du dir sicher, dass du die ausgewählten Elemente archivieren möchtest?</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-soft-primary" variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="danger"
            onClick={submit}
          >
            Archivieren
            {isLoading && (
              <Spinner
                className="ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              ></Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ArchiveModal;
