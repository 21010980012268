import { CompanyEnum, useDocumentTitle, usePermissions, useSaveLastUrl } from 'c1g-ui-library';
import { jwtDecode } from 'jwt-decode';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { Permissions } from '../interfaces';
import { moduleTranslations } from '../utils/enum';

const requiredTokenProperties = [
  'iss',
  'aud',
  'iat',
  'exp',
  'viaToken',
  'user_id',
  'roles_id',
  'views',
];

const isAuthenticated = () => {
  const companyId = sessionStorage.getItem('companyId') || 'oc';
  const token = localStorage.getItem(`accessToken_${companyId}`);
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    const hasAllRequiredProps = requiredTokenProperties.every(prop => decodedToken.hasOwnProperty(prop));
    const decodedTokenProps = Object.keys(decodedToken);
    const hasExactProps = decodedTokenProps.length === requiredTokenProperties.length && hasAllRequiredProps;

    const currentTime = Date.now() / 1000;
    if (!hasExactProps || decodedToken.exp! < currentTime) {
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

const ProtectedRoute = () => {
  useSaveLastUrl();
  useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });
  const { userHasCompanyAccess, permissionsLoaded } = usePermissions<Permissions>();
  let { companyId = 'oc' } = useParams<{ companyId?: keyof typeof CompanyEnum }>();
  
  return permissionsLoaded ? (
    isAuthenticated() && userHasCompanyAccess(companyId) ? <Outlet /> : <Navigate to={`/${companyId}/login`} />
  ) : (
      <div>Loading Permissions</div>
  );
};

export default ProtectedRoute;
