import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
/**
 * Custom hook that stores the last visited path (excluding companyId)
 * in localStorage, scoped per company. Also stores the current companyId
 * in sessionStorage for later use (e.g. favicon or default routing).
 *
 * This enables restoring users to their last location when switching companies
 * or returning to the app.
 *
 * Example:
 * If user visits `/acme/dashboard`, it stores:
 * - `sessionStorage.companyId = 'acme'`
 * - `localStorage.lastPath_acme = { path: '/dashboard', timestamp: 1711475287348 }`
 */
const useSaveLastUrl = () => {
    const location = useLocation();
    const params = useParams();
    useEffect(() => {
        const { companyId } = params;
        let currentPath = location.pathname;
        if (companyId) {
            // Strip the companyId from the URL for storage (e.g., /oc/dashboard → /dashboard)
            const pathWithoutCompanyId = currentPath.replace(`/${companyId}`, '');
            currentPath = pathWithoutCompanyId.startsWith('/') ? pathWithoutCompanyId : `/${pathWithoutCompanyId}`;
            // Persist current companyId in sessionStorage
            sessionStorage.setItem('companyId', companyId);
        }
        const timestamp = Date.now();
        const lastPathInfo = { path: currentPath, timestamp };
        // Store the cleaned path and timestamp in localStorage, namespaced by companyId
        localStorage.setItem(`lastPath_${companyId}`, JSON.stringify(lastPathInfo));
    }, [location, params]);
};
export { useSaveLastUrl };
