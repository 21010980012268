import { closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripLines } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldConfig } from 'c1g-ui-library';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { getFieldConfigByResourceName } from '../utils/utils';
import FormGroup from './form/FormGroup';
import PortalWrapper from './PortalWrapper';

interface ColumnSelectionProps {
  selectedColumns: string[];
  onSelectionChange: (selected: string[]) => void;
  fieldConfigs: FieldConfig[];
  entityType: string
}

interface SortableItemProps {
  id: string;
  label: React.ReactNode;
  handleCheckboxChange: (value: string) => void;
  isChecked: boolean;
  isDisabled: boolean;
  isFirstItem: boolean;
}

// Renders a sortable item with drag-and-drop functionality and a checkbox for selecting/deselecting columns.
const SortableItem: React.FC<SortableItemProps> = ({
  id,
  label,
  handleCheckboxChange,
  isChecked,
  isDisabled,
  isFirstItem,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString({
      ...transform,
      x: 0,
      scaleX: 1,
      scaleY: 1,
    } as any),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
  };

  return (
    <Dropdown.Item
      ref={setNodeRef}
      style={isFirstItem ? undefined : style}
      {...(isFirstItem ? {} : attributes)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d-flex align-items-center w-100 cursor-pointer">
        <div className="flex-grow-1" onClick={() => !isFirstItem && handleCheckboxChange(id)}>
          <Form.Check
            type="checkbox"
            label={<span className="word-break">{label}</span>}
            checked={isChecked}
            onChange={() => handleCheckboxChange(id)}
            onClick={(e) => e.stopPropagation()}
            disabled={isDisabled || isFirstItem}
            className="checkbox-pointer m-0"
          />
        </div>
        {!isFirstItem && (
          <div {...listeners} className="cursor-grab">
            <FontAwesomeIcon icon={faGripLines} />
          </div>
        )}
      </div>
    </Dropdown.Item>
  );
};

/**
 * ColumnSelection Component
 * 
 * This component allows users to select and reorder table columns using checkboxes and drag-and-drop.
 * It supports dynamic column rendering, where each column is configurable based on external field configurations.
 * 
 * Props:
 * - `selectedColumns`: An array of strings representing the initially selected column resource names.
 * - `onSelectionChange`: A callback function that is triggered when the selected columns change.
 * - `fieldConfigs`: An array of `FieldConfig` objects, which provide metadata about available columns.
 */
const ColumnSelection: React.FC<ColumnSelectionProps> = ({
  selectedColumns,
  onSelectionChange,
  fieldConfigs,
  entityType
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(selectedColumns);
  const [show, setShow] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Synchronizes the selectedItems state with the selectedColumns prop when it changes.
  useEffect(() => {
    setSelectedItems(selectedColumns);
  }, [selectedColumns]);

  // Checks if there are changes between the selectedItems and selectedColumns to track unsaved changes.
  useEffect(() => {
    const hasChanges = JSON.stringify(selectedItems) !== JSON.stringify(selectedColumns);
    setIsChanged(hasChanges);
  }, [selectedItems, selectedColumns]);

  // Handles the selection/deselection of columns via checkboxes.
  const handleCheckboxChange = (resourceName: string) => {
    setSelectedItems((prevSelectedItems) => {
      let newSelectedItems;
      if (prevSelectedItems.includes(resourceName)) {
        if (prevSelectedItems.length === 1) {
          return prevSelectedItems; // Prevent deselecting the last column.
        }
        newSelectedItems = prevSelectedItems.filter((item) => item !== resourceName);
      } else {
        newSelectedItems = [...prevSelectedItems, resourceName];
      }
      return newSelectedItems;
    });
  };

  // Applies the changes by confirming the column selection and closing the dropdown.
  const handleConfirmClick = () => {
    onSelectionChange(selectedItems);
    setIsChanged(false);
    setShow(false);
  };

  // Toggles the dropdown menu open or closed.
  const handleToggle = (isOpen: boolean) => {
    setShow(isOpen);
  };

  // Handles the reordering of selected columns when a drag-and-drop event finishes.
  const handleDragEnd = ({ active, over }: any) => {
    if (!over || active.id === over.id) {
      return;
    }

    setSelectedItems((prevSelectedItems) => {
      const oldIndex = prevSelectedItems.indexOf(active.id);
      const newIndex = prevSelectedItems.indexOf(over.id);

      if (oldIndex === 0 || newIndex === 0) {
        return prevSelectedItems; // Prevent moving the first item.
      }

      return arrayMove(prevSelectedItems, oldIndex, newIndex);
    });
  };

  return (
    <Dropdown show={show} onToggle={handleToggle}>
      <Dropdown.Toggle as="div" className="no-caret cursor-pointer d-inline-block">
        <div className="px-2" onClick={() => setShow(!show)}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
      </Dropdown.Toggle>

      <PortalWrapper>
        <Dropdown.Menu className="column-selection-dropdown-menu py-0 custom-width-250">
          <Dropdown.ItemText className="sticky-item-text py-2">
            <p className="mb-0 custom-font-size-08 font-weight-normal">TABELLENANSICHT BEARBEITEN</p>
          </Dropdown.ItemText>

          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={selectedItems}>
              {selectedItems.map((resourceName) => {
                const fieldConfig = getFieldConfigByResourceName(fieldConfigs, resourceName);
                const entity = resourceName?.split('.')[0]
                let entityLabel = ''
                if (entityType !== entity) {
                  entityLabel = fieldConfigs.find(config => config.fieldName === entity)?.fieldLabel || ''
                }
                return (
                  <SortableItem
                    key={resourceName}
                    id={resourceName}
                    label={
                      <>
                        {fieldConfig?.fieldLabel || resourceName}
                        {entityLabel && <small> ({entityLabel})</small>}
                      </>
                    }
                    handleCheckboxChange={handleCheckboxChange}
                    isChecked={selectedItems.includes(resourceName)}
                    isDisabled={selectedItems.length === 1 && selectedItems.includes(resourceName)}
                    isFirstItem={resourceName === selectedItems[0]}
                  />
                );
              })}
            </SortableContext>
          </DndContext>

          <div className="horizontal-line my-3"></div>

          <div className='p-2'>
            <FormGroup
              className='form-control-sm'
              id="search"
              label={''}
              placeholder="Spalte suchen..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} />
          </div>

          {fieldConfigs
            .filter((fieldConfig) => !selectedItems.includes(fieldConfig.resourceName))
            .filter(config => config.resourceName)
            .filter(config => config.fieldLabel.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((fieldConfig) => {
              const entity = fieldConfig?.resourceName?.split('.')[0]
              let entityLabel = ''
              if (entityType !== entity) {
                entityLabel = fieldConfigs.find(config => config.fieldName === entity)?.fieldLabel || ''
              }
              return (
                <Dropdown.Item
                  className="cursor-pointer"
                  key={fieldConfig.resourceName}
                  as="div"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="d-flex align-items-center w-100 cursor-pointer">
                    <div className="flex-grow-1 cursor-pointer" onClick={() => handleCheckboxChange(fieldConfig.resourceName)}>
                      <Form.Check
                        type="checkbox"
                        label={
                          <>
                            <span className="cursor-pointer word-break">{fieldConfig.fieldLabel}</span>
                            {entityLabel && <small> ({entityLabel})</small>}
                          </>
                        }
                        className="checkbox-pointer m-0"
                        checked={selectedItems.includes(fieldConfig.resourceName)}
                        onChange={() => handleCheckboxChange(fieldConfig.resourceName)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  </div>
                </Dropdown.Item>
              );
            })}

          <div className="d-flex justify-content-end sticky-bottom">
            <Button size="sm" className="btn btn-soft-primary m-2 mt-3" onClick={handleConfirmClick} disabled={!isChanged}>
              Anwenden
            </Button>
          </div>
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default ColumnSelection;
