import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, FormControl, InputGroup } from 'react-bootstrap';
import { View } from './ViewSelector';
import { useToast } from 'c1g-ui-library';

interface ShareLinkModalProps {
    show: boolean;
    handleClose: () => void;
    currentView?: View
}

// ShareLinkModal is a modal component that generates and displays a shareable link.
// The link is generated asynchronously when the modal is shown, simulating an API call.
// It includes a button to copy the generated link to the clipboard and displays a success toast.
// If the link is being generated, a loading spinner and message are shown.
// The 'handleClose' function closes the modal, while 'handleCopyClick' copies the link to the clipboard.
const ShareLinkModal: React.FC<ShareLinkModalProps> = ({ show, handleClose, currentView }) => {
    const [link, setLink] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { showToast } = useToast();

    useEffect(() => {
        if (show) {
            setLoading(true);
            setTimeout(() => {
                const generatedLink = `http://localhost:3000/oc/products/hash=${Math.random().toString(36).substring(7)}`;
                setLink(generatedLink);
                setLoading(false);
            }, 2000);
        }
    }, [show]);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(link || '')
        showToast('Erfolgreich kopiert')
        console.log(currentView)
    }

    return (
        <Modal
            centered
            size="lg"
            backdrop="static"
            keyboard={false}
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Wir haben deine Ansicht fuer 24 Stunden gespeichert</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-5'>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        <p>Dein Link zum teilen wird generiert...</p>
                    </div>
                ) : (
                    <>
                        <p>Zum Teilen einfach den folgenden Link kopieren:</p>
                        <InputGroup>
                            <FormControl
                                readOnly
                                className='text-black bg-grey'
                                type="text"
                                value={link || ''}
                                autoComplete="off"
                            />

                            <Button
                                variant="primary"
                                onClick={handleCopyClick}
                            >
                                Kopieren
                            </Button>
                        </InputGroup>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-soft-primary" onClick={handleClose}>
                    Schließen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShareLinkModal;
