import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import BdksDetails from '../../components/bdks/detail/BdksDetails';
import { Bdks, Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';

function BdksDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { bdksId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [bdks, setBdks] = useState<Bdks | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Bdks, 'read');

      if (hasPermission) {
        bdksId && fetchBdks(bdksId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [bdksId, permissionsLoaded]);

  const fetchBdks = async (bdksId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/bdks/${bdksId}`);
      const bdksResponse = response.data;
      setBdks(bdksResponse);
      setDynamicTitle(bdksResponse.title)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setBdks(null)
    bdksId && fetchBdks(bdksId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <BdksDetails bdks={bdks} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></BdksDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default BdksDetail;
