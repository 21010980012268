import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import { ApiClient } from 'c1g-ui-library';

export interface SelectOption {
  value: string
  label: string;
}

interface SelectWithSearchProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  apiEndpoint: string;
  mapResponseToOptions?: (response: any) => SelectOption[];
  onChange: (id: string, selectedOption: SelectOption | null) => void;
  initialValue?: SelectOption | null;
}

const SelectWithSearch: React.FC<SelectWithSearchProps> = ({
  id,
  label,
  placeholder,
  required = false,
  disabled = false,
  apiEndpoint,
  mapResponseToOptions,
  onChange,
  initialValue = null,
}) => {
  const [selectedValue, setSelectedValue] = useState<SelectOption | null>(initialValue);

  useEffect(() => {
    if (!selectedValue) {
      setSelectedValue(initialValue);
    }
  }, [selectedValue]);

  const handleChange = (selectedOption: SelectOption | null) => {
    setSelectedValue(selectedOption);
    onChange(id, selectedOption);
  };

  const loadOptions = async (inputValue: string): Promise<SelectOption[] | []> => {
    if (inputValue) {
      const response = await ApiClient.get(`${apiEndpoint}?search=${inputValue}`);

      if (mapResponseToOptions) {
        return mapResponseToOptions(response.data);
      }

      return response.data.list.map((item: any) => ({
        value: item.id,
        label: item.title
      }))

    }
    return []
  };
  return (
    <Form.Group className="mb-3 w-100" controlId={id}>
      {label && <Form.Label className='text-black'>{label}</Form.Label>}
      <AsyncSelect
        id={id}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={placeholder}
        value={selectedValue}
        required={required}
        styles={{
          control: (provided) => ({
            ...provided,
            borderColor: '#ced4da',
            backgroundColor: '#F9F9F9',
            color: 'black',
          }),
        }}
      />
    </Form.Group>
  );
};

export default SelectWithSearch;
