import React, { useState, useEffect } from 'react';
import { ButtonGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faThumbtack, faComment, faTrash, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import ConfirmationModal from '../../ConfirmationModal';

interface ActionButtonsProps {
    activityId: number;
    isPinned: boolean;
    isArchived: boolean;
    isCreator: boolean;
    createdDate: string;
    onActionClick: (activityId: number, action: 'pin' | 'archive' | 'comment' | 'delete' | 'edit') => void;
}

/**
 * ActionButtons Component
 *
 * This component renders a set of action buttons for managing activities.
 * It includes options to comment, pin/unpin, archive/unarchive, and delete an activity.
 * 
 * - The "Delete" button is conditionally displayed for a limited time (5 minutes) 
 *   after the activity is created and only for the activity creator.
 * - The visibility of the delete button is determined by comparing the current time 
 *   with the activity's creation time, which is parsed as German time and converted to UTC.
 * - Includes a confirmation modal to confirm delete actions.
 * 
 * Props:
 * - activityId: The unique identifier for the activity.
 * - isPinned: Boolean indicating if the activity is pinned.
 * - isArchived: Boolean indicating if the activity is archived.
 * - isCreator: Boolean indicating if the current user is the activity creator.
 * - createdDate: String representing the creation time of the activity.
 * - onActionClick: Callback function invoked when an action button is clicked.
 */
const ActionButtons: React.FC<ActionButtonsProps> = ({
    activityId,
    isPinned,
    isArchived,
    isCreator,
    createdDate,
    onActionClick,
}) => {
    const [showDeleteEditButton, setShowDeleteEditButton] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        const created = new Date(createdDate);

        const checkVisibility = () => {
            const nowLocal = new Date();
            const timeElapsed = nowLocal.getTime() - created.getTime();
            const fiveMinutesInMs = 5 * 60 * 1000;

            if (timeElapsed <= fiveMinutesInMs && isCreator) {
                setShowDeleteEditButton(true);
            } else {
                setShowDeleteEditButton(false);
            }
        };
        checkVisibility();
        const interval = setInterval(checkVisibility, 1000);

        return () => clearInterval(interval);
    }, [createdDate, isCreator]);

    const handleDeleteConfirm = () => {
        setShowConfirmationModal(false);
        onActionClick(activityId, 'delete');
    };

    return (
        <>
            <ButtonGroup size="sm">
                <OverlayTrigger placement="top" overlay={<Tooltip>Kommentieren</Tooltip>}>
                    <label className="btn me-2" onClick={() => onActionClick(activityId, 'comment')}>
                        <FontAwesomeIcon icon={faComment} />
                    </label>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>{isPinned ? 'Unpinnen' : 'Pinnen'}</Tooltip>}>
                    <label
                        className={`me-2 ${isPinned ? 'btn btn-soft-primary' : 'btn'}`}
                        onClick={() => onActionClick(activityId, 'pin')}
                    >
                        <FontAwesomeIcon icon={faThumbtack} />
                    </label>
                </OverlayTrigger>
                {showDeleteEditButton ? (
                    <>
                         <OverlayTrigger placement="top" overlay={<Tooltip>Bearbeiten</Tooltip>}>
                        <label
                            className="btn"
                            onClick={() => onActionClick(activityId, 'edit')}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </label>
                    </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Löschen</Tooltip>}>
                            <label
                                className="btn text-danger"
                                onClick={() => setShowConfirmationModal(true)}
                            >   
                                <FontAwesomeIcon icon={faTrash} />
                            </label>
                        </OverlayTrigger>
                    </>
                ) : (
                    <OverlayTrigger placement="top" overlay={<Tooltip>{isArchived ? 'Unarchivieren' : 'Archivieren'}</Tooltip>}>
                        <label
                            className={`${isArchived ? 'btn btn-soft-primary' : 'btn'}`}
                            onClick={() => onActionClick(activityId, 'archive')}
                        >
                            <FontAwesomeIcon icon={faArchive} />
                        </label>
                    </OverlayTrigger>
                )}
            </ButtonGroup>

            <ConfirmationModal
                show={showConfirmationModal}
                handleClose={() => setShowConfirmationModal(false)}
                handleConfirm={handleDeleteConfirm}
                title="Notiz löschen"
                message="Möchten Sie diese Notiz wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
            />
        </>
    );
};

export default ActionButtons;
