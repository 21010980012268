import { AxiosError } from 'axios';
import { ApiClient, ColumnMapping, FieldConfig, NotificationToast, SortCaret, usePermissions, useResetUrlParams, useSelection, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
  memo,
  useEffect,
  useState,
} from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CertificationStatusDropdown from '../../components/CertificationStatusDropdown';
import ColumnSelection from '../../components/ColumnSelection';
import {
  ComboButtonId,
} from '../../components/ComboButtonGroup';
import PublicationStatusDropdown from '../../components/PublicationStatusDropdown';
import ResetFiltersButton from '../../components/ResetFilterButton';
import SearchInput from '../../components/SearchInput';
import ViewSelector from '../../components/ViewSelector';
import Card from '../../components/bootstrap/card';
import GenericDropdownFilter from '../../components/filter/GenericDropdownFilter';
import { statusCertificationLocationsColorMap, statusCertificationLocationsIconMap, statusColorMap, statusIconMap } from '../../components/filter/iconAndColorMappings';
import SelectionActions from '../../components/locations/SelectionActions';
import AddEditLocationModal from '../../components/locations/modal/AddEditLocationModal';
import { DefaultColumnRender } from '../../components/table/DefaultColumnRender';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import SaveCancelPopup from '../../components/table/SaveCancelPopup';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import { defaultRenderHeader } from '../../components/table/utils';
import { AccountId, Location, Partner, PaymentMethods, Permissions } from '../../interfaces';
import {
  PermissionsEnum,
  PublicationStatus,
} from '../../utils/enum';
import { addPrefixToFilters, fetchAndCombineFieldConfigs, getFieldConfigByResourceName } from '../../utils/utils';

export interface LocationFilters {
  statusCertification: number | null;
  status: number | null;
  contractRentalStatus: number | null;
  standingOrder: boolean | null;
  officeType: number | null;
}

interface LocationsResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  searchFilters: string[];
  list: Location[];
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Locations = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedSearchColumn, setSelectedSearchColumn] = useState<ComboButtonId | ''>('all');
  const [locations, setLocations] = useState<Location[]>([]);
  const [module, setModule] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [accounts, setAccounts] = useState<AccountId>();
  const [partner, setPartner] = useState<Partner>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods>();
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const [showPopup, setShowPopup] = useState(false);
  const { requestSort, sortConfig, setSortConfig, getSortDirection } = useSortableData(locations, showPopup);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [pendingChanges, setPendingChanges] = useState<{ [key: string]: any }>({});
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [editableCell, setEditableCell] = useState<{ rowId: number | null; columnKey: string | null }>({
    rowId: null,
    columnKey: null,
  });
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<LocationFilters>({
    statusCertification: null,
    status: null,
    contractRentalStatus: null,
    standingOrder: null,
    officeType: null,
  });
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const query = useQuery();

  const { selectedItems: selectedLocations, isAllSelected, selectedCount, handleSelectAll, handleDeSelectAll, handleSelectRow } = useSelection(locations);

  // Fetch the list of locations based on the current filters, search, sort and pagination
  const fetchLocations = async () => {
    setLocations([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedFilters.statusCertification?.toString()) {
      queryParams += `&locations.statusCertification=${selectedFilters.statusCertification?.toString()}`;
    }
    if (selectedFilters.status?.toString()) {
      queryParams += `&locations.status=${selectedFilters.status?.toString()}`;
    }
    if (selectedFilters.officeType?.toString()) {
      queryParams += `&locations.officeType=${selectedFilters.officeType?.toString()}`;
    }
    if (selectedFilters.contractRentalStatus !== null && selectedFilters.contractRentalStatus !== undefined) {
      queryParams += `&locations.contractRentalStatus=${selectedFilters.contractRentalStatus}`;
    }
    if (selectedFilters.standingOrder !== null && selectedFilters.standingOrder !== undefined) {
      queryParams += `&locations.standingOrder=${selectedFilters.standingOrder}`;
    }
    if (limit.toString()) {
      queryParams += `&limit=${limit}`;
    }
    if (searchQuery) {
      queryParams += `&search=${encodeURIComponent(searchQuery)}`;
      if (selectedSearchColumn !== 'all') {
        queryParams += `&column=${encodeURIComponent(selectedSearchColumn)}`;
      }
    }

    if (sortConfig?.field) {
      queryParams += `&sort[field]=${encodeURIComponent(sortConfig.field)}`;
      queryParams += `&sort[type]=${sortConfig.type}`;
    }

    try {
      const response = await ApiClient.get(`/locations${queryParams}`);
      setLastQueryParams(queryParams)
      const locationResponse = response.data as LocationsResponse;
      setTotalPages(locationResponse.amountPages);
      setLocations(locationResponse.list ?? []);
      setCurrentPage(locationResponse.page);
      setLimit(locationResponse.itemsPerPage);
      setTotalEntries(locationResponse.amountAllItems);
      setAvailableFilter(addPrefixToFilters(locationResponse.searchFilters, 'locations'));
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch necessary data (Accounts, Partners, Payment methods)
  const fetchAccounts = async () => {
    const res = await ApiClient.get(`/settings?ident=meinNowAccounts`);
    setAccounts(res.data.list[0].details || {});
  };

  const fetchPartner = async () => {
    const res = await ApiClient.get(`/settings?ident=locationsPartners`);
    setPartner(res.data.list[0].details || {});
  };

  const fetchPaymentMethods = async () => {
    const res = await ApiClient.get(`/settings?ident=paymentMethods`);
    setPaymentMethods(res.data.list[0].details || {});
  };

  // Effect to trigger fetching of locations when filters, search, paginationm or other dependenies change
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Locations, 'read');

      if (hasPermission) {
        fetchLocations();

        // Fetch only once
        if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
          fetchAndCombineFieldConfigs(['locations'], setFieldConfigs, setModule);
        }

        // Fetch only once
        if (!partner) {
          fetchPartner();
        }

        // Fetch only once
        if (!paymentMethods) {
          fetchPaymentMethods();
        }

        // Fetch only once
        if (!accounts) {
          fetchAccounts();
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [selectedFilters, currentPage, searchQuery, selectedSearchColumn, selectedColumns, limit, sortConfig]);

  // Effect to reset search input
  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  // Updates the certification/publication status for a location
  const handleStatusUpdate = async (locationId: number, statusKey: 'statusCertification' | 'status', newStatus: number | PublicationStatus) => {
    try {
      await ApiClient.put(`/locations/${locationId}`, {
        [statusKey]: newStatus,
      });
      await fetchLocations();
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  };

  // Submits changes for adding/editing a location
  const handleModalSubmit = (locationsId: number) => {
    navigate(`/${companyId}/locations/${locationsId}`);
  };

  // Fetch locations again after an update, optionally showing a message
  const handleLocationsUpdateSubmit = (message?: string, isError?: boolean) => {
    fetchLocations();
    if (message) {
      showToast(message, isError);
    }
  };

  // Resets all filters and search fields
  const resetSearch = () => {
    resetUrlParams();
    setSearchQuery('');
    setSelectedSearchColumn('all');
    setCurrentPage(1);
    setResetSearchInput(true);
  };

  // Handle click for navigating to global data settings
  const handleGlobalDataClick = async () => {
    const res = await ApiClient.get('/settings?ident=globalLocationFields');
    const settingsId = res.data.list[0].id;
    navigate(`/${companyId}/settings/${settingsId}`);
  };

  // Handles view selection changes from the view selection component.
  const handleSelectionChange = (
    selectedColumns: string[],
    selectedFilters: any,
    selectedSortConfig: any,
    selectedLimit: number,
    selectedSearchTerm: string,
    selectedSearchColumn: string
  ) => {
    resetSearch()
    setSelectedColumns(selectedColumns);
    setSelectedFilters(selectedFilters);
    setSortConfig(selectedSortConfig);
    setLimit(selectedLimit);

    // get URL search params
    const searchTermParam = query.get('searchTerm');
    const searchColumnParam = query.get('searchColumn');

    if (searchTermParam && searchColumnParam) {
      setSearchQuery(searchTermParam);
      setSelectedSearchColumn(searchColumnParam);
    } else if (selectedSearchTerm || selectedSearchColumn) {
      setSearchQuery(selectedSearchTerm);
      setSelectedSearchColumn(selectedSearchColumn);
    }
  };

  // Handle clicks on table cells for editing
  const handleCellClick = (rowId: number | null, columnKey: string | null) => {
    setEditableCell({ rowId, columnKey });
  };

  // Handle changes to table cells for editing
  const handleFieldChange = (rowId: number, columnKey: string, value: any) => {
    setPendingChanges(prevChanges => ({
      ...prevChanges,
      [`${rowId}-${columnKey}`]: { rowId, columnKey, value },
    }));
    setShowPopup(true);
  };

  // Save changes made in editable cells
  const handleSaveChange = async () => {
    const changesToSubmit = Object.values(pendingChanges);

    try {
      const updatePromises = changesToSubmit.map(change => {
        if (typeof change.value === 'object' && change.value !== null) {
          const relation = change.columnKey.split('.')[0]
          return ApiClient.put(`/locations/${change.rowId}`, { [`${relation}_id`]: change.value.value });
        } else {
          return ApiClient.put(`/locations/${change.rowId}`, { [getFieldConfigByResourceName(fieldConfigs, change.columnKey)?.fieldName as string]: change.value });
        }
      });

      await Promise.all(updatePromises);

      setPendingChanges({});
      setEditableCell({ rowId: null, columnKey: null });

      await fetchLocations();
      showToast('Erfolgreich gespeichert', false);
      setShowPopup(false);
    } catch (error: any) {
      console.error((error as AxiosError).message);
    }
  };

  // Revert a single change in editable cell
  const handleRevertChange = () => {
    const { rowId, columnKey } = editableCell;

    if (rowId === null || columnKey === null) return;

    const newPendingChanges = { ...pendingChanges };
    delete newPendingChanges[`${rowId}-${columnKey}`];

    setPendingChanges(newPendingChanges);
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(Object.keys(newPendingChanges).length > 0);
  };

  // Revert all changes made in the editable cells
  const handleRevertChanges = () => {
    setPendingChanges({});
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(false);
  };

  // Handle search input change
  const handleSearch = (data: { query: string; filter?: string }) => {
    const { query, filter } = data;

    if (query) {
      setSearchQuery(query);
      setSelectedSearchColumn(filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  // Define how columns should be rendered
  const columnMapping: { [key: string]: ColumnMapping<Location> } = {
    'locations.title': {
      renderHeader: (key: any) => (
        <th
          key={key}
          className="sticky-col cursor-pointer"
          scope="col"
          title="Titel"
          onClick={() => requestSort('locations.title')}
        >
          <div className="d-flex align-items-center position-relative table-cell-wrap max-w-100">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write') && !userHasPermissionByRight(PermissionsEnum.Locations, 'delete')}
              className="me-3"
              type="checkbox"
              checked={isAllSelected}
              onChange={handleSelectAll}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            Titel <div className="position-absolute" style={{ right: 0 }}><SortCaret direction={getSortDirection('locations.title')} /></div>
          </div>
        </th>
      ),

      render: (location: Location) => (
        <td key={location.id} className="sticky-col">
          <div className="d-flex align-items-center justify-content-start">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write') && !userHasPermissionByRight(PermissionsEnum.Products, 'delete')}
              className="me-3"
              type="checkbox"
              checked={selectedLocations[location.id] ?? false}
              onChange={() => { }}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectRow(location.id, e);
              }}
            />
            <Link
              to={`/${companyId}/locations/${location.id}`}
              className="btn btn-link ps-0 text-start table-cell-wrap max-w-table-title"
              title={location.title ?? ''}
            >
              {location.title}
            </Link>
          </div>
        </td>
      ),
    },
    'locations.statusCertification': {
      render: (location: Location) => (
        <td key={`${location.id}-statusCertification`}>
          <CertificationStatusDropdown
            statusCertification={location.statusCertification}
            handleCertificationStatusUpdate={(status: number) =>
              handleStatusUpdate(location.id, 'statusCertification', status)
            }
            statusEnum={getFieldConfigByResourceName(fieldConfigs, 'locations.statusCertification')?.options ?? {}}
            iconMap={statusCertificationLocationsIconMap}
            colorMap={statusCertificationLocationsColorMap}
            isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write')}
          />
        </td>
      ),
    },
    'locations.status': {
      render: (location: Location) => (
        <td key={`${location.id}-status`}>
          <PublicationStatusDropdown
            status={location.status}
            handlePublicationStatusUpdate={(status: PublicationStatus) =>
              handleStatusUpdate(location.id, 'status', status)
            }
            isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write')}
            style={{}}
          />
        </td>
      ),
    },
  };

  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>Standorte</h3>
          <div className="d-flex">
            <Button disabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write')} className="btn btn-soft-primary me-4" onClick={handleGlobalDataClick}>
              Globale Daten bearbeiten
            </Button>

            <Button disabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
              Standort hinzufügen
            </Button>
          </div>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={9}>
                {searchQuery &&
                  <div className="d-flex align-items-baseline mb-3">
                    <h4 className="m-0">Suchergebnisse</h4>
                    <span className="ms-3 d-flex align-items-baseline">
                      <Button
                        className="m-0 p-0 fs-6"
                        variant="link"
                        onClick={resetSearch}
                      >
                        Suche beenden
                      </Button>
                    </span>
                  </div>
                }
                <ViewSelector
                  selectedSortConfig={sortConfig}
                  selectedFilters={selectedFilters}
                  selectedColumns={selectedColumns}
                  selectedLimit={limit}
                  selectedSearchColumn={selectedSearchColumn}
                  selectedSearchTerm={searchQuery}
                  entityType='locations'
                  onSelectionChange={handleSelectionChange}
                />
              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} initialSearchTerm={searchQuery} initialSearchColumn={selectedSearchColumn} fieldConfigs={fieldConfigs} />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="d-flex custom-scrollbar-x horizontal-scroll">

                    {/* StatusCertification Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.statusCertification ?? null}
                      handleFilterChange={(statusCertification) => {
                        setSelectedFilters(filters => ({ ...filters, statusCertification }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'locations.statusCertification')?.options ?? {}}
                      iconMap={statusCertificationLocationsIconMap}
                      colorMap={statusCertificationLocationsColorMap}
                      titlePlaceholder="Zertifizierung Status"
                    />

                    {/* Status Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.status ?? null}
                      handleFilterChange={(status) => {
                        setSelectedFilters(filters => ({ ...filters, status }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'locations.status')?.options ?? {}}
                      iconMap={statusIconMap}
                      colorMap={statusColorMap}
                      titlePlaceholder="Veröffentlichung Status"
                    />

                    {/* Bürotyp Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.officeType ?? null}
                      handleFilterChange={(officeType) => {
                        setSelectedFilters(filters => ({ ...filters, officeType }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'locations.officeType')?.options ?? {}}
                      titlePlaceholder="Bürotyp"
                    />

                    {/* Contract Rental Status Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.contractRentalStatus ?? null}
                      handleFilterChange={(contractRentalStatus) => {
                        setSelectedFilters(filters => ({ ...filters, contractRentalStatus }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'locations.contractRentalStatus')?.options ?? {}}
                      titlePlaceholder="Miet-Status"
                    />

                    {/* Standing Order Filter */}
                    <GenericDropdownFilter
                      selectedFilter={
                        selectedFilters.standingOrder === true
                          ? 'true'
                          : selectedFilters.standingOrder === false
                            ? 'false'
                            : null
                      }
                      handleFilterChange={(standingOrder) => {
                        setSelectedFilters((filters) => ({
                          ...filters,
                          standingOrder: standingOrder === 'true' ? true : standingOrder === 'false' ? false : null,
                        }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'locations.standingOrder')?.options ?? {}}
                      titlePlaceholder="Dauerauftrag"
                    />

                    <div className="sticky-right-reset-filter">
                      <ResetFiltersButton filters={selectedFilters} setFilters={(newFilters: LocationFilters) => {
                        setSelectedFilters(newFilters);
                        setCurrentPage(1);
                      }} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {selectedCount > 0 && (
          <SelectionActions
            selectedLocations={selectedLocations}
            selectedCount={selectedCount}
            handleDeSelectAll={handleDeSelectAll}
            onSubmitSuccess={handleLocationsUpdateSubmit}
            handleSelectAll={handleSelectAll}
            publicationStatus={selectedFilters.status}
            amountAllItems={totalEntries}
            fieldConfigs={fieldConfigs}
            queryParams={lastQueryParams}
          ></SelectionActions>
        )}
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table className="sticky-table" responsive="md" size="sm" style={{ overflow: 'auto' }}>
          <thead>
            <tr>
              {selectedColumns.map((columnKey) =>
                columnMapping[columnKey]?.renderHeader
                  ? columnMapping[columnKey].renderHeader!(columnKey)
                  : defaultRenderHeader(columnKey, fieldConfigs, requestSort, getSortDirection, 'locations')
              )}

              <th className="cursor-pointer text-end align-top sticky-right bg-grey w-40-px" scope="col">
                <ColumnSelection
                  selectedColumns={selectedColumns}
                  onSelectionChange={columns => setSelectedColumns(columns)}
                  fieldConfigs={fieldConfigs}
                  entityType='locations'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={`skeleton-row-${index}`} columnCount={selectedColumns.length + 1} />
              ))
              : locations.map((location) => (
                <tr key={location.id} className="bg-white">
                  {selectedColumns.map((columnKey) =>
                    columnMapping[columnKey]
                      ? columnMapping[columnKey].render(location)
                      : <DefaultColumnRender
                        key={`${location.id}-${columnKey}`}
                        item={location}
                        columnKey={columnKey}
                        editableCell={editableCell}
                        handleCellClick={handleCellClick}
                        handleFieldChange={handleFieldChange}
                        handleRevertChange={handleRevertChange}
                        fieldConfigs={fieldConfigs}
                        pendingChanges={pendingChanges}
                        activeTooltip={activeTooltip}
                        setActiveTooltip={setActiveTooltip}
                        lookupValues={{
                          ...(paymentMethods ? { paymentMethods } : {}),
                          ...(accounts ? { accounts } : {}),
                          ...(partner ? { partner } : {}),
                        }}
                        module={module}
                      />
                  )}
                  <td className='sticky-right bg-white' key={'unique'}></td>
                </tr>
              ))}
            {!isLoading && locations.length === 0 && (
              <TableNoDataMessage
                message="Keine Standorte"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row>
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}

      {showAddEditModal && (
        <AddEditLocationModal
          modalTitle="Standort hinzufügen"
          onSubmitSuccess={handleModalSubmit}
          onAccountsUpdate={fetchAccounts}
          onPartnerUpdate={fetchPartner}
          accounts={accounts}
          partner={partner}
          onPaymentMethodsUpdate={fetchPaymentMethods}
          paymentMethods={paymentMethods}
          onModalClose={() => setShowAddEditModal(false)}
          fieldConfigs={fieldConfigs}
        ></AddEditLocationModal>
      )}

      {showPopup && (
        <SaveCancelPopup
          onSave={handleSaveChange}
          onAbort={handleRevertChanges}
          pendingChangesCount={Object.keys(pendingChanges).length}
        />
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default Locations;
