import { MouseEvent, ReactNode } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

interface SidebarMenuProps {
  pathname: string;
  title: string;
  minititle?: string;
  target?: string;
  isTag: 'true' | 'false';
  staticIcon?: 'true' | 'false';
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

/**
 * SidebarMenu component
 *
 * Renders an individual menu item within the sidebar.
 * It supports both `<li>` elements and `Nav.Item` depending on the `isTag` prop.
 *
 * Features:
 * - Displays a link with an optional icon (via children).
 * - Adds a tooltip with the full title when collapsed.
 * - Highlights the active menu item based on current route.
 * - Supports optional click handler for custom logic (e.g., logout).
 * - Handles self-click (reloads the page if already on the same route).
 *
 * Props:
 * - pathname: string → target path for the menu item.
 * - title: string → visible label and tooltip for the item.
 * - minititle: string (optional) → shown in collapsed mode via tooltip.
 * - target: string (optional) → supports `_blank` or other target values.
 * - isTag: 'true' | 'false' → determines whether to render a `<li>` or `Nav.Item`.
 * - staticIcon: 'true' | 'false' (optional) → whether to display a static SVG icon.
 * - children: ReactNode → used to render icon components or any custom content.
 * - onClick: function (optional) → custom handler executed on click.
 *
 * Used inside VerticalNav to build up the sidebar navigation.
 */
const SidebarMenu: React.FC<SidebarMenuProps> = ({
  onClick,
  pathname,
  title,
  minititle,
  target,
  isTag,
  staticIcon,
  children,
}) => {
  const location = useLocation();

  const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
    if (location.pathname === pathname) {
      e.preventDefault();
      window.location.href = pathname;
    }
  };

  const isActive =
    location.pathname === pathname ||
    location.pathname.startsWith(`${pathname}/`);

  return (
    <>
      {isTag === 'true' && (
        <li className={`${isActive ? 'active' : ''} nav-item`}>
          <div className="link-container d-flex align-items-center justify-content-between">
            <Link
              to={pathname}
              className={`${isActive ? 'active' : ''} nav-link`}
              aria-current="page"
              onClick={handleOnClick}
            >
              <OverlayTrigger placement="right" overlay={<Tooltip>{title}</Tooltip>}>
                <div>{children}</div>
              </OverlayTrigger>
              <span className="item-name">{title}</span>
            </Link>
          </div>
        </li>
      )}

      {isTag === 'false' && (
        <Nav.Item as="li">
          <Link
            to={pathname}
            className={`${isActive ? 'active' : ''} nav-link`}
            onClick={handleOnClick}
            target={target}
          >
            {staticIcon === 'true' && (
              <i className="icon">
                {/* SVG or static icon can go here */}
              </i>
            )}
            {children}
            <OverlayTrigger placement="right" overlay={<Tooltip>{title}</Tooltip>}>
              <i className="sidenav-mini-icon">{minititle}</i>
            </OverlayTrigger>
            <span className="item-name">{title}</span>
          </Link>
        </Nav.Item>
      )}
    </>
  );
};

SidebarMenu.displayName = 'SidebarMenu';
export default SidebarMenu;
