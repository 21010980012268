import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Role, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import RoleDetails from '../../components/roles/detail/RoleDetails';
import { Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';

function RoleDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { roleId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [role, setRole] = useState<Role>();
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Roles, 'read');

      if (hasPermission) {
        roleId && fetchRole(roleId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [roleId, permissionsLoaded]);

  const fetchRole = async (roleId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/roles/${roleId}`);
      const roleResponse = response.data;
      setRole(roleResponse);
      setDynamicTitle(roleResponse.title)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setRole(undefined)
    roleId && fetchRole(roleId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <RoleDetails role={role} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></RoleDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default RoleDetail;
