import axios from 'axios';
// Default fallback error message
const DEFAULT_ERROR_MESSAGE = 'Unbekannter Fehler aufgetreten (e000)';
/**
 * Extracts error message and UI ID from response headers or error object
 * @param {AxiosError} error - The axios error object
 * @returns {string} Formatted error message string in format "message (id)" or default message
 */
const extractErrorMessage = (error) => {
    var _a, _b;
    // Check if headers contain error information
    const headers = (_a = error.response) === null || _a === void 0 ? void 0 : _a.headers;
    const errorMessage = headers === null || headers === void 0 ? void 0 : headers['x-error-message'];
    const errorUID = headers === null || headers === void 0 ? void 0 : headers['x-error-uid'];
    // Format as "message (id)" if both values exist
    if (errorMessage && errorUID) {
        console.error("errorMessage: ", errorMessage, "errorUID: ", errorUID);
        return `${errorMessage} (${errorUID})`;
    }
    // Use only error message if it exists in header
    if (errorMessage) {
        console.error("errorMessage", errorMessage);
        return errorMessage;
    }
    // Try to get error message from response data
    const responseData = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data;
    if (responseData === null || responseData === void 0 ? void 0 : responseData.message) {
        console.error("responseData.message", responseData.message);
        return responseData.message;
    }
    // Return default error message if nothing else is available
    console.error(DEFAULT_ERROR_MESSAGE);
    return DEFAULT_ERROR_MESSAGE;
};
// Create axios instance for the application
const axiosInstance = axios.create();
/**
 * Response interceptor to catch and process errors globally
 * Dispatches a custom event for global error handling
 */
axiosInstance.interceptors.response.use(
// Success handler
(response) => response, 
// Error handler
(error) => {
    // Dispatch error event for global handling
    const errorMessage = extractErrorMessage(error);
    const errorEvent = new CustomEvent('api-error', {
        detail: {
            error,
            message: errorMessage
        }
    });
    window.dispatchEvent(errorEvent);
    // Re-throw error for local error handling
    return Promise.reject(error);
});
class ApiClient {
    /**
     * Gets authentication data and company ID for API requests
     * @returns {Object} Object containing headers with authorization token and company ID
     * @throws {Error} If no auth token is available
     */
    static getRequestData() {
        const companyId = sessionStorage.getItem('companyId');
        const token = localStorage.getItem(`accessToken_${companyId}`);
        if (!token) {
            throw new Error(`Trying to access protected endpoint but no auth token available!`);
        }
        return { headers: { Authorization: `Bearer ${token}` }, companyId };
    }
    /**
     * Sends a GET request to the API
     * Uses the central error interceptor for global error handling
     * @param {string} endpoint - API endpoint to call
     * @returns {Promise<AxiosResponse>} Promise with the API response
     */
    static get(endpoint) {
        const requestData = ApiClient.getRequestData();
        return axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`, {
            headers: requestData.headers,
        });
    }
    /**
     * Sends a POST request to the API
     * Uses the central error interceptor for global error handling
     * @param {string} endpoint - API endpoint to call
     * @param {any} body - Request body data
     * @param {AxiosRequestConfig} config - Optional axios config
     * @returns {Promise<AxiosResponse>} Promise with the API response
     */
    static post(endpoint, body, config) {
        const requestData = ApiClient.getRequestData();
        return axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`, body, Object.assign({ headers: requestData.headers }, config));
    }
    /**
     * Sends a PUT request to the API
     * Uses the central error interceptor for global error handling
     * @param {string} endpoint - API endpoint to call
     * @param {any} body - Request body data
     * @returns {Promise<AxiosResponse>} Promise with the API response
     */
    static put(endpoint, body) {
        const requestData = ApiClient.getRequestData();
        return axiosInstance.put(`${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`, body, {
            headers: requestData.headers,
        });
    }
    /**
     * Sends a DELETE request to the API
     * Uses the central error interceptor for global error handling
     * @param {string} endpoint - API endpoint to call
     * @returns {Promise<AxiosResponse>} Promise with the API response
     */
    static delete(endpoint) {
        const requestData = ApiClient.getRequestData();
        return axiosInstance.delete(`${process.env.REACT_APP_BACKEND_URL}/${requestData.companyId}${endpoint}`, {
            headers: requestData.headers,
        });
    }
}
export { ApiClient, extractErrorMessage };
