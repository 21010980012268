import { FieldConfig } from 'c1g-ui-library';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Location, PaymentMethods } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { getFieldConfigByResourceName } from '../../../utils/utils';
import InfoDisplay, { RowConfig } from '../../InfoDisplay';
import SkeletonRows from '../../SkeletonRows';
import StatusIndicator from '../../StatusIndicator';

type LocationPaymentProps = {
    location?: Location;
    fieldConfigs: FieldConfig[]
    paymentMethods?: PaymentMethods
};

/**
 * LocationPayment Component
 * 
 * This component displays detailed payment-related information for a location, including landlord 
 * details, payment timing, methods, and additional contact information. It utilizes the `InfoDisplay`
 * component to structure data across categories such as payment data, payment terms, and miscellaneous 
 * information. If data is unavailable or loading, `SkeletonRows` placeholders are displayed to ensure 
 * consistent layout.
 * 
 * Props:
 * - `location`: Optional `Location` object containing payment details like IBAN and intended use.
 * - `fieldConfigs`: Array of `FieldConfig` objects for dynamically rendering specific fields.
 * - `paymentMethods`: Optional `PaymentMethods` enum or mapping for retrieving payment method labels.
 * 
 * Example usage:
 * ```tsx
 * <LocationPayment location={locationData} fieldConfigs={fieldConfigs} paymentMethods={paymentMethodsData} />
 * ```
 */

const LocationPayment: React.FC<LocationPaymentProps> = ({ location, fieldConfigs, paymentMethods }) => {
    const paymentData: RowConfig[] = [
        { label: "Name", value: location?.landlordName || '-' },
        { label: "IBAN", value: location?.landlordIban || '-' },
        { label: "Verwendungszweck", value: location?.landlordIntendedUse || '-' },
        { label: "Dauerauftrag", value: <StatusIndicator isActive={!!location?.standingOrder}></StatusIndicator> },
    ];

    const payment: RowConfig[] = [
        { label: "Zahlungszeitpunkt", value: getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.paymentTime')?.options ?? {}, location?.paymentTime.toString()) },
        { label: "Zahlungsart", value: getEnumValue(paymentMethods ?? {}, location?.costsPaymentMethod) },
    ];

    const miscellaneous: RowConfig[] = [
        { label: "Belegart", value: getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'locations.paymentDocumentType')?.options ?? {}, location?.paymentDocumentType.toString()) },
    ];

    return (
        <Row>
            <Col md={6} >
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Zahlungsdaten' config={paymentData} />
                        )}

                    </Card.Body>
                </Card>
            </Col>
            <Col md={6} >
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Zahlungsmodalitäten' config={payment} />
                        )}

                    </Card.Body>
                </Card>
            </Col>
            <Col md={6} >
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={5} />
                        ) : (
                            <InfoDisplay title='Sonstiges' config={miscellaneous} />
                        )}

                    </Card.Body>
                </Card>
            </Col>
            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        {!location ? (
                            <SkeletonRows count={2} />
                        ) : (<>
                            <h5 className="mb-3">Notizen zur Zahlung</h5>
                            <p>{location.notesPayment ?? '-'}</p>
                        </>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default LocationPayment;
