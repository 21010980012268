import { LogEntry } from 'c1g-ui-library';
import React from 'react';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import { formatDate } from '../utils/utils';
import JsonViewer from './JsonViewer';

interface LogAccordionProps {
    logs: LogEntry[];
}

const LogAccordion: React.FC<LogAccordionProps> = ({ logs }) => {
    return (
        <Accordion>
            {logs.map((entry, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                        <div>
                            <h5 className="p-0 m-0 text-primary">{formatDate(entry.created, 'd.m.Y - H:i:s Uhr')}</h5>
                            <Table borderless size="sm" className='m-0 p-0 text-black'>
                                <tbody>
                                    <tr>
                                        <td className="fw-bold p-0">Methode:</td>
                                        <td className='py-1'>{entry.method}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold p-0">GET-Variablen:</td>
                                        <td className='py-1'>{entry._get ?? '-'}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='pt-0'>
                        <Row>
                            {entry._post &&
                                <Col>
                                    <JsonViewer data={entry._post} />
                                </Col>}
                            <Col>
                                <JsonViewer data={entry._server} />
                            </Col>
                        </Row>

                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default LogAccordion;
