import { useState, useCallback, useEffect } from 'react';
/**
 * Custom hook for managing selection state (single/multi-select with shift support).
 *
 * Supports:
 * - Toggling selection of individual items
 * - Shift-click multi-select behavior
 * - Select all / deselect all
 * - Tracking selected count and "all selected" state
 *
 * @param items - The list of items to manage selection for
 * @param getId - Optional function to extract an ID from an item (defaults to `item.id`)
 */
const useSelection = (items, getId) => {
    const [selectedItems, setSelectedItems] = useState({});
    const [lastClickedIndex, setLastClickedIndex] = useState(null);
    const getItemId = useCallback((item) => (getId ? getId(item) : item.id), [getId]);
    // Initialize or reset selection state when items change
    useEffect(() => {
        const newSelectedItems = items.reduce((acc, item) => {
            const id = getItemId(item);
            acc[id] = false;
            return acc;
        }, {});
        setSelectedItems(newSelectedItems);
    }, [items, getItemId]);
    // Select all items
    const handleSelectAll = useCallback(() => {
        const newSelectedItems = items.reduce((acc, item) => {
            const id = getItemId(item);
            acc[id] = true;
            return acc;
        }, {});
        setSelectedItems(newSelectedItems);
    }, [items, getItemId]);
    // Deselect all items
    const handleDeSelectAll = useCallback(() => {
        const newSelectedItems = Object.keys(selectedItems).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});
        setSelectedItems(newSelectedItems);
    }, [selectedItems]);
    /**
     * Toggle selection for a specific row.
     * Supports shift-click multi-selection between the last clicked row and the current one.
     */
    const handleSelectRow = useCallback((itemId, event) => {
        setSelectedItems(prev => {
            const newSelectedItems = Object.assign({}, prev);
            const currentIndex = items.findIndex(item => getItemId(item) === itemId);
            if (event.shiftKey && lastClickedIndex !== null && lastClickedIndex !== currentIndex) {
                const start = Math.min(lastClickedIndex, currentIndex);
                const end = Math.max(lastClickedIndex, currentIndex);
                for (let i = start; i <= end; i++) {
                    const id = getItemId(items[i]);
                    newSelectedItems[id] = true;
                }
            }
            else {
                newSelectedItems[itemId] = !prev[itemId];
            }
            setLastClickedIndex(currentIndex);
            return newSelectedItems;
        });
    }, [items, getItemId, lastClickedIndex]);
    const isAllSelected = items.length > 0 && Object.values(selectedItems).every(Boolean);
    const selectedCount = Object.values(selectedItems).filter(Boolean).length;
    return {
        selectedItems,
        isAllSelected,
        selectedCount,
        handleSelectAll,
        handleDeSelectAll,
        handleSelectRow,
        setSelectedItems,
    };
};
export { useSelection };
