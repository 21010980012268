import { Setting, usePermissions } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { GlobalDataProduct, Permissions } from '../../interfaces';
import { PermissionsEnum } from '../../utils/enum';
import SkeletonCard from '../locations/skeleton/SkeletonCard';
import GenericInfosCard from '../products/GenericInfosCard';
import EditGlobalDataProductsModal from './modal/EditGlobalDataProductsModal';

const translationMap: { [key: string]: string } = {
    "instructionRemarks": "instruction_remarks",
    "degreeExaminer": "degree_examiner",
    "subsidyDescription": "subsidy_description",
    "priceRemarks": "price_remarks",
    "dateRemarks": "date_remarks",
    "requirements": "requirements",
    "certNumber": "cert_number",
    "certStatus": "cert_status",
    "contactSalutation": "contact_salutation",
    "contactFirstName": "contact_first_name",
    "contactLastName": "contact_last_name",
    "contactEmail": "contact_email",
    "contactPhone": "contact_phone",
    "unterrichtsForm": "unterrichts_form"
};

const normalLabelsMap: { [key: string]: string } = {
    "instructionRemarks": "Unterrichtszeiten",
    "degreeExaminer": "Prüfende Stelle",
    "subsidyDescription": "Zulassung zur Förderung mit Bildungsgutschein",
    "priceRemarks": "Kostenbemerkung",
    "dateRemarks": "Bemerkung",
    "requirements": "Zugang",
    "certNumber": "Zertifizierung, Nummer",
    "certStatus": "Zertifizierung, Status",
    "contactSalutation": "Kontakt, Anrede",
    "contactFirstName": "Kontakt, Vorname",
    "contactLastName": "Kontakt, Nachname",
    "contactEmail": "Kontakt, E-Mail Adresse",
    "contactPhone": "Kontakt, Telefonnummer",
    "unterrichtsForm": "Unterrichtsform"
};

const getTranslatedLabel = (key: string, showXmlFieldNames: boolean): string => {
    return showXmlFieldNames ? translationMap[key] || key : normalLabelsMap[key] || key;
};

interface GlobalDataProductsDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null;
    onSubmitSuccess: (settingsId: number) => void;
}

const GlobalDataProductsDetails: React.FC<GlobalDataProductsDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess,
}) => {
    const [showXmlFieldNames, setShowXmlFieldNames] = useState(false);
    const handleToggleXmlFieldNames = () => setShowXmlFieldNames(!showXmlFieldNames);
    const globalDataProduct = setting?.details as GlobalDataProduct;
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    function getInfo1() {
        return [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
    }

    function getInfo2() {
        return globalDataProduct
            ? [
                {
                    label: getTranslatedLabel('instructionRemarks', showXmlFieldNames),
                    value: globalDataProduct.instructionRemarks || '-',
                },
                {
                    label: getTranslatedLabel('unterrichtsForm', showXmlFieldNames),
                    value: globalDataProduct.unterrichtsForm || '-',
                },
                {
                    label: getTranslatedLabel('degreeExaminer', showXmlFieldNames),
                    value: globalDataProduct.degreeExaminer || '-',
                },
                {
                    label: getTranslatedLabel('subsidyDescription', showXmlFieldNames),
                    value: globalDataProduct.subsidyDescription || '-',
                },
                {
                    label: getTranslatedLabel('priceRemarks', showXmlFieldNames),
                    value: globalDataProduct.priceRemarks || '-',
                },
                {
                    label: getTranslatedLabel('dateRemarks', showXmlFieldNames),
                    value: globalDataProduct.dateRemarks || '-',
                },
                {
                    label: getTranslatedLabel('requirements', showXmlFieldNames),
                    value: globalDataProduct.requirements || '-',
                },
            ]
            : [];
    }

    function getInfo3() {
        return globalDataProduct
            ? [
                {
                    label: getTranslatedLabel('certNumber', showXmlFieldNames),
                    value: globalDataProduct.certNumber || '-',
                },
                {
                    label: getTranslatedLabel('certStatus', showXmlFieldNames),
                    value: globalDataProduct.certStatus || '-',
                },
            ]
            : [];
    }

    function getInfo4() {
        return globalDataProduct
            ? [
                {
                    label: getTranslatedLabel('contactSalutation', showXmlFieldNames),
                    value: globalDataProduct.contactSalutation || '-',
                },
                {
                    label: getTranslatedLabel('contactFirstName', showXmlFieldNames),
                    value: globalDataProduct.contactFirstName || '-',
                },
                {
                    label: getTranslatedLabel('contactLastName', showXmlFieldNames),
                    value: globalDataProduct.contactLastName || '-',
                },
                {
                    label: getTranslatedLabel('contactEmail', showXmlFieldNames),
                    value: globalDataProduct.contactEmail || '-',
                },
                {
                    label: getTranslatedLabel('contactPhone', showXmlFieldNames),
                    value: globalDataProduct.contactPhone || '-',
                },
            ]
            : [];
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <div className="d-flex justify-content-center align-items-center">
                    <Form.Switch
                        id="show-xml-names-switch"
                        label="XML-Feldnamen"
                        checked={showXmlFieldNames}
                        onChange={handleToggleXmlFieldNames}
                        className="me-3"
                    />
                    <Button disabled={!userHasPermissionByRight(PermissionsEnum.Settings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                        Bearbeiten
                    </Button>
                </div>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={3} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo3()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={5} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo4()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={6} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {setting && showAddEditModal && (
                <EditGlobalDataProductsModal
                    modalTitle={`${setting.title} bearbeiten`}
                    setting={setting}
                    onSubmitSuccess={onSubmitSuccess}
                    onModalClose={() => setShowAddEditModal(false)}
                />
            )}
        </>
    );
};

export default GlobalDataProductsDetails;
