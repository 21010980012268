import { AxiosError } from 'axios';
import { NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import FocusDetails from '../../components/focuses/detail/FocusDetails';
import { Focus, Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { mockFocuses } from './focuses';

function FocusDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { focusId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [focus, setFocus] = useState<Focus>();
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, userHasPermission, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Focuses, 'read');

      if (hasPermission) {
        focusId && fetchFocus(focusId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [focusId, permissionsLoaded]);

  const fetchFocus = async (focusId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/keywords/${keywordId}`);
      // const keywordResponse = response.data;
      setFocus(mockFocuses[0]);
      //setDynamicTitle(keywordResponse.title)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setFocus(undefined)
    focusId && fetchFocus(focusId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <FocusDetails focus={focus} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></FocusDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default FocusDetail;
