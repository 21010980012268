import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, usePermissions, useResetUrlParams, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Col, Row, Table, } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import SearchInput from '../../components/SearchInput';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import { Bdks, Permissions } from '../../interfaces';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { formatEuro } from '../../utils/utils';

interface BdksResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Bdks[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const BdksList = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [bdksList, setBdksList] = useState<Bdks[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const { items: sortedBdks, requestSort } = useSortableData(bdksList, false, { field: 'title', type: 'asc' });


  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const fetchBdksList = useCallback(async () => {
    setBdksList([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (limit.toString()) {
        queryParams += `&limit=${limit}`;
      }
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      const response = await ApiClient.get(`/bdks${queryParams}`);
      const bdksListResponse = response.data as BdksResponse

      setTotalPages(bdksListResponse.amountPages);
      setBdksList(bdksListResponse.list);
      setCurrentPage(bdksListResponse.page);
      setLimit(bdksListResponse.itemsPerPage);
      setTotalEntries(bdksListResponse.amountAllItems);
      setAvailableFilter(bdksListResponse.searchFilters)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery, limit]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Bdks, 'read');

      if (hasPermission) {
        fetchBdksList();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchBdksList,
    permissionsLoaded,
    searchQuery,
    limit
  ]);

  const resetSearch = () => {
    resetUrlParams()
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>BDKS</h3>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={6}>
                {searchQuery ?
                  <div className="d-flex align-items-baseline">
                    <h4 className="m-0">Suchergebnisse</h4>
                    <span className="ms-3 d-flex align-items-baseline">
                      <Button
                        className="m-0 p-0 fs-6"
                        variant="link"
                        onClick={resetSearch}
                      >
                        Suche beenden
                      </Button>
                    </span>
                  </div>

                  : <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedCombo}
                    setSelectedCombo={setSelectedCombo}
                    borderRadius="normal"
                  ></ComboButtonGroup>}
              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('bdksId')}
              >
                ID
              </th>
              <th
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                Titel
              </th>
              <th
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('costs')}
              >
                Kosten
              </th>

            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedBdks.map((bdks) => (
                <tr key={bdks.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Link
                      to={`/${companyId}/bdks/${bdks.id}`}
                      className="btn btn-link ps-0"
                    >
                      {bdks.bdksId}
                    </Link>
                  </td>

                  <td>
                    {bdks.title}
                  </td>

                  <td>
                    {bdks.costs ? formatEuro(bdks.costs) : '-'}
                  </td>
                </tr>
              ))}
            {!isLoading && sortedBdks.length === 0 && (
              <TableNoDataMessage
                message="Keine BDKS Einträge"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row>
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default BdksList;
