import { Fragment, memo, MouseEvent } from 'react';
import { Accordion } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  faArrowDownShortWide,
  faArrowsToCircle,
  faArrowUpArrowDown,
  faBarcode,
  faFileCertificate,
  faGear,
  faGraduationCap,
  faHouse,
  faImage,
  faKey,
  faListCheck,
  faLocationDot,
  faRightFromBracket,
  faShieldKeyhole,
  faUser,
  faUsers,
  faWebhook,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePermissions } from 'c1g-ui-library';
import { Permissions } from '../../../interfaces';
import { PermissionsEnum } from '../../../utils/enum';
import SidebarMenu from '../../sidebar/sidebar-menu';

const VerticalNav: React.FC = memo(() => {
  const { userHasPermissionByRight } = usePermissions<Permissions>();
  const { companyId = 'oc' } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const handleLogout = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const storedCompanyId = sessionStorage.getItem('companyId') || 'oc';
    localStorage.removeItem(`accessToken_${storedCompanyId}`);
    localStorage.removeItem(`lastPath_${storedCompanyId}`);
    navigate(`/${storedCompanyId}/login`);
  };

  const hasModulePermissions = (): boolean =>
    userHasPermissionByRight(PermissionsEnum.Certificates, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Optimization, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Locations, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Products, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Media, 'read');

  const hasManagementPermissions = (): boolean =>
    userHasPermissionByRight(PermissionsEnum.Bdks, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Systematics, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Keywords, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Focuses, 'read');

  const hasAdministrationPermissions = (): boolean =>
    userHasPermissionByRight(PermissionsEnum.Users, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Roles, 'read') ||
    userHasPermissionByRight(PermissionsEnum.ApiKeys, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Webhooks, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Settings, 'read') ||
    userHasPermissionByRight(PermissionsEnum.Tasks, 'read');

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <SidebarMenu isTag="true" pathname={`/${companyId}/`} title="Dashboard">
          <div>
            <FontAwesomeIcon width={15} icon={faHouse} />
          </div>
        </SidebarMenu>
      </Accordion>

      {hasModulePermissions() && (
        <Accordion as="ul" className="navbar-nav iq-main-menu">
          <div className="d-flex flex-column justify-content-center gap-2">
            <li className="nav-item static-item ps-0">
              <Link className="nav-link static-item disabled" to="#" tabIndex={-1}>
                <span className="default-icon ps-1">MODULE</span>
                <div className="mini-icon" style={{ paddingLeft: '10px' }}>-</div>
              </Link>
            </li>
            {userHasPermissionByRight(PermissionsEnum.Certificates, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/certificates`} title="Zertifikate">
                <div>
                  <FontAwesomeIcon width={15} icon={faFileCertificate} />
                </div>
              </SidebarMenu>
            )}
            {userHasPermissionByRight(PermissionsEnum.Optimization, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/optimization`} title="Optimierung">
                <div>
                  <FontAwesomeIcon width={15} icon={faWrench} />
                </div>
              </SidebarMenu>
            )}
            {userHasPermissionByRight(PermissionsEnum.Locations, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/locations`} title="Standorte">
                <div>
                  <FontAwesomeIcon width={15} icon={faLocationDot} />
                </div>
              </SidebarMenu>
            )}
            {userHasPermissionByRight(PermissionsEnum.Products, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/products`} title="Produkte">
                <div>
                  <FontAwesomeIcon width={15} icon={faGraduationCap} />
                </div>
              </SidebarMenu>
            )}
            {userHasPermissionByRight(PermissionsEnum.Media, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/media/0`} title="Medien">
                <div>
                  <FontAwesomeIcon width={15} icon={faImage} />
                </div>
              </SidebarMenu>
            )}
          </div>
        </Accordion>
      )}

      {hasManagementPermissions() && (
        <Accordion as="ul" className="navbar-nav iq-main-menu gap-2">
          <li className="nav-item static-item ps-0">
            <Link className="nav-link static-item disabled" to="#" tabIndex={-1}>
              <span className="default-icon ps-1">VERWALTUNG</span>
              <div className="mini-icon" style={{ paddingLeft: '10px' }}>-</div>
            </Link>
          </li>
          {userHasPermissionByRight(PermissionsEnum.Bdks, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/bdks`} title="BDKS">
              <div>
                <FontAwesomeIcon width={15} icon={faBarcode} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.Systematics, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/systematics`} title="Systematiken">
              <div>
                <FontAwesomeIcon width={15} icon={faArrowDownShortWide} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.Keywords, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/keywords`} title="Keywords">
              <div>
                <FontAwesomeIcon width={15} icon={faKey} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.Focuses, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/focuses`} title="Schwerpunkte">
              <div>
                <FontAwesomeIcon width={15} icon={faArrowsToCircle} />
              </div>
            </SidebarMenu>
          )}
        </Accordion>
      )}

      {hasAdministrationPermissions() && (
        <Accordion as="ul" className="navbar-nav iq-main-menu">
          <li className="nav-item static-item ps-0">
            <Link className="nav-link static-item disabled" to="#" tabIndex={-1}>
              <span className="default-icon ps-1">ADMINISTRATION</span>
              <div className="mini-icon" style={{ paddingLeft: '10px' }}>-</div>
            </Link>
          </li>
          {userHasPermissionByRight(PermissionsEnum.Users, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/users`} title="Benutzer">
              <div>
                <FontAwesomeIcon width={15} icon={faUser} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.Roles, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/roles`} title="Rollen">
              <div>
                <FontAwesomeIcon width={15} icon={faUsers} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.ApiKeys, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/apikeys`} title="API-Schlüssel">
              <div>
                <FontAwesomeIcon width={15} icon={faShieldKeyhole} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.Webhooks, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/webhooks`} title="Webhooks">
              <div>
                <FontAwesomeIcon width={15} icon={faWebhook} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.Tasks, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/tasks`} title="CronTasks">
              <div>
                <FontAwesomeIcon width={15} icon={faListCheck} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.ExportImport, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/export-import`} title="Export & Import">
              <div>
                <FontAwesomeIcon width={15} icon={faArrowUpArrowDown} />
              </div>
            </SidebarMenu>
          )}
          {userHasPermissionByRight(PermissionsEnum.Settings, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/settings`} title="Einstellungen">
              <div>
                <FontAwesomeIcon width={15} icon={faGear} />
              </div>
            </SidebarMenu>
          )}
        </Accordion>
      )}

      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <SidebarMenu
          pathname={`/${companyId}/login`}
          isTag="true"
          title="Ausloggen"
          onClick={handleLogout}
        >
          <div>
            <FontAwesomeIcon width={15} icon={faRightFromBracket} />
          </div>
        </SidebarMenu>
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = 'VerticalNav';
export default VerticalNav;
