import { faUserGraduate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Scrollbar from 'smooth-scrollbar';
import CompanyDropdown from '../../CompanyDropdown';
import VerticalNav from './vertical-nav';

interface SidebarProps {
  app_name: string
}

/**
 * Sidebar component for the application layout.
 *
 * - Displays the app name, navigation links (via VerticalNav), and a company selector (CompanyDropdown).
 * - Supports toggling between expanded and mini sidebar modes.
 * - Sidebar state (open/closed) is persisted in localStorage.
 * - Automatically adjusts layout responsively on window resize.
 * - Uses smooth-scrollbar for enhanced scroll behavior.
 *
 * Props:
 * - app_name (string): The name of the application displayed in the sidebar.
 */
const Sidebar: React.FC<SidebarProps> = ({ app_name }) => {
  const { companyId = 'oc' } = useParams<{ companyId?: string }>();
  const [sidebarClosed, setSidebarClosed] = useState<boolean>(() => {
    return localStorage.getItem('sidebarClosed') === 'true';
  });

  const toggleSidebar = () => {
    const newState = !sidebarClosed;
    setSidebarClosed(newState);
    localStorage.setItem('sidebarClosed', String(newState));
    document
      .getElementsByTagName('ASIDE')[0]
      .classList.toggle('sidebar-mini', newState);
  };

  useEffect(() => {
    const savedState = localStorage.getItem('sidebarClosed') === 'true';
    setSidebarClosed(savedState);
    document
      .getElementsByTagName('ASIDE')[0]
      .classList.toggle('sidebar-mini', savedState);
  }, []);

  useEffect(() => {
    Scrollbar.init(document.querySelector('#my-scrollbar') as HTMLElement);

    const resizeHandler = () => {
      setSidebarClosed(true);
      const tabs = document.querySelectorAll('.nav');
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]');

      if (window.innerWidth < 2000) {
        tabs.forEach((elem) => {
          if (
            !elem.classList.contains('flex-column') &&
            elem.classList.contains('nav-tabs') &&
            elem.classList.contains('nav-pills')
          ) {
            elem.classList.add('flex-column', 'on-resize');
          }
        });
        if (sidebarResponsive && !sidebarResponsive.classList.contains('sidebar-mini')) {
          sidebarResponsive.classList.add('sidebar-mini', 'on-resize');
        }
      } else {
        tabs.forEach((elem) => {
          if (elem.classList.contains('on-resize')) {
            elem.classList.remove('flex-column', 'on-resize');
          }
        });
        if (
          sidebarResponsive &&
          sidebarResponsive.classList.contains('sidebar-mini') &&
          sidebarResponsive.classList.contains('on-resize')
        ) {
          sidebarResponsive.classList.remove('sidebar-mini', 'on-resize');
        }
      }
    };

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <aside
      className="d-flex flex-column sidebar-white left-bordered sidebar sidebar-mini sidebar-base"
      data-sidebar="responsive"
    >
      <div className="sidebar-header d-flex align-items-center justify-content-start">
        <Link to={`/${companyId}`} className="navbar-brand">
          <div style={{ marginLeft: sidebarClosed ? '11px' : '15px' }}>
            <FontAwesomeIcon style={{ color: '#212529' }} icon={faUserGraduate} />
          </div>
          <h4 className="logo-title">{app_name}</h4>
        </Link>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
          onClick={toggleSidebar}
        >
          <i className="icon">
            <svg
              width="20"
              className="icon-20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.25 12.2744L19.25 12.2744"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </i>
        </div>
      </div>

      <div className="pt-0 sidebar-body data-scrollbar flex-grow-1" data-scroll="1" id="my-scrollbar">
        <div className="sidebar-list navbar-collapse" id="sidebar">
          <VerticalNav />
        </div>
      </div>

      <div className="sidebar-footer p-2 border-top">
        <CompanyDropdown sidebarClosed={sidebarClosed} />
      </div>
    </aside>
  );
};

export default Sidebar;
