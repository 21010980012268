import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import DeleteModal from '../DeleteModal';
import { usePermissions, MediaItem } from 'c1g-ui-library';
import { Permissions } from '../../interfaces';
import { PermissionsEnum } from '../../utils/enum';

interface SelectionActionsProps {
  selectedCount: number;
  selectedMedia: { [key: string]: boolean };
  handleDeSelectAll: () => void;
  handleSelectAll: () => void;
  onSubmitSuccess: () => void;
  media: MediaItem[];
  amountAllItems: number
  queryParams: string
}

const SelectionActions: React.FC<SelectionActionsProps> = ({
  selectedCount,
  selectedMedia,
  handleDeSelectAll,
  onSubmitSuccess,
  handleSelectAll,
  media,
  amountAllItems,
  queryParams
}) => {
  const { userHasPermissionByRight } = usePermissions<Permissions>();
  const [isAllSelected, setIsAllSelected] = useState(amountAllItems === selectedCount)

  const selectedMediaIds = isAllSelected ? [] : Object.keys(selectedMedia)
    .filter(key => selectedMedia[key] === true)
    .map(Number);

  const anySelectedMediaSecure = selectedMediaIds.some(id => {
    const mediaItem = media.find(item => item.id === id);
    return mediaItem && mediaItem.locked;
  });

  return (
    <tr>
      <td colSpan={7}>
        <Row
          className="d-flex justify-content-between p-3 bg-light-blue"
        >
          <Col className="d-flex align-items-center">
            <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
            <Button variant="link" onClick={handleDeSelectAll}>
              Auswahl aufheben
            </Button>
            {
              !isAllSelected && (
                <Button
                  variant="link"
                  className="ps-0"
                  onClick={() => {
                    setIsAllSelected(true);
                    handleSelectAll();
                  }}
                >
                  Alle Auswählen ({amountAllItems})
                </Button>
              )
            }

          </Col>
          <Col className="d-flex justify-content-end">
            {!anySelectedMediaSecure && (
              <DeleteModal
                modalTitle="Löschen?"
                buttonName="Löschen"
                entityType='media'
                entityIds={selectedMediaIds}
                onSubmit={onSubmitSuccess}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'delete')}
                queryParams={queryParams}
              >
              </DeleteModal>
            )}
          </Col>
        </Row>
      </td>
    </tr>
  );
};

export default SelectionActions;
