import { jsx as _jsx } from "react/jsx-runtime";
// ToastContext.tsx
import { createContext, useContext, useState } from 'react';
const ToastContext = createContext(undefined);
const ToastProvider = ({ children }) => {
    const [toast, setToast] = useState({ show: false, message: '', error: false });
    const showToast = (message, error = false) => {
        setToast({ show: true, message, error });
    };
    const hideToast = () => {
        setToast(Object.assign(Object.assign({}, toast), { show: false }));
    };
    return (_jsx(ToastContext.Provider, { value: Object.assign(Object.assign({}, toast), { showToast, hideToast }), children: children }));
};
const useToast = () => {
    const context = useContext(ToastContext);
    if (context === undefined) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};
export { ToastProvider, useToast };
