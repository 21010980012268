import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, usePermissions, useResetUrlParams, useSelection, useSortableData, useTableHeight, useToast, Webhook } from 'c1g-ui-library';
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Col, Form, Row, Table, } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';
import SearchInput from '../../components/SearchInput';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import AddEditWebhookModal from '../../components/webhooks/modal/AddEditWebhookModal';
import { Permissions } from '../../interfaces';
import {
  getEnumValue,
  PermissionsEnum,
  WebhookModuleEnum,
} from '../../utils/enum';
import { getWebhookStatusIcon } from '../../utils/webhooks/utils';

interface WebhooksResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Webhook[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Webhooks = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const { items: sortedWebhooks, requestSort } = useSortableData(webhooks, false, { field: 'title', type: 'asc' });
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const getId = useCallback((webhook: Webhook) => webhook.id, []);

  const {
    selectedItems: selectedWebhooks,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(webhooks, getId);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const fetchWebhooks = useCallback(async () => {
    setWebhooks([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (limit.toString()) {
        queryParams += `&limit=${limit}`;
      }
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      const response = await ApiClient.get(`/webhooks${queryParams}`);
      setLastQueryParams(queryParams)
      const webhooksResponse = response.data as WebhooksResponse
      setTotalPages(webhooksResponse.amountPages);
      setWebhooks(webhooksResponse.list);
      setCurrentPage(webhooksResponse.page);
      setLimit(webhooksResponse.itemsPerPage);
      setTotalEntries(webhooksResponse.amountAllItems);
      setAvailableFilter(webhooksResponse.searchFilters)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery, limit]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Webhooks, 'read');

      if (hasPermission) {
        fetchWebhooks();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchWebhooks,
    permissionsLoaded,
    searchQuery,
    limit
  ]);


  const handleWebhookUpdateSubmit = () => {
    fetchWebhooks();
  };

  const resetSearch = () => {
    resetUrlParams()
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>Webhooks</h3>
          <Button disabled={!userHasPermissionByRight(PermissionsEnum.Webhooks, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
            Webhook hinzufügen
          </Button>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={6}>
                {searchQuery ?
                  <div className="d-flex align-items-baseline">
                    <h4 className="m-0">Suchergebnisse</h4>
                    <span className="ms-3 d-flex align-items-baseline">
                      <Button
                        className="m-0 p-0 fs-6"
                        variant="link"
                        onClick={resetSearch}
                      >
                        Suche beenden
                      </Button>
                    </span>
                  </div>

                  : <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedCombo}
                    setSelectedCombo={setSelectedCombo}
                    borderRadius="normal"
                  ></ComboButtonGroup>}
              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.Webhooks, 'write') && !userHasPermissionByRight(PermissionsEnum.Webhooks, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('status')}
              >
                Modul
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('status')}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedWebhooks}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                handleSelectAll={handleSelectAll}
                onSubmitSuccess={handleWebhookUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='webhooks'
                queryParams={lastQueryParams}
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedWebhooks.map((webhook) => (
                <tr key={webhook.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.Webhooks, 'write') && !userHasPermissionByRight(PermissionsEnum.Webhooks, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedWebhooks[webhook.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(webhook.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/webhooks/${webhook.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {webhook.title}
                    </Link>
                  </td>
                  <td>{getEnumValue(WebhookModuleEnum, webhook.module)}</td>
                  <td className='text-center'>
                    {getWebhookStatusIcon(webhook.status)}
                  </td>
                </tr>
              ))}
            {!isLoading && sortedWebhooks.length === 0 && (
              <TableNoDataMessage
                message="Keine Webhooks"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row>
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}
      {showAddEditModal && <AddEditWebhookModal
        modalTitle={`Webhook hinzufügen`}
        onSubmitSuccess={handleWebhookUpdateSubmit}
        onModalClose={() => setShowAddEditModal(false)}
      >
      </AddEditWebhookModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default Webhooks;
