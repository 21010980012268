import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Setting, useToast } from 'c1g-ui-library';
import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    Modal,
    Row,
    Spinner,
} from 'react-bootstrap';
import { WebflowSync } from '../../../interfaces';
import { WebflowSyncModuleEnum } from '../../../utils/enum';
import { handleInputChange } from '../../../utils/form/utils';
import Card from '../../bootstrap/card';
import { SelectOption } from '../../form/SelectWithSearch';

type WebflowSyncModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    forcePublish: number
    module: string[]
    sync: number
}

const WebflowSyncModal: React.FC<WebflowSyncModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose
}) => {
    const webflowSyncData = setting?.details as WebflowSync

    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        forcePublish: 1,
        module: webflowSyncData?.module ?? [],
        sync: webflowSyncData?.sync ?? 0
    });
    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error: any) {
                console.error((error as AxiosError).message);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: {
                forcePublish: formValues.forcePublish,
                module: formValues.module,
                sync: formValues.sync,
            }
        };
    }

    const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>, id: keyof FormValues) => {
        setFormValues((prev: any) => ({ ...prev, [id]: e.target.checked ? 1 : 0 }));
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        setFormValues((prev) => {
            let updatedModules = [...prev.module];
            if (checked) {
                if (!updatedModules.includes(id)) {
                    updatedModules.push(id);
                }
            } else {
                updatedModules = updatedModules.filter(moduleId => moduleId !== id);
            }
            return { ...prev, module: updatedModules };
        });
    };

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        disabled = false
    ) => (
        <Form.Group className="mb-3 w-100" controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}

            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={5}
                    type={type}
                    value={formValues[id]}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={type !== 'file' ? formValues[id] : ''}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );

    const createSwitchGroup = (
        id: keyof FormValues,
        label: string,
        required = false,
        disabled = false
    ) => (
        <Form.Group className="mb-3 w-100 text-black" controlId={id}>
            <Form.Label>{label}</Form.Label>
            <Form.Check
                type="switch"
                id={id}
                checked={formValues[id] === 1}
                onChange={(e) => handleSwitchChange(e, id)}
                required={required}
                disabled={disabled}
                className='text-black'
            />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );

    const createSelectGroup = (
        id: keyof FormValues,
        label: string,
        options: SelectOption[],
        placeholder?: string,
        required = false
    ) => (
        <Form.Group
            style={{ color: 'black' }}
            className="mb-3 w-100"
            controlId={id}
        >
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Select
                value={formValues[id]}
                onChange={(e) => handleInputChange(e, setFormValues)}
                required={required}
                style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );

    const renderCheckboxes = (label: string) => {
        const moduleKeys = Object.keys(WebflowSyncModuleEnum) as Array<keyof typeof WebflowSyncModuleEnum>;

        return (
            <Form.Group style={{ color: 'black' }} className="mb-3 w-100">
                {label && <Form.Label>{label}</Form.Label>}
                {moduleKeys.map((company) => (
                    <Form.Check
                        key={company}
                        type="checkbox"
                        id={company}
                        label={WebflowSyncModuleEnum[company]}
                        checked={formValues.module.includes(company)}
                        onChange={handleCheckboxChange}
                    />
                ))}
            </Form.Group>
        );
    };

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>
                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('title', 'Titel', 'text', true, true)}
                                            {createFormGroup('description', 'Beschreibung', 'text', false)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {renderCheckboxes('Module')}
                                            {createSwitchGroup('forcePublish', 'Sofort veröffentlichen')}
                                            {createSwitchGroup('sync', 'Synchronisation starten')}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default WebflowSyncModal;
