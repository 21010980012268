import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Setting, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import CounterIdents from '../../components/settings/CounterIdents';
import DefaultValuesExcelCertificationDetails from '../../components/settings/DefaultValuesExcelCertification';
import ExportNameConventions from '../../components/settings/ExportNameConventions';
import FileNamesDetails from '../../components/settings/FileNames';
import GlobalDataCompaniesDetails from '../../components/settings/GlobalDataCompanies';
import GlobalDataLocationsDetails from '../../components/settings/GlobalDataLocations';
import GlobalDataProductsDetails from '../../components/settings/GlobalDataProducts';
import MeinNowAccounts from '../../components/settings/MeinNowAccounts';
import PartnerNamesDetails from '../../components/settings/PartnerNames';
import PaymentMethods from '../../components/settings/PaymentMethods';
import WebflowSync from '../../components/settings/WebflowSync';
import { Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
  SettingType,
} from '../../utils/enum';

function SettingDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { settingId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [setting, setSetting] = useState<Setting | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Settings, 'read');

      if (hasPermission) {
        settingId && fetchSetting(settingId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [settingId, permissionsLoaded]);

  const fetchSetting = async (settingId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/settings?ident=${settingId}`);
      const settingResponse = response.data.list[0];
      setSetting({...settingResponse, details: settingResponse.details ?? {}});
      setDynamicTitle(settingResponse.title)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setSetting(null)
    settingId && fetchSetting(settingId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              {settingId === SettingType.globalProductFields && <GlobalDataProductsDetails  setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></GlobalDataProductsDetails>}
              {settingId === SettingType.globalLocationFields && <GlobalDataLocationsDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></GlobalDataLocationsDetails>}
              {settingId === SettingType.globalCompanyData && <GlobalDataCompaniesDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></GlobalDataCompaniesDetails>}
              {settingId === SettingType.webflowSync && <WebflowSync setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></WebflowSync>}
              {settingId === SettingType.meinNowAccounts && <MeinNowAccounts setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></MeinNowAccounts>}
              {settingId === SettingType.nameConventionLocations && <FileNamesDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></FileNamesDetails>}
              {settingId === SettingType.locationsPartners && <PartnerNamesDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></PartnerNamesDetails>}
              {settingId === SettingType.paymentMethods && <PaymentMethods setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></PaymentMethods>}
              {settingId === SettingType.counterIdents && <CounterIdents setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></CounterIdents>}
              {settingId === SettingType.defaultValuesExcelCertification && <DefaultValuesExcelCertificationDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></DefaultValuesExcelCertificationDetails>}
              {settingId === SettingType.nameConventionExport && <ExportNameConventions setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></ExportNameConventions>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default SettingDetail;
