import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, CompanyEnum, NotificationToast, Role, useCompanyNavigate, usePermissions, User, useToast } from 'c1g-ui-library';
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Permissions } from '../../../interfaces';
import {
  ActiveInactiveMapping,
  userSalutationOptions
} from '../../../utils/enum';
import { handleInputChange } from '../../../utils/form/utils';
import { generateRandomHex } from '../../../utils/utils';
import Card from '../../bootstrap/card';

type AddEditUsersModalProps = {
  modalTitle: string;
  user?: User;
  onSubmitSuccess: (locationsId: number) => void;
  userRoles: Role[]
  onModalClose: () => void;
};

interface SelectOption {
  value: string;
  label: string;
}

interface FormValues {
  accessToken: string,
  email: string,
  name: string,
  pass: string,
  roles_id: string,
  status: number
  title: string
  company: string[],
  salutation: number,
  firstname: string,
  secondName: string,
  lastname: string,
}

const AddEditUsersModal: React.FC<AddEditUsersModalProps> = ({
  modalTitle,
  user,
  onSubmitSuccess,
  userRoles,
  onModalClose
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const { userHasPermissionByRight } = usePermissions<Permissions>();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const companyNavigate = useCompanyNavigate()
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState({
    accessToken: user?.accessToken ?? '',
    email: user?.email ?? '',
    name: user?.name ?? '',
    pass: '',
    roles_id: user?.roles_id.toString() ?? '',
    status: user?.status ?? 1,
    title: user?.title ?? '',
    company: user?.company ?? [],
    salutation: user?.salutation ?? 0,
    firstname: user?.firstname ?? '',
    secondName: user?.secondName ?? '',
    lastname: user?.lastname ?? '',
  });
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });
  const [showPassword, setShowPassword] = useState(false);

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;
        if (user) {
          response = await ApiClient.put(
            `/users/${user.id}`,
            formValues
          );
        } else {
          response = await ApiClient.post('/users', formValues);
        }
        const usersId = response.data.users_id;
        onSubmitSuccess(usersId);
        showToast('Erfolgreich gespeichert', false);
      } catch (error: any) {
        console.error((error as AxiosError).message);
      } finally {
        setIsLoading(false);
        setValidated(false);
        const newForm: FormValues = {
          accessToken: '',
          email: '',
          name: '',
          pass: '',
          roles_id: '',
          status: 1,
          title: '',
          company: [],
          salutation: 0,
          firstname: '',
          secondName: '',
          lastname: ''
        }
        setFormValues(newForm)
        setInitialFormValues(newForm)
        onModalClose();
      }
    }
  };

  useEffect(() => {
    if (formValues.roles_id === '3') {
      setFormValues((prev) => ({ ...prev, accessToken: user?.accessToken ?? generateRandomHex() }));
    } else {
      setFormValues((prev) => ({ ...prev, accessToken: '' }));
    }
  }, [formValues.roles_id]);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormValues((prev) => {
      let updatedCompanies = [...prev.company];
      if (checked) {
        if (!updatedCompanies.includes(id)) {
          updatedCompanies.push(id);
        }
      } else {
        updatedCompanies = updatedCompanies.filter(moduleId => moduleId !== id);
      }
      return { ...prev, company: updatedCompanies };
    });
  };

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    infoLabel: string = ''
  ) => {

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const formatDate = (dateStr: string = "") => {
      if (type === 'date') {
        return dateStr.split(' ')[0];
      }
      return dateStr;
    };

    return (
      <Form.Group className="mb-3 w-100 text-black" controlId={id.toString()}>
        <Form.Label className='text-black'>{label} {infoLabel && <small>{infoLabel}</small>}</Form.Label>
        {type !== 'password' ? (
          <Form.Control
            style={{ backgroundColor: '#F9F9F9' }}
            className='text-black'
            type={type}
            value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]?.toString()) : ''}
            onChange={(e) => handleInputChange(e, setFormValues)}
            required={required}
            isInvalid={validated && !formValues[id]}
          />
        ) : (
          <InputGroup>
            <Form.Control
              style={{ backgroundColor: '#F9F9F9' }}
              className='text-black'
              type={showPassword ? 'text' : 'password'}
              value={formValues[id]?.toString() || ''}
              onChange={(e) => handleInputChange(e, setFormValues)}
              required={required}
              isInvalid={validated && !formValues[id]}
              autoComplete="new-password"
            />
            <Button
              variant=""
              onClick={toggleShowPassword}
              className="border-left-0"
              style={{ backgroundColor: '#F9F9F9', border: '1px solid #eee', }}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </Button>
          </InputGroup>
        )}
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    );
  };


  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        value={formValues[id]?.toString()}
        onChange={(e) => handleInputChange(e, setFormValues)}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const renderCheckboxes = (label: string) => {
    const companyKeys = Object.keys(CompanyEnum) as Array<keyof typeof CompanyEnum>;

    return (
      <Form.Group className="mb-3 w-100 text-black">
        {label && <Form.Label>{label}</Form.Label>}
        {companyKeys.map((company) => (
          <Form.Check
            key={company}
            type="checkbox"
            id={company}
            label={CompanyEnum[company]}
            checked={formValues.company.includes(company)}
            onChange={handleCheckboxChange}
          />
        ))}
      </Form.Group>
    );
  };

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError)
    onModalClose()
    companyNavigate('/users')
  }

  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}>
                  <FontAwesomeIcon width={30} icon={faXmark} />Schließen
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Row>
              <Col lg={4}>
                <Card className='card-block card-stretch card-height'>
                  <Card.Body>
                    <h5>Person</h5>
                    {createSelectGroup(
                      'salutation',
                      'Anrede',
                      Object.entries(userSalutationOptions).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                      true
                    )}
                    {createFormGroup('firstname', 'Vorname', 'text', true)}
                    {createFormGroup('secondName', 'Zweiter Vorname', 'text', false)}
                    {createFormGroup('lastname', 'Nachname', 'text', true)}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className='card-block card-stretch card-height'>
                  <Card.Body>
                    <h5>Allgemein</h5>
                    {createSelectGroup(
                      'status',
                      'Status',
                      Object.entries(ActiveInactiveMapping).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                      true
                    )}
                    {createSelectGroup(
                      'roles_id',
                      'Benutzergruppe',
                      userRoles.map(r => ({
                        value: r.id.toString(),
                        label: r.title,
                      })),
                      'Auswählen...',
                      true
                     )}
                    {renderCheckboxes("Unternehmen")}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className='card-block card-stretch card-height'>
                  <Card.Body>
                    <h5>Anmeldedaten</h5>
                    {formValues.roles_id === '3' ?
                      <Col >
                        {createFormGroup('accessToken', 'Accesstoken', 'password', true)}
                      </Col> :
                      <>
                        <Col>
                          {createFormGroup('email', 'E-Mail-Adresse', 'email', true)}
                        </Col>
                        <Col>
                          {createFormGroup('pass', 'Passwort', 'password', user ? false : true)}
                        </Col>
                      </>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditUsersModal;
