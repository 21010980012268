import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
/**
 * Hook to extract permission data and user metadata from a JWT stored in localStorage.
 *
 * - Decodes a JWT that is expected to include:
 *   - `views`: an object containing permission definitions per module
 *   - `user_id`: the ID of the currently logged-in user
 *   - `roles_id`: the role ID of the user
 *
 * - The permissions object is expected to include a `companies` field with allowed company IDs.
 *
 * - Supports custom permission types per frontend (generic), while Company is shared via the library.
 *
 * @template Permissions - Your app-specific permission structure (must include `companies: Company[]`)
 *
 * @param config - Optional configuration:
 *  - `defaultCompanyId`: fallback companyId if not found in sessionStorage
 *  - `tokenPrefix`: defaults to 'accessToken_', used to build the JWT key
 *
 * @returns Object with:
 *  - permission state and user info
 *  - helper functions to check access
 */
export function usePermissions() {
    const [permissions, setPermissions] = useState({ companies: [] });
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [userId, setUserId] = useState();
    const [rolesId, setRolesId] = useState();
    useEffect(() => {
        const companyId = sessionStorage.getItem('companyId');
        const token = localStorage.getItem(`accessToken_${companyId}`);
        setPermissionsLoaded(false);
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setPermissions(decodedToken.views || {});
                setUserId(decodedToken.user_id);
                setRolesId(decodedToken.roles_id);
            }
            catch (error) {
                console.error('Failed to decode token', error);
            }
        }
        setPermissionsLoaded(true);
    }, []);
    /**
     * Checks if the user has access to a given module/view key.
     *
     * @param viewKey - A string representing the module name
     * @returns true if the permission object for that view exists
     */
    const userHasPermission = (viewKey) => {
        return (permissions === null || permissions === void 0 ? void 0 : permissions[viewKey]) !== undefined;
    };
    /**
     * Checks if the user has a specific right (e.g., 'read', 'write') for a given module.
     *
     * @param viewKey - The permission module key (e.g. 'users', 'settings')
     * @param right - The right to check (e.g. 'read', 'delete')
     * @returns true if the right is explicitly set to true
     */
    const userHasPermissionByRight = (viewKey, right) => {
        const viewPermission = permissions === null || permissions === void 0 ? void 0 : permissions[viewKey];
        return (viewPermission === null || viewPermission === void 0 ? void 0 : viewPermission[right]) === true;
    };
    /**
     * Checks if the user has access to the given company.
     *
     * @param company - Company identifier (e.g. 'oc', 'cn')
     * @returns true if the company is in the user's permissions
     */
    const userHasCompanyAccess = (company) => {
        return permissions.companies.includes(company);
    };
    return {
        permissions,
        permissionsLoaded,
        userId,
        rolesId,
        userHasPermission,
        userHasPermissionByRight,
        userHasCompanyAccess,
    };
}
