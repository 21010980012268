import { Setting, usePermissions } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Permissions, WebflowSync } from '../../interfaces';
import { PermissionsEnum, WebflowSyncModuleEnum, YesNoMapping, getEnumValue } from '../../utils/enum';
import SkeletonCard from '../locations/skeleton/SkeletonCard';
import GenericInfosCard from '../products/GenericInfosCard';
import WebflowSyncModal from './modal/WebflowSyncModal';

interface WebflowSyncDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

const WebflowSyncDetails: React.FC<WebflowSyncDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const webflowSync = setting?.details as WebflowSync

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: `Modul${webflowSync?.module.length > 1 ? 'e' : ''}`,
                value: webflowSync?.module.map(item => getEnumValue(WebflowSyncModuleEnum, item)).join(', '),
            },
            {
                label: 'Sofort veröffentlichen',
                value: getEnumValue(YesNoMapping, webflowSync?.forcePublish.toString()),
            },
            {
                label: 'Synchronisation starten',
                value: getEnumValue(YesNoMapping, webflowSync?.sync.toString()),
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Settings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {setting && showAddEditModal && <WebflowSyncModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </WebflowSyncModal>
            }
        </>
    );
};

export default WebflowSyncDetails;
