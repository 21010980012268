import { AxiosError } from 'axios';
import { NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import KeywordDetails from '../../components/keywords/detail/KeywordDetails';
import { Keyword, Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { mockKeywords } from './keywords';

function KeywordDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { keywordId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [keyword, setKeyword] = useState<Keyword>();
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Keywords, 'read');

      if (hasPermission) {
        keywordId && fetchKeyword(keywordId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [keywordId, permissionsLoaded]);

  const fetchKeyword = async (keywordId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/keywords/${keywordId}`);
      // const keywordResponse = response.data;
      setKeyword(mockKeywords[0]);
      //setDynamicTitle(keywordResponse.title)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setKeyword(undefined)
    keywordId && fetchKeyword(keywordId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <KeywordDetails keyword={keyword} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></KeywordDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default KeywordDetail;
