import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import SystematicDetails from '../../components/systematics/detail/SystematicDetails';
import { Permissions, Systematic } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';

function SystematicDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { systematicId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [systematic, setSystematic] = useState<Systematic | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, userHasPermission, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Systematics, 'read');

      if (hasPermission) {
        systematicId && fetchSystematic(systematicId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [systematicId, permissionsLoaded]);

  const fetchSystematic = async (systematicId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/systematics/${systematicId}`);
      const systematicResponse = response.data;
      setDynamicTitle(systematicResponse.title)
      setSystematic(systematicResponse);
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setSystematic(null)
    systematicId && fetchSystematic(systematicId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <SystematicDetails systematic={systematic} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></SystematicDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default SystematicDetail;
