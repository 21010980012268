import { useEffect } from 'react';
/**
 * Custom hook that dynamically sets the height of a scrollable table container
 * based on the current viewport height and the height of surrounding UI elements.
 *
 * This ensures that the table fills the remaining vertical space on the screen
 * without overflowing below the viewport, and adapts automatically on window resize.
 *
 * Targeted elements (if present):
 * - `.navbar`
 * - `.table-controls-wrapper`
 * - `.table-controls-wrapper-2`
 * - `.pagination-wrapper`
 * - `.table-responsive-md` (receives the computed height)
 */
const useTableHeight = () => {
    useEffect(() => {
        const updateTableHeight = () => {
            const screenHeight = window.innerHeight;
            const tableControlsWrapper = document.querySelector('.table-controls-wrapper');
            const tableControlsWrapper2 = document.querySelector('.table-controls-wrapper-2');
            const paginationWrapper = document.querySelector('.pagination-wrapper');
            const navBar = document.querySelector('.navbar');
            const extraPadding = 85; // Additional spacing to account for margins, etc.
            const tableControlsWrapperHeight = (tableControlsWrapper === null || tableControlsWrapper === void 0 ? void 0 : tableControlsWrapper.offsetHeight) || 0;
            const tableControlsWrapper2Height = (tableControlsWrapper2 === null || tableControlsWrapper2 === void 0 ? void 0 : tableControlsWrapper2.offsetHeight) || 0;
            const paginationWrapperHeight = (paginationWrapper === null || paginationWrapper === void 0 ? void 0 : paginationWrapper.offsetHeight) || 0;
            const navBarHeight = (navBar === null || navBar === void 0 ? void 0 : navBar.offsetHeight) || 0;
            // Calculate available height for the table
            const availableHeight = screenHeight -
                (tableControlsWrapperHeight +
                    tableControlsWrapper2Height +
                    paginationWrapperHeight +
                    navBarHeight +
                    extraPadding);
            // Apply the height to the scrollable table container
            const tableContainer = document.querySelector('.table-responsive-md');
            if (tableContainer) {
                tableContainer.style.height = `${availableHeight}px`;
            }
        };
        // Initial calculation + resize listener
        window.addEventListener('resize', updateTableHeight);
        updateTableHeight();
        // Clean up on unmount
        return () => window.removeEventListener('resize', updateTableHeight);
    }, []);
};
export { useTableHeight };
