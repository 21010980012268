import React from 'react';

interface Breadcrumb {
  title: string;
}

interface TableNoDataMessageProps {
  message: string;
  breadcrumbs?: Breadcrumb[];
  colSpan?: number;
}

/**
 * TableNoDataMessage Component
 *
 * This component displays a centered "No Data" message in a table when no records are found.
 * It spans across all columns dynamically using `colSpan=100%`, making it reusable for various tables.
 *
 * Features:
 * - Dynamically spans all available table columns.
 * - Displays a custom message provided via props.
 * - Supports breadcrumbs to dynamically show the last navigation level.
 * - Styled with a border and centered alignment for better visibility.
 *
 * Props:
 * @param {string} message - The base message to display when no data is available.
 * @param {Array<{ title: string }>} breadcrumbs - Array of breadcrumb objects to show the last level.
 * @param {number} [colSpan=100] - Optional column span (default: 100%).
 *
 * Usage Example:
 * ```tsx
 * {!isLoading && sortedMedia.length === 0 && (
 *   <TableNoDataMessage 
 *     message="Keine Medien in Ordner" 
 *     breadcrumbs={breadcrumbs} 
 *   />
 * )}
 * ```
 */

const TableNoDataMessage: React.FC<TableNoDataMessageProps> = ({ message, breadcrumbs = [], colSpan = 100 }) => {
  // Get the last breadcrumb title or fallback to an empty string
  const lastBreadcrumbTitle = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1]?.title : '';

  return (
    <tr>
      <td colSpan={colSpan} className="text-center">
        <div
          className="d-flex justify-content-center align-items-center border rounded my-3"
          style={{ height: '50px' }}
        >
          <p className="p-0 m-0">
            {message} <b>{lastBreadcrumbTitle}</b> gefunden
          </p>
        </div>
      </td>
    </tr>
  );
};

export default TableNoDataMessage;
