import { CompanyEnum, Role, User, usePermissions } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Permissions } from '../../../interfaces';
import {
    ActiveInactiveMapping,
    PermissionsEnum,
    getEnumValue,
    userSalutationOptions
} from '../../../utils/enum';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import GenericInfosCard from '../../products/GenericInfosCard';
import AddEditUsersModal from '../modal/AddEditUsersModal';

interface UserDetailsProps {
    isLoading?: boolean;
    user?: User | null;
    userRoles?: Role[]
    onSubmitSuccess: (certificateId: number) => void;
}

const UserDetails: React.FC<UserDetailsProps> = ({
    isLoading,
    user,
    onSubmitSuccess,
    userRoles
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions<Permissions>();

    function getInfo1() {
        const info1 = [

            {
                label: 'Status',
                value: getEnumValue(ActiveInactiveMapping, user?.status.toString()),
            },
            {
                label: 'Benutzergruppe',
                value: user?.roles?.title || '-',
            },
            {
                label: 'Unternehmen',
                value: user?.company ? ((user.company) as (keyof typeof CompanyEnum)[]).map(companyKey => CompanyEnum[companyKey]).join(', ') : '',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'E-Mail-Adresse',
                value: user?.email || '-',
            },
        ];
        return info2;
    }

    function getInfo3() {
        const info3 = [
            {
                label: 'Anrede',
                value: userSalutationOptions[user?.salutation as keyof typeof userSalutationOptions] || '-',
            },
            {
                label: 'Vorname',
                value: user?.firstname || '-',
            },
            {
                label: 'Zweiter Vorname',
                value: user?.secondName || '-',
            },
            {
                label: 'Nachname',
                value: user?.lastname || '-',
            },
        ];
        console.log(user);
        return info3;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{user?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.Users, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={4}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Person"
                            infos={getInfo3()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={4}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={4}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Anmeldedaten"
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {user && userRoles && showAddEditModal && <AddEditUsersModal
                modalTitle={`${user.title} bearbeiten`}
                user={user}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
                userRoles={userRoles}
            >
            </AddEditUsersModal>
            }
        </>
    );
};

export default UserDetails;
