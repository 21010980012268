import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Role, useDocumentTitle, usePermissions, User, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import UserDetails from '../../components/users/detail/UserDetails';
import { Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';

function UserDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { userId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [user, setUser] = useState<User>();
  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();


  const fetchUserRoles = async () => {
    const res = await ApiClient.get('/roles')
    const userRoles = res.data
    setUserRoles(userRoles.list ?? []);
  }

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Users, 'read');

      if (hasPermission) {
        userId && fetchUser(userId);
        fetchUserRoles()
      } else {
        navigate('/errors/error404');
      }
    }
  }, [userId, permissionsLoaded]);

  const fetchUser = async (userId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/users/${userId}`);
      const userResponse = response.data;
      setUser(userResponse);
      setDynamicTitle(userResponse.title)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setUser(undefined)
    userId && fetchUser(userId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <UserDetails userRoles={userRoles} user={user} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></UserDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default UserDetail;
