import { useEffect } from 'react';
/**
 * Custom hook to dynamically set the favicon based on the current company ID.
 *
 * It reads the `companyId` from sessionStorage (defaults to 'oc' if not set)
 * and updates or creates the <link rel="icon"> element in the document head
 * to point to the corresponding favicon in `/favicons/{companyId}.png`.
 *
 * Useful for customizing branding in multi-tenant applications.
 */
const useFavicon = () => {
    useEffect(() => {
        const companyId = sessionStorage.getItem('companyId');
        const faviconUrl = `/favicons/${companyId}.png`;
        // Try to find an existing favicon <link> tag
        const link = document.querySelector("link[rel~='icon']");
        if (link) {
            // Update the existing favicon link
            link.href = faviconUrl;
        }
        else {
            // Create a new favicon link if one doesn't exist
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.type = 'image/png';
            newLink.href = faviconUrl;
            document.head.appendChild(newLink);
        }
    }, []);
};
export { useFavicon };
