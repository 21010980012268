import { faFile, faFileContract, faHouse, faMoneyBill, faMoneyCheckDollarPen, faPlus, } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, CollapsibleCard, FieldConfig, MediaItem, NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, { ComboButton, ComboButtonId } from '../../components/ComboButtonGroup';
import ActivitiesList from '../../components/locations/detail/ActivitiesList';
import CertificateDetails from '../../components/locations/detail/CertificateDetails.tsx';
import LocationContract from '../../components/locations/detail/LocationContract';
import LocationCosts from '../../components/locations/detail/LocationCosts';
import LocationDetails from '../../components/locations/detail/LocationDetails';
import LocationDetailsHeader from '../../components/locations/detail/LocationDetailsHeader';
import LocationOverview from '../../components/locations/detail/LocationOverview';
import LocationPayment from '../../components/locations/detail/LocationPayment';
import AddActivityModal from '../../components/locations/modal/AddActivityModal';
import CertificateSelectorModal from '../../components/locations/modal/CertificateSelectorModal';
import EmbeddedMedia from '../../components/media/EmbeddedMedia';
import MediaPickerMultiModal from '../../components/media/modal/MediaPickerMultiModal';
import MediaDetails from '../../components/MediaDetails';
import { AccountId, Location, Partner, PaymentMethods, Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { fetchAndCombineFieldConfigs } from '../../utils/utils';

function LocationDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { locationId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [location, setLocation] = useState<Location>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ComboButtonId | ''>('location_overview');
  const [accounts, setAccounts] = useState<AccountId>();
  const [partner, setPartner] = useState<Partner>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods>();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [showContractMediaUploadModal, setShowContractMediaUploadModal] = useState(false);
  const [showImageMediaUploadModal, setShowImageMediaUploadModal] = useState(false);
  const [showCertificateSelectModal, setShowCertificateSelectModal] = useState(false);
  const [showAddNoteModal, setShowAddNoteModal] = useState<number | null>();
  const [refreshKey, setRefreshKey] = useState(0);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();
  const companyId = sessionStorage.getItem('companyId') || 'oc';

  const canViewImages = userHasPermissionByRight(PermissionsEnum.LocationsImages, 'read');
  const canViewDocuments = userHasPermissionByRight(PermissionsEnum.LocationsDocuments, 'read');
  const canViewCertificates = userHasPermissionByRight(PermissionsEnum.LocationsCertificates, 'read');
  const canViewParticipants = userHasPermissionByRight(PermissionsEnum.LocationsParticipants, 'read');
  const canViewPayments = userHasPermissionByRight(PermissionsEnum.LocationsPayment, 'read');

  async function handleFieldUpdate(updates: Partial<Location>) {
    try {
      await ApiClient.put(`/locations/${locationId}`, updates);
      locationId && fetchLocation(locationId);
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  }

  const fetchAccounts = async () => {
    const res = await ApiClient.get('/settings?ident=meinNowAccounts')
    setAccounts(res.data.list[0].details || {})
  }

  const fetchPartner = async () => {
    const res = await ApiClient.get(`/settings?ident=locationsPartners`)
    setPartner(res.data.list[0].details || {})
  }

  const fetchPaymentMethods = async () => {
    const res = await ApiClient.get(`/settings?ident=paymentMethods`)
    setPaymentMethods(res.data.list[0].details || {})
  }

  const comboButtons: ComboButton[] = useMemo(() => {
    return [
      { id: 'location_overview', label: 'Übersicht', icon: faHouse },
      { id: 'location_contract', label: 'Vertragliches', icon: faFileContract },
      ...(canViewPayments ? [{ id: 'location_payment', label: 'Zahlung', icon: faMoneyCheckDollarPen }] : []),
      { id: 'location_costs', label: 'Kosten', icon: faMoneyBill },
      ...(canViewDocuments ? [{ id: 'location_documents', label: 'Unterlagen', icon: faFile }] : []),
      { id: 'location_notes', label: 'Notizen', icon: faFile },
    ];
  }, [location, canViewPayments, canViewDocuments]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Locations, 'read');

      if (hasPermission) {
        locationId && fetchLocation(locationId);
        // Fetch only once
        if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
          fetchAndCombineFieldConfigs(['locations', 'activities'], setFieldConfigs);
        }
        fetchAccounts()
        fetchPartner()
        fetchPaymentMethods()
      } else {
        navigate('/errors/error404');
      }
    }
  }, [locationId, permissionsLoaded]);

  const fetchLocation = async (locationId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/locations/${locationId}`);
      const locationResponse = response.data;
      setLocation(locationResponse);
      setDynamicTitle(locationResponse.title)
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setLocation(undefined)
    locationId && fetchLocation(locationId);
  };

  const handleActivitiesUpdate = (message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleMediaAction = async (
    mediaType: keyof Pick<Location, 'contractMedia' | 'imageMedia'>,
    mediaId: number,
    action: 'unlink' | 'delete'
  ) => {
    if (action === 'unlink') {
      if (location && location[mediaType]) {
        const updatedMedia = location[mediaType].filter((media: MediaItem) => media.id !== mediaId);
        handleFieldUpdate({ [mediaType]: updatedMedia });
      }
    } else if (action === 'delete') {
      try {
        await ApiClient.delete(`/media/${mediaId}`);
        locationId && fetchLocation(locationId);
        showToast('Erfolgreich gelöscht', false);
      } catch (error: any) {
        showToast('Fehler beim Löschen', true);
      }
    }
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col >
          <LocationDetailsHeader
            location={location}
            isLoading={isLoading}
            onSubmitSuccess={handleModalSubmit}
            fieldConfigs={fieldConfigs}
            accounts={accounts}
            onAccountsUpdate={fetchAccounts}
            onPartnerUpdate={fetchPartner}
            partner={partner}
            paymentMethods={paymentMethods}
            onPaymentMethodsUpdate={fetchPaymentMethods}
            handleCertificationStatusUpdate={(statusCertification) => handleFieldUpdate({ statusCertification })}>
          </LocationDetailsHeader>
        </Col>
      </Row>

      <Row>
        <Col lg={9}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedTab}
                    setSelectedCombo={setSelectedTab}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>

              {/* Location Overview Content */}
              {selectedTab === 'location_overview' &&
                <>
                  <CollapsibleCard
                    title="Übersicht"
                    initialOpen
                  >
                    <div className='p-3'>
                      <LocationOverview location={location} fieldConfigs={fieldConfigs} accounts={accounts}></LocationOverview>
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Angepinnte Notizen"
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setSelectedTab('location_notes')} className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {location && <ActivitiesList fieldConfigs={fieldConfigs} elementId={location?.id} elementClass='App\Models\LocationsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} isPinnedSection />}
                    </div>
                  </CollapsibleCard>
                </>
              }

              {/* Location Contract Content */}
              {selectedTab === 'location_contract' &&
                <>
                  <CollapsibleCard
                    title="Vertragliches"
                    initialOpen
                  >
                    <div className='p-3'>
                      <LocationContract location={location} fieldConfigs={fieldConfigs}></LocationContract>
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Notizen"
                    titleInfo='Vertragliches'
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setShowAddNoteModal(1)} variant='primary'>
                        Notiz Hinzufügen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {location && <ActivitiesList type={1} fieldConfigs={fieldConfigs} elementId={location?.id} elementClass='App\Models\LocationsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>
                </>
              }

              {/* Location Payment Content */}
              {selectedTab === 'location_payment' &&
                <>
                  <CollapsibleCard
                    title="Zahlung"
                    initialOpen
                  >
                    <div className='p-3'>
                      <LocationPayment paymentMethods={paymentMethods} location={location} fieldConfigs={fieldConfigs}></LocationPayment>
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Notizen"
                    titleInfo='Zahlung'
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setShowAddNoteModal(2)} variant='primary'>
                        Notiz Hinzufügen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {location && <ActivitiesList type={2} fieldConfigs={fieldConfigs} elementId={location?.id} elementClass='App\Models\LocationsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>
                </>
              }

              {/* Location Costs Content */}
              {selectedTab === 'location_costs' &&
                <>
                  <CollapsibleCard
                    title="Kosten"
                    initialOpen
                  >
                    <div className='p-3'>
                      <LocationCosts location={location} fieldConfigs={fieldConfigs}></LocationCosts>
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Notizen"
                    titleInfo='Kosten'
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setShowAddNoteModal(3)} variant='primary'>
                        Notiz Hinzufügen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {location && <ActivitiesList type={3} fieldConfigs={fieldConfigs} elementId={location?.id} elementClass='App\Models\LocationsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>
                </>
              }

              {/* Location Costs Content */}
              {selectedTab === 'location_documents' &&
                <>
                  <div className='mb-5 pb-5'><EmbeddedMedia initialFolderId={location?.folder_id ?? 0} /></div>

                  <CollapsibleCard
                    title="Notizen"
                    titleInfo='Unterlagen'
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setShowAddNoteModal(4)} variant='primary'>
                        Notiz Hinzufügen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {location && <ActivitiesList type={4} fieldConfigs={fieldConfigs} elementId={location?.id} elementClass='App\Models\LocationsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>
                </>
              }

              {/* Location Activities Content */}
              {selectedTab === 'location_notes' &&
                <>
                  <CollapsibleCard
                    title="Angepinnte Notizen"
                    initialOpen
                  >
                    <div className='p-3'>
                      {location && <ActivitiesList fieldConfigs={fieldConfigs} elementId={location?.id} elementClass='App\Models\LocationsModel' onActivitiesUpdate={handleActivitiesUpdate} isPinnedSection refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Notizen"
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setShowAddNoteModal(0)} variant='primary'>
                        Notiz Hinzufügen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {location && <ActivitiesList fieldConfigs={fieldConfigs} elementId={location?.id} elementClass='App\Models\LocationsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>
                </>
              }
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          {/* Collapsible Card Location Details */}
          <CollapsibleCard
            title="Details"
            showDivider
            initialOpen
          >
            <div className='p-3'>
              <LocationDetails location={location} partner={partner} fieldConfigs={fieldConfigs} />
            </div>

          </CollapsibleCard>

          {/* Collapsible Card Deals */}
          {canViewParticipants && <CollapsibleCard
            title="Teilnehmer"
          >
            {/* <ListGroup className="p-3">
              {['N/A'].map((participant) => (
                <ListGroup.Item
                  key={participant}
                  className="d-flex align-items-center mb-3 border rounded cursor-pointer"
                >
                  <div className='me-2'>
                    <ParticipantInitialsComponent size={40} initials='MM' smileyLevel={0}></ParticipantInitialsComponent>
                  </div>

                  <div className='p-1'>
                    <div className="mb-1">Max Mustermann (TBD)</div>
                    <div className='text-muted d-flex align-items-center'>
                      <FontAwesomeIcon width={15} icon={faSpinner} className="me-2 text-warning " />
                      <span className='me-2 text-warning '>Lernend</span> ·
                      <SmileyRating selectedRating={2}></SmileyRating>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup> */}

            <div className='p-3'>
              <div className="d-flex justify-content-center align-items-center border rounded py-3 p-3 text-center">
                <p className="p-0 m-0">Keine Teilnehmer zugewiesen <br></br> (Funktion kommt noch)</p>
              </div>
            </div>

          </CollapsibleCard>}


          {/* Collapsible Card Certificate */}
          {canViewCertificates && (
            <CollapsibleCard title="Zertifikat"
              headerRightElement={!location?.certificate &&
                <Button variant="link" onClick={() => setShowCertificateSelectModal(true)}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              }>
              <div className='p-3'>
                {location && (
                  <CertificateDetails
                    handleDelete={() => handleFieldUpdate({ certificate_id: null })}
                    location={location}
                    companyId={companyId}
                  />
                )}
              </div>
            </CollapsibleCard>
          )}


          {/* Collapsible Card Documents */}
          {canViewDocuments && <CollapsibleCard
            title="Dokumente"
            titleInfo={`(${location?.contractMedia.length})`}
            headerRightElement={
              <Button variant="link" onClick={() => setShowContractMediaUploadModal(true)}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
          >
            <div className='p-3'>
              <MediaDetails
                handleDelete={(mediaId: number, action: 'unlink' | 'delete') =>
                  handleMediaAction('contractMedia', mediaId, action)
                }
                mediaItems={location?.contractMedia}
                title={`Dokumente: ${location?.street} ${location?.streetNr} · ${location?.city}`}>

              </MediaDetails>
            </div>

          </CollapsibleCard>}


          {/* Collapsible Card Images */}
          {canViewImages && <CollapsibleCard
            title="Bilder"
            titleInfo={`(${location?.imageMedia.length})`}
            headerRightElement={
              <Button variant="link" onClick={() => setShowImageMediaUploadModal(true)}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
          >
            <div className='p-3'>
              <MediaDetails
                handleDelete={(mediaId: number, action: 'unlink' | 'delete') =>
                  handleMediaAction('imageMedia', mediaId, action)
                }
                mediaItems={location?.imageMedia}
                title={`Bilder: ${location?.street} ${location?.streetNr} · ${location?.city}`}>
              </MediaDetails>
            </div>
          </CollapsibleCard>}

        </Col>
      </Row>

      {showCertificateSelectModal &&
        <CertificateSelectorModal
          location={location}
          onClose={() => setShowCertificateSelectModal(false)}
          onSelectCertificate={(certificate_id) => handleFieldUpdate({ certificate_id })}
        />
      }

      {showContractMediaUploadModal && (
        <MediaPickerMultiModal
          onSelectMedia={(contractMedia) => handleFieldUpdate({ contractMedia })}
          initialMedia={location?.contractMedia}
          initialFolderId={location?.folder_id ?? 1}
          allowedMimeTypesUpload={['application/pdf', 'application/x-pdf']}
          allowedMimeTypesSelection={['application/pdf', 'application/x-pdf', 'inode/directory']}
          convention="locations-contract"
          onModalClose={() => {
            setShowContractMediaUploadModal(false);
          }}
        />
      )}

      {showImageMediaUploadModal && (
        <MediaPickerMultiModal
          onSelectMedia={(imageMedia) => handleFieldUpdate({ imageMedia })}
          initialMedia={location?.imageMedia}
          initialFolderId={location?.folder_id ?? 1}
          allowedMimeTypesUpload={['image/heic', 'image/heif', 'image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg+xml']}
          allowedMimeTypesSelection={['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg+xml', 'inode/directory']}
          convention="locations-image"
          onModalClose={() => {
            setShowImageMediaUploadModal(false);
          }}
        />
      )}

      {showAddNoteModal?.toString() && location && <AddActivityModal platform='dataoffice' type={showAddNoteModal} elementId={location?.id} elementClass='App\Models\LocationsModel' onSubmitSuccess={handleActivitiesUpdate} onModalClose={() => setShowAddNoteModal(null)}></AddActivityModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default LocationDetail;
