import { memo, } from 'react';

const ExportImport = memo(() => {
  return (
    <div className='container-fluid p-40'>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3>Export & Import</h3>
        </div>
      </div>
    </div>
  );
});

ExportImport.displayName = 'Export & Import';
export default ExportImport;
