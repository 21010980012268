import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Setting, useToast } from 'c1g-ui-library';
import React, { FormEvent, useRef, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    Modal,
    Row,
    Spinner,
} from 'react-bootstrap';
import { GlobalDataLocation } from '../../../interfaces';
import { handleInputChange } from '../../../utils/form/utils';
import Card from '../../bootstrap/card';

type EditGlobalDataLocationsModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    telephone: string;
    fax: string;
    mobile: string;
    email: string;
}

const EditGlobalDataLocationsModal: React.FC<EditGlobalDataLocationsModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose
}) => {
    const globalDataLocation = setting?.details as GlobalDataLocation

    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        telephone: globalDataLocation?.telephone ?? '',
        fax: globalDataLocation?.fax ?? '',
        mobile: globalDataLocation?.mobile ?? '',
        email: globalDataLocation?.email ?? '',
    });
    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error: any) {
                console.error((error as AxiosError).message);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: {
                telephone: formValues.telephone,
                fax: formValues.fax,
                mobile: formValues.mobile,
                email: formValues.email,
            }
        };
    }

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        disabled = false
    ) => (
        <Form.Group className="mb-3 w-100" controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}

            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={5}
                    type={type}
                    value={formValues[id]}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={type !== 'file' ? formValues[id] : ''}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>
                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('title', 'Titel', 'text', true, true)}
                                            {createFormGroup('description', 'Beschreibung', 'text', false)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('telephone', 'Festnetz (Vorwahl + Rufnummer)', 'text', true)}
                                            {createFormGroup('fax', 'Fax (Vorwahl + Rufnummer)', 'text', true)}
                                            {createFormGroup('mobile', 'Mobil / Handy', 'text', true)}
                                            {createFormGroup('email', 'E-Mail Adresse', 'text', true)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default EditGlobalDataLocationsModal;
