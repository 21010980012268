import { ApiClient, FieldConfig, NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import CertificationStatusDropdown from '../../components/CertificationStatusDropdown';
import ComboButtonGroup from '../../components/ComboButtonGroup';
import { statusCertificationProductsColorMap, statusCertificationProductsIconMap } from '../../components/filter/iconAndColorMappings';
import SkeletonCard from '../../components/locations/skeleton/SkeletonCard';
import CertificateDetails from '../../components/products/detail/CertificateDetails';
import CertificationDetails from '../../components/products/detail/CertificationDetails';
import EducationalContents from '../../components/products/detail/EducationalContents';
import KursnetDetails from '../../components/products/detail/KursnetDetails';
import MeasuresConceptDetails from '../../components/products/detail/MeasuresConceptDetails';
import GenericInfosCard from '../../components/products/GenericInfosCard';
import AddEditProductCertificationModal from '../../components/products/modal/AddEditProductCertificationModal';
import AddEditProductPublicationModal from '../../components/products/modal/AddEditProductPublicationModal';
import PublicationStatusDropdown from '../../components/PublicationStatusDropdown';
import { Permissions, Product } from '../../interfaces';
import {
  ExecutionForm,
  PermissionsEnum,
  ProductTypeEnum,
  PublicationStatus,
  getEnumValue,
  moduleTranslations,
} from '../../utils/enum';
import { fetchAndCombineFieldConfigs, getFieldConfigByResourceName } from '../../utils/utils';

const comboButtonsCertification = [
  { id: 'educational_contents', label: 'Bildungsinhalte' },
  { id: 'certification', label: 'Zertifizierung' },
  { id: 'measures_concept', label: 'Maßnahmenkonzept' },
  { id: 'certificate', label: 'Zertifikat' },
];

const comboButtonsPublication = [
  { id: 'kursnet', label: 'meinNow / NewPlan' },
];

function ProductDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'DataOffice', moduleTranslations: moduleTranslations });
  const { productId } = useParams();
  const [product, setProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();
  const [selectedComboCertification, setSelectedComboCertification] = useState(
    'educational_contents'
  );
  const [selectedComboPublication, setSelectedComboPublication] =
    useState('kursnet');

  const { show, message, error, showToast, hideToast } = useToast();
  const [showXmlFieldNames, setShowXmlFieldNames] = useState(false);
  const [showAddEditPublicationModal, setShowAddEditPublicationModal] = useState(false);
  const [showAddEditCertificationModal, setShowAddEditCertificationModal] = useState(false);
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);

  const handleToggleXmlFieldNames = () => setShowXmlFieldNames(!showXmlFieldNames);

  async function handleCertificationStatusUpdate(
    certificationStatus: number
  ) {
    try {
      await ApiClient.put(`/products/${productId}`, {
        statusCertification: certificationStatus,
      });
      productId && (await fetchProduct(productId));
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  }

  async function handlePublicationStatusUpdate(
    publicationStatus: PublicationStatus
  ) {
    try {
      await ApiClient.put(`/products/${productId}`, {
        status: publicationStatus,
      });
      productId && (await fetchProduct(productId));
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  }

  const handleDownloadClick = (checkboxStates: {
    measuresList: boolean;
    measuresConcept: boolean;
  }) => {
    console.log('Checkbox states:', checkboxStates);
  };

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Products, 'read');

      if (hasPermission) {
        productId && fetchProduct(productId);

        if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
          fetchAndCombineFieldConfigs(['products'], setFieldConfigs);
        }

      } else {
        navigate('/errors/error404');
      }
    }
  }, [productId, permissionsLoaded]);

  const fetchProduct = async (productId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/products/${productId}`);
      const productResponse = response.data;
      setProduct(productResponse);
      setDynamicTitle(productResponse.title)
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setProduct(undefined);
    productId && fetchProduct(productId);
  };

  function getProductInfo() {
    const generalInfo = [
      { label: 'Produktart:', value: getEnumValue(ProductTypeEnum, product?.type) },
      { label: 'Pid-Ident:', value: product?.pidIdent || '-' },
      {
        label: 'Zertifizierungssystematik:',
        value: `${product?.bdks ? (product?.bdks?.bdksId + ' | ' + product?.bdks?.title) : '-'}`
      },
      {
        label: 'Maßnahmen-ID',
        value: product?.measureCode || '-',
      },
      { label: 'Keywords von:', value: '-' },
      {
        label: 'Gruppe',
        value: product?.sortGroup || '-',
      },
      {
        label: 'Unterricht',
        value: product?.lessonUnits ? `${product.lessonUnits} UE` : '-',
      },
      {
        label: 'Arbeitszeitmodell',
        value: getEnumValue(ExecutionForm, product?.executionForm),
      },
    ];
    return generalInfo;
  }

  const getProductMainImage = () => {
    return 'https://storage.onecareer.de/placeholder-products.webp'
  }

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={3}>
          {isLoading ? (
            <SkeletonCard sectionCount={7} imageFirst includeImage />
          ) : (
            <GenericInfosCard
              imageSrc={getProductMainImage()}
              title={product?.title || '-'}
              infos={getProductInfo()}
              showHorizontalLine
              cardHeight={false}
              firstColumnSize={5}
            ></GenericInfosCard>
          )}
        </Col>
        <Col lg={9}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <h4>Zertifizierung</h4>
                  <p>Maßnahmenliste und Maßnahmenkonzept</p>
                </div>

                {product && (
                  <div className="d-flex">
                    <CertificationStatusDropdown
                      statusCertification={product?.statusCertification!}
                      handleCertificationStatusUpdate={
                        handleCertificationStatusUpdate
                      }
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')}
                      className='bg-grey'
                      statusEnum={getFieldConfigByResourceName(fieldConfigs, 'products.statusCertification')?.options ?? {}}
                      iconMap={statusCertificationProductsIconMap}
                      colorMap={statusCertificationProductsColorMap}
                    />

                    <Button disabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')} variant="primary" onClick={() => setShowAddEditCertificationModal(true)}>
                      Bearbeiten
                    </Button>
                  </div>
                )}
              </div>

              <div className="horizontal-line mb-3"></div>

              <ComboButtonGroup
                buttons={comboButtonsCertification}
                selectedCombo={selectedComboCertification}
                setSelectedCombo={setSelectedComboCertification}
                borderRadius="normal"
              ></ComboButtonGroup>

              {selectedComboCertification === 'certification' && (
                <CertificationDetails
                  product={product}
                  isLoading={isLoading}
                ></CertificationDetails>
              )}
              {selectedComboCertification === 'educational_contents' && (
                <EducationalContents
                  product={product}
                  isLoading={isLoading}
                ></EducationalContents>
              )}
              {selectedComboCertification === 'measures_concept' && (
                <MeasuresConceptDetails
                  isLoading={isLoading}
                ></MeasuresConceptDetails>
              )}
              {selectedComboCertification === 'certificate' && (
                <CertificateDetails isLoading={isLoading} product={product}></CertificateDetails>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <h4>Veröffentlichung</h4>
                  <p>meinNow / NewPlan</p>
                </div>

                {product && (
                  <div className="d-flex">
                    <PublicationStatusDropdown
                      status={product?.status!}
                      handlePublicationStatusUpdate={
                        handlePublicationStatusUpdate
                      }
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')}
                      style={{ backgroundColor: '#F9F9F9' }}
                    />

                    <Button disabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')} variant="primary" onClick={() => setShowAddEditPublicationModal(true)}>
                      Bearbeiten
                    </Button>
                  </div>
                )}
              </div>

              <div className="horizontal-line mb-3"></div>

              <div className='d-flex justify-content-between'>
                <div>
                  <ComboButtonGroup
                    buttons={comboButtonsPublication}
                    selectedCombo={selectedComboPublication}
                    setSelectedCombo={setSelectedComboPublication}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </div>
                <div>
                  <Form.Switch
                    id="show-xml-names-switch"
                    label="XML-Feldnamen"
                    checked={showXmlFieldNames}
                    onChange={handleToggleXmlFieldNames}
                  />
                </div>
              </div>

              {selectedComboPublication === 'kursnet' && (
                <KursnetDetails
                  product={product}
                  isLoading={isLoading}
                  showXmlFieldNames={showXmlFieldNames}
                ></KursnetDetails>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showAddEditCertificationModal && <AddEditProductCertificationModal
        modalTitle="Zertifizierung bearbeiten"
        product={product}
        onSubmitSuccess={handleModalSubmit}
        onModalClose={() => setShowAddEditCertificationModal(false)}
      ></AddEditProductCertificationModal>}

      {showAddEditPublicationModal && <AddEditProductPublicationModal
        modalTitle="Veröffentlichung bearbeiten"
        product={product}
        onSubmitSuccess={handleModalSubmit}
        onModalClose={() => setShowAddEditPublicationModal(false)}
      ></AddEditProductPublicationModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default ProductDetail;
