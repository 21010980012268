import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';
/**
 * CollapsibleCard Component
 *
 * This component renders a card with a header that can be clicked to expand or collapse the content.
 * The initial state of the card (whether it's open or closed) can be controlled via the `initialOpen` prop.
 * It also supports disabling interaction via the `disabled` prop, which will make the title and button inactive.
 *
 * Props:
 * - `title` (string): The title displayed on the card header.
 * - `headerRightElement` (ReactNode): Optional element rendered on the right side of the header (e.g., a button or icon).
 * - `children` (ReactNode): The content to be displayed inside the collapsible area.
 * - `titleInfo` (number | string): Optional additional information displayed next to the title.
 * - `subTitle` (ReactNode | string): Optional subtitle displayed below the title, useful for supplementary information.
 * - `showDivider` (boolean): Adds a horizontal divider inside the card when the content is shown.
 * - `initialOpen` (boolean): Controls whether the card should start in an open state (default: false).
 * - `disabled` (boolean): Disables the card toggle functionality and applies disabled styling (default: false).
 *
 * Example Usage:
 *
 * <CollapsibleCard
 *   title="Deals"
 *   titleInfo={5}
 *   subTitle="Active deals for the month"
 *   initialOpen={true}
 *   disabled={false}
 * >
 *   <p>This is some collapsible content.</p>
 * </CollapsibleCard>
 */
const CollapsibleCard = ({ title, titleInfo, subTitle, headerRightElement, showDivider, children, initialOpen = false, disabled = false, customCardCssClass, showExpandCollapseIcon = true }) => {
    const [open, setOpen] = useState(initialOpen);
    useEffect(() => {
        if (!disabled) {
            setOpen(initialOpen);
        }
    }, [initialOpen, disabled]);
    const toggleOpen = () => {
        if (!disabled) {
            setOpen(!open);
        }
    };
    return (_jsxs(Card, { className: `${customCardCssClass}`, children: [_jsx(Card.Header, { className: 'p-3', children: _jsxs("div", { className: "d-flex justify-content-between align-items-center", children: [_jsxs("div", { onClick: disabled ? undefined : toggleOpen, className: `d-flex justify-content-center align-items-center gap-3 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`, children: [showExpandCollapseIcon &&
                                    _jsx("div", { className: 'btn-ghost text-primary px-2', children: open ? _jsx(FontAwesomeIcon, { icon: faAngleUp }) : _jsx(FontAwesomeIcon, { icon: faAngleDown }) }), _jsxs("div", { className: 'd-flex flex-column justify-content-center', children: [_jsxs("h4", { className: `mb-0 ${disabled ? 'text-muted' : ''}`, children: [title, " ", (titleInfo === null || titleInfo === void 0 ? void 0 : titleInfo.toString()) && _jsx("span", { className: 'text-muted', children: titleInfo })] }), subTitle && _jsx("p", { className: 'm-0', children: subTitle })] })] }), headerRightElement && (_jsx("div", { children: headerRightElement }))] }) }), _jsx(Collapse, { in: open, children: _jsxs("div", { children: [showDivider && _jsx("div", { className: 'px-3', children: _jsx("div", { className: "horizontal-line" }) }), open && children] }) })] }));
};
export { CollapsibleCard };
