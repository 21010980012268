import { Outlet } from 'react-router-dom';
import Sidebar from '../components/partials/sidebar/sidebar';
import Header from '../components/partials/header/header';

interface DefaultLayoutProps {
  app_name: string
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ app_name }) => {
  return (
    <>
      <Sidebar app_name={app_name} />
      <main className="main-content">
        <div className="position-relative d-lg-none">
          <Header app_name={app_name} />
        </div>
        <div className="content-inner p-0">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default DefaultLayout;
