import React, { useState } from 'react';
import { ListGroup, Dropdown, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faLink, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { MediaItem } from 'c1g-ui-library';
import { formatSizeUnits, getImageUrl } from '../utils/media/utils';
import MediaViewerModal from './MediaViewerModal';
import ConfirmationModal from './ConfirmationModal';

interface MediaDetailsProps {
  mediaItems?: MediaItem[];
  title?: React.ReactNode | string;
  handleDelete: (mediaId: number, action: 'unlink' | 'delete') => void;
  showDropdown?: boolean;
  isGrid?: boolean;
}

/**
 * MediaDetails Component
 * 
 * This component displays a list of media items with options to view each media item in a modal and
 * provides a dropdown menu to delete (or unlink) individual media items. It supports both list and 
 * grid layouts based on the `isGrid` prop.
 * 
 * Props:
 * - `mediaItems`: An optional array of `MediaItem` objects, each representing a media file with properties 
 *   like title, dimensions, and size.
 * - `title`: An optional title to display at the top of the media viewer modal.
 * - `handleDelete`: A callback function to handle the deletion (or removal) of a media item link, taking 
 *   the media item's ID and action type as arguments.
 * - `showDropdown`: A boolean to control the display of the dropdown menu with delete/unlink options. 
 *   Defaults to true.
 * - `isGrid`: A boolean that toggles the layout of media items between a list view and a grid view. When 
 *   set to true, items are displayed in a two-column grid; otherwise, they appear in a single-column list. 
 *   Defaults to false.
 * 
 * Example usage:
 * ```tsx
 * <MediaDetails
 *   mediaItems={mediaArray}
 *   title="Media Gallery"
 *   handleDelete={(id, action) => console.log(id, action)}
 *   showDropdown={true}
 *   isGrid={true}
 * />
 * ```
 */

const MediaDetails: React.FC<MediaDetailsProps> = ({
  mediaItems = [],
  title,
  handleDelete,
  showDropdown = true,
  isGrid = false,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [mediaIdToDelete, setMediaIdToDelete] = useState<number | null>(null);

  const handleShowModal = (index: number) => {
    setCurrentMediaIndex(index);
    setModalShow(true);
  };

  const confirmDelete = (mediaId: number) => {
    setMediaIdToDelete(mediaId);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    if (mediaIdToDelete !== null) {
      handleDelete(mediaIdToDelete, 'delete');
      setMediaIdToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleUnlink = (mediaId: number) => {
    handleDelete(mediaId, 'unlink');
  };

  const handleCloseConfirmationModal = () => {
    setMediaIdToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <>
      <MediaViewerModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        media={mediaItems}
        currentMediaIndex={currentMediaIndex}
        setCurrentMediaIndex={setCurrentMediaIndex}
        title={title}
      />

      {mediaItems.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center border rounded py-3">
          <p className="p-0 m-0">Keine Medien zugewiesen</p>
        </div>
      ) : (
        isGrid ? (
          <Row>
            {mediaItems.map((media, index) => (
              <Col key={media.id} md={6} className="mb-3">
                <div
                  className="d-flex align-items-center p-2 border rounded media-item cursor-pointer"
                  onClick={() => handleShowModal(index)}
                  onMouseLeave={() => setOpenDropdownIndex(null)}
                >
                  <img
                    src={getImageUrl(media)}
                    alt={`Preview of ${media.title}`}
                    className="me-3 rounded"
                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                  />
                  <div className="flex-grow-1 text-truncate">
                    <div className="mb-1 text-truncate text-black" title={media.title}>
                      {media.title}
                    </div>
                    <div className="text-muted">
                      {media.width > 0 && media.height > 0 && (
                        <>{media.width} x {media.height} · </>
                      )}
                      {media.size && formatSizeUnits(media.size)}
                    </div>
                  </div>
                  {showDropdown && <Dropdown
                    align="end"
                    show={openDropdownIndex === index}
                    className="ms-auto"
                  >
                    <Dropdown.Toggle
                      as="span"
                      bsPrefix="btn"
                      className="hover-button no-border"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDropdownIndex(index);
                      }}
                    >
                      <FontAwesomeIcon className="text-primary" icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleUnlink(media.id); }}>
                        <FontAwesomeIcon className="text-primary" width={30} icon={faLink} /> Verlinkung entfernen
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => { e.stopPropagation(); confirmDelete(media.id); }}>
                        <FontAwesomeIcon className="text-danger" width={30} icon={faTrash} /> Medium löschen
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>}
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <ListGroup>
            {mediaItems.map((media, index) => (
              <ListGroup.Item
                key={media.id}
                className="d-flex align-items-center mb-3 p-2 border rounded media-item cursor-pointer"
                onClick={() => handleShowModal(index)}
                onMouseLeave={() => setOpenDropdownIndex(null)}
              >
                <img
                  src={getImageUrl(media)}
                  alt={`Preview of ${media.title}`}
                  className="me-3 rounded"
                  style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                />
                <div className="flex-grow-1 text-truncate">
                  <div className="mb-1 text-truncate" title={media.title}>
                    {media.title}
                  </div>
                  <div className="text-muted">
                    {media.width > 0 && media.height > 0 && (
                      <>{media.width} x {media.height} · </>
                    )}
                    {media.size && formatSizeUnits(media.size)}
                  </div>
                </div>
                {showDropdown && <Dropdown
                  align="end"
                  show={openDropdownIndex === index}
                  className="ms-auto"
                >
                  <Dropdown.Toggle
                    as="span"
                    bsPrefix="btn"
                    className="hover-button no-border"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDropdownIndex(index);
                    }}
                  >
                    <FontAwesomeIcon className="text-primary" icon={faEllipsisV} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleUnlink(media.id); }}>
                      <FontAwesomeIcon className="text-primary" width={30} icon={faLink} /> Verlinkung entfernen
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { e.stopPropagation(); confirmDelete(media.id); }}>
                      <FontAwesomeIcon className="text-danger" width={30} icon={faTrash} /> Medium löschen
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )
      )}

      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={handleConfirmDelete}
        title="Medien löschen?"
        message="Bist du dir sicher, dass du dieses Medium unwiderruflich löschen willst?"
      />
    </>
  );
};

export default MediaDetails;
