import { FieldConfig, usePermissions } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Permissions } from '../../interfaces';
import { PermissionsEnum } from '../../utils/enum';
import ArchiveModal from '../ArchiveModal';
import BulkEditModal from '../BulkEditModal';
import DeleteModal from '../DeleteModal';

interface SelectionActionsProps {
  selectedCount: number;
  selectedProducts: any;
  handleDeSelectAll: () => void;
  handleSelectAll: () => void;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  publicationStatus?: number | null
  amountAllItems: number
  fieldConfigs: FieldConfig[]
  queryParams: string
}

const SelectionActions: React.FC<SelectionActionsProps> = ({ selectedCount,
  selectedProducts,
  handleDeSelectAll,
  onSubmitSuccess,
  handleSelectAll,
  publicationStatus,
  amountAllItems,
  fieldConfigs,
  queryParams }) => {

  const { userHasPermissionByRight } = usePermissions<Permissions>();
  const [isAllSelected, setIsAllSelected] = useState(amountAllItems === selectedCount)

  return (
    <Row
      className="d-flex justify-content-between p-3 mx-1 mb-2 bg-light-blue"
    >
      <Col className="d-flex align-items-center">
        <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
        <Button variant="link" onClick={handleDeSelectAll}>
          Auswahl aufheben
        </Button>
        {
          !isAllSelected && (
            <Button
              variant="link"
              className="ps-0"
              onClick={() => {
                setIsAllSelected(true);
                handleSelectAll();
              }}
            >
              Alle Auswählen ({amountAllItems})
            </Button>
          )
        }

      </Col>
      <Col className="d-flex justify-content-md-end" >
        <BulkEditModal
          modalTitle={`${isAllSelected ? amountAllItems : selectedCount} Produkte bearbeiten`}
          buttonName="Ausgewählte Produkte bearbeiten"
          entityIds={isAllSelected ? [] : Object.keys(selectedProducts)
            .filter(key => selectedProducts[key] === true)
            .map(Number)}
          onSubmitSuccess={onSubmitSuccess}
          isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')}
          entityType='products'
          fieldConfigs={fieldConfigs}
          queryParams={queryParams}
        ></BulkEditModal>

        {Number(publicationStatus) === 3 && <DeleteModal
          modalTitle="Auswahl löschen?"
          buttonName="Löschen"
          entityType='products'
          entityIds={isAllSelected ? [] : Object.keys(selectedProducts)
            .filter(key => selectedProducts[key] === true)
            .map(Number)}
          onSubmit={onSubmitSuccess}
          isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}
          queryParams={queryParams}
        >
        </DeleteModal>}

        {Number(publicationStatus) !== 3 && <ArchiveModal
          modalTitle="Auswahl archivieren?"
          buttonName="Archivieren"
          entityType='products'
          entityIds={isAllSelected ? [] : Object.keys(selectedProducts)
            .filter(key => selectedProducts[key] === true)
            .map(Number)}
          onSubmit={onSubmitSuccess}
          isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}
          queryParams={queryParams}>
        </ArchiveModal>}
      </Col>
    </Row>
  );
};

export default SelectionActions;
