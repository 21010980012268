import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import { ApiClient } from 'c1g-ui-library';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import { handleInputChange } from '../../../utils/form/utils';
import { AxiosError } from 'axios';

type NewFolderModalProps = {
  modalTitle: string;
  buttonName: string;
  onSubmitSuccess: (mediaId: number | null, message?: string, isError?: boolean) => void;
  onFolderModalChange: (open: boolean) => void;
  selectedFolderId?: number | null
  isDropdownItem?: boolean
  isDisabled?: boolean
};

interface FormValues {
  title: string;
  description: string
}

const NewFolderModal: React.FC<NewFolderModalProps> = ({
  modalTitle,
  buttonName,
  onSubmitSuccess,
  selectedFolderId,
  onFolderModalChange,
  isDropdownItem,
  isDisabled
}) => {
  const [showModal, setShowModal] = useState(false);
  const { folderId = 0 } = useParams();
  const handleClose = () => {
    onFolderModalChange(false);
    setShowModal(false);
  };
  
  const handleShow = () => {
    onFolderModalChange(true);
    setShowModal(true);
  };
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(
    {
      description: "",
      title: "",
      mimeType: "inode/directory",
      mediaType: "inode",
    }
  );

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false
  ) => (
    <Form.Group className="mb-3 w-100" controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}

      {type === 'textarea' ? (
        <Form.Control
          as="textarea"
          rows={5}
          type={type}
          value={formValues[id]}
          onChange={(e) => handleInputChange(e, setFormValues)}
          required={required}
          className='text-black bg-grey'
        />
      ) : (
        <Form.Control
          type={type}
          value={type !== 'file' ? formValues[id] : ''}
          onChange={(e) => handleInputChange(e, setFormValues)}
          required={required}
          className='text-black bg-grey'
        />
      )}

      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );

  const handleFolderSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        const res = await ApiClient.post('/media', { ...formValues, folder_id: selectedFolderId?.toString() ? selectedFolderId : folderId })
        onSubmitSuccess(res.data.id, 'Erfolgreich gespeichert', false)
      } catch (error: any) {
        console.error((error as AxiosError).message);
      } finally {
        setIsLoading(false);
        handleClose();
        setValidated(false);
        setFormValues({ description: "", title: "", mimeType: "inode/directory", mediaType: "inode" });
      }
    }
  };

  return (
    <>
      {isDropdownItem ?
        <Dropdown.Item disabled={isDisabled} onClick={handleShow}><FontAwesomeIcon width={30} icon={faFolder} />{buttonName}</Dropdown.Item>
        :
        <Button disabled={isDisabled} variant="btn btn-soft-primary me-4" onClick={handleShow}>
        {buttonName}
      </Button>
      }
      
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Form
          className='text-black'
          noValidate
          validated={validated}
          onSubmit={handleFolderSubmit}
        >
          <Modal.Body>
            <Row>
              <Col>
                {createFormGroup('title', 'Ordner Name', 'text', true)}
                {createFormGroup('description', 'Beschreibung', 'textarea', false)}
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn-soft-primary" variant="secondary" onClick={handleClose}>
              Abbrechen
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={isLoading}
            >
              Erstellen
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NewFolderModal;
