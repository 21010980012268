import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faPen, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical, faEye, } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, MediaItem, MediaItemWithProgress, NotificationToast, usePermissions, useSelection, useSortableData, useToast } from 'c1g-ui-library';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Permissions } from '../../../interfaces';
import { PermissionsEnum } from '../../../utils/enum';
import { formatSizeUnits, getMediaIcon, getMediaStatusIcons, getMediaUrl } from '../../../utils/media/utils';
import DeleteModal from '../../DeleteModal';
import PortalWrapper from '../../PortalWrapper';
import DynamicPagination from '../../table/DynamicPagination';
import PaginationInfo from '../../table/PaginationInfo';
import SkeletonRow from '../../table/skeletonRow/SkeletonRow';
import TableNoDataMessage from '../../table/TableNoDataMessage';
import TemporaryFiles from '../../table/temporaryFileRows';
import Breadcrumbs from '../Breadcrumbs';
import MediaDetails from '../MediaDetails';
import TableWithFileDropzone from '../TableWithFileDropzone';
import MediumEditModal from './MediumEditModal';
import NewFolderModal from './NewFolderModal';
import UploadMediaModal from './UploadMediaModal';

interface MediaResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: MediaItem[];
}

type MediaPickerModalProps = {
  onSelectMedia: (selectedMedia: MediaItem) => void;
  initialMedia?: MediaItem | null
  initialFolderId?: number
  allowedMimeTypesUpload?: string[]
  allowedMimeTypesSelection?: string[]
  onModalClose: () => void;
  convention?: string
  uploadAllowed?: boolean
};

/**
 * MediaPickerModal Component
 *
 * This component provides a modal interface for selecting, managing, 
 * and organizing media files. It supports features like file uploads, 
 * folder navigation, media detail views, and media selection.
 *
 * Props:
 * - `onSelectMedia`: Callback function invoked when a media item is selected.
 * - `initialMedia` (optional): The initially selected media item.
 * - `initialFolderId` (optional): The ID of the folder to display initially.
 * - `allowedMimeTypesUpload` (optional): Array of MIME types allowed for upload.
 * - `allowedMimeTypesSelection` (optional): Array of MIME types allowed for selection.
 * - `onModalClose`: Callback function invoked when the modal is closed.
 * - `convention` (optional): Convention string used during file uploads.
 * - `uploadAllowed` (optional): Flag indicating whether file uploads are permitted. Default is `false`.
 */

const MediaPickerModal: React.FC<MediaPickerModalProps> = ({
  onSelectMedia,
  initialMedia,
  initialFolderId,
  allowedMimeTypesUpload = [],
  allowedMimeTypesSelection = [],
  onModalClose,
  convention,
  uploadAllowed = false
}) => {
  const [dropdownSelectedMedia, setDropdownSelectedMedia] = useState<MediaItem | null>();
  const [showMediumEditModal, setShowMediumEditModal] = useState(false);
  const navigate = useNavigate();
  const [selectedFolderId, setSelectedFolderId] = useState(initialFolderId ?? 0)
  const { show, message, error, showToast, hideToast } = useToast();
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [selectedMediaForDetailView, setSelectedMediaForDetailView] = useState<MediaItem | null>(initialMedia ?? null);
  const [detailViewOpen, setDetailViewOpen] = useState<boolean>(initialMedia ? true : false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const { items: sortedMedia, requestSort } = useSortableData(media);
  const [breadcrumbs, setBreadcrumbs] = useState<MediaItem[]>([]);
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const [temporaryFiles, setTemporaryFiles] = useState<MediaItemWithProgress[]>([]);

  // Handle breadcrumb navigation
  const handleBreadcrumbs = (newBreadcrumbs: MediaItem[]) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  // Function to get the ID of a media item
  const getId = useCallback((media: MediaItem) => media.id, []);

  // Hook to manage the selection of media items
  const {
    selectedItems: selectedMedia,
    setSelectedItems,
  } = useSelection(media, getId);

  // Function to fetch media items from the server
  const fetchMedia = async () => {
    setMedia([]);
    setIsLoading(true);

    let queryParams = `?page=${currentPage}&folder_id=${Number(selectedFolderId) === 0 ? 'IS%20NULL' : selectedFolderId}`;

    if (limit.toString()) {
      queryParams += `&limit=${limit}`;
    }

    allowedMimeTypesSelection.forEach((type: string, index: number) => {
      queryParams += `&mimeType[${index}]=${encodeURIComponent(type)}`;
    });

    try {
      // API call to fetch media items
      const response = await ApiClient.get(`/media${queryParams}`);
      const mediaResponse = response.data as MediaResponse;
      setTotalPages(mediaResponse.amountPages);
      setMedia(mediaResponse.list ?? []);
      setCurrentPage(mediaResponse.page);
      setLimit(mediaResponse.itemsPerPage);
      setTotalEntries(mediaResponse.amountAllItems);
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to fetch media when relevant dependencies change
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Media, 'read');

      if (hasPermission) {
        fetchMedia();
      } else {
        navigate('/errors/error404')
      }
    }
  }, [
    currentPage,
    permissionsLoaded,
    selectedFolderId,
    limit
  ]);

  // Handle the creation of a new folder
  const handleNewFolderSubmit = (mediaId: number | null, message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }

    if (mediaId) {
      setSelectedFolderId(mediaId)
      fetchMedia()
    }
  };

  // Handle media updates (e.g., after editing or deleting)
  const handleMediaUpdateSubmit = (message?: string, isError?: boolean, isDelete?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    if (isDelete) {
      setSelectedMediaForDetailView(null)
    }
    fetchMedia();
  };

  // Check if the media item can be selected based on allowed MIME types
  const extensionAllowed = (media: MediaItem) => {
    if (media.mimeType === 'inode/directory') return true
    return allowedMimeTypesSelection.length === 0 || allowedMimeTypesSelection.includes(media.mimeType)
  }

  // Handle clicks on media items or folders
  const handleMediumOrFolderClick = (media: MediaItem) => {
    if (extensionAllowed(media)) {
      if (media.mediaType === 'inode') {
        // If it's a folder, navigate into it
        setDetailViewOpen(false);
        setSelectedFolderId(media.id);
        setCurrentPage(1);
      } else {
        // If it's a media file, open the detail view
        setSelectedItems(prevSelectedItems => {
          const isSelected = prevSelectedItems[media.id] ?? false;
          const newSelectedItems = isSelected ? {} : { [media.id]: true };

          if (isSelected) {
            setSelectedMediaForDetailView(null);
            setDetailViewOpen(false);
          } else {
            setSelectedMediaForDetailView(media);
            setDetailViewOpen(true);
          }

          return newSelectedItems;
        });
      }
    }
  };

  // Handle the closure of the preview pane
  const handlePreviewCloseClick = () => {
    setDetailViewOpen(false)
    setSelectedMediaForDetailView(null)
  }

  // Handle the selection of a media item for final selection
  const handleSelectMedia = () => {
    if (selectedMediaForDetailView) {
      onSelectMedia(selectedMediaForDetailView)
      onModalClose()
    }
  }

  // Handle file uploads
  const handleFilesChange = async (newFiles: File[]) => {
    if (!newFiles.length) {
      return;
    }

    // Filter files based on allowed MIME types
    const allowedFiles = allowedMimeTypesUpload.length === 0
      ? newFiles
      : newFiles.filter(file => allowedMimeTypesUpload.includes(file.type));

    const disallowedFiles = allowedMimeTypesUpload.length === 0
      ? []
      : newFiles.filter(file => !allowedMimeTypesUpload.includes(file.type));

    if (disallowedFiles.length > 0) {
      showToast(
        `Einige Dateien haben nicht unterstützte Formate und wurden nicht hochgeladen. Erlaubte Formate: ${allowedMimeTypesUpload}.`,
        true
      );
    }

    if (!allowedFiles.length) {
      return;
    }

    // Create temporary file items to show upload progress
    const tempFiles = allowedFiles.map((file, index) => ({
      media_id: `temp-${index}`,
      title: file.name,
      size: file.size,
      mediaType: 'file',
      progress: 0,
    }));

    setTemporaryFiles(tempFiles as any);

    try {
      // Upload files to the server
      for (let index = 0; index < allowedFiles.length; index++) {
        const file = allowedFiles[index];
        const formData = new FormData();
        formData.append('file', file);

        if (convention) {
          formData.append('convention', convention);
        }
        formData.append('folder_id', selectedFolderId?.toString());

        await ApiClient.post('/upload', formData, {
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total ? progressEvent.total : progressEvent.loaded;
            const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
            setTemporaryFiles(prevFiles => {
              const newFiles = [...prevFiles];
              newFiles[index].progress = percentCompleted;
              return newFiles;
            });
          },
        });
      }
      showToast(`Erfolgreich gespeichert`, false);
      await fetchMedia();
    } catch (error: any) {
      console.error((error as AxiosError).message);
    } finally {
      setTemporaryFiles([]);
    }
  };

  return (
    <>
      <Modal
        centered
        fullscreen
        show
        onHide={onModalClose}
        backdrop="static"
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <h3>Medien auswählen</h3>
          <div className="d-flex">

            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button p-0"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>

                {uploadAllowed &&
                  <>
                    <NewFolderModal
                      modalTitle="Neuer Ordner"
                      buttonName="Neuer Ordner"
                      onSubmitSuccess={handleNewFolderSubmit}
                      selectedFolderId={selectedFolderId}
                      onFolderModalChange={setIsTopLevelModalOpen}
                      isDropdownItem
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                    >
                    </NewFolderModal>

                    <UploadMediaModal
                      modalTitle="Hochladen"
                      buttonName="Hochladen"
                      onSubmitSuccess={handleMediaUpdateSubmit}
                      selectedFolderId={selectedFolderId}
                      onUploadMediaModalChange={setIsTopLevelModalOpen}
                      isDropdownItem
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                    ></UploadMediaModal>
                  </>}

                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button variant="btn btn-primary" disabled={!selectedMediaForDetailView} onClick={handleSelectMedia}>
              Auswählen
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Fragment>
            <Row>
              <Col md={(detailViewOpen && selectedMediaForDetailView) ? 9 : 12}>
                <Card className="card-block ">
                  <Card.Body>
                    <Row className="d-flex justify-content-between">
                      <Col md={6}>
                        <Breadcrumbs
                          isMediaPicker={true}
                          onBreadcrumbsClick={(id) => {
                            setSelectedFolderId(id);
                            setCurrentPage(1);
                          }}
                          onBreadcrumbsChange={handleBreadcrumbs}
                          currentFolderId={selectedFolderId}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <TableWithFileDropzone isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write') || !uploadAllowed} onFilesChange={handleFilesChange}>
                  <div style={{ overflowX: 'auto' }}>
                    <Table responsive="md" size="sm">
                      <thead>
                        <tr>
                          <th
                            className="d-flex align-items-center cursor-pointer"
                            scope="col"
                            onClick={() => requestSort('title')}
                          >
                            <div className='d-flex'>
                              <label className="d-flex align-items-center w-100 ps-2">
                                <Form.Check
                                  className="me-3"
                                  type="checkbox"
                                  checked={false}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                />
                              </label>
                              Name
                            </div>
                          </th>
                          <th
                            style={{ cursor: 'pointer', width: '80px' }}
                            scope="col"
                            onClick={() => requestSort('size')}
                          >
                            Dateigröße
                          </th>
                          <th
                            style={{ cursor: 'pointer', width: '80px' }}
                            scope="col"
                          >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {temporaryFiles.length > 0 ? (
                          <TemporaryFiles temporaryFiles={temporaryFiles} />
                        ) : (
                          isLoading
                            ? Array.from({ length: 8 }).map((_, index) => (
                              <SkeletonRow key={index} columnCount={3} />
                            ))
                            : sortedMedia.map((media) => (
                              <tr key={media.id} className={`${detailViewOpen && selectedMediaForDetailView?.id === media.id ? 'bg-light-blue' : 'bg-white'} cursor-pointer`}>
                                <td onClick={() => handleMediumOrFolderClick(media)} >
                                  <div className={`d-flex align-items-center ${!extensionAllowed(media) ? 'text-gray' : 'text-black'}`}>
                                    <div className="d-flex align-items-center">
                                      <label className="d-flex align-items-center w-100 ps-2" onClick={(event) => event.stopPropagation()}>
                                        <Form.Check
                                          className="me-3"
                                          type="checkbox"
                                          checked={selectedMedia[media.id] ?? false}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleMediumOrFolderClick(media)
                                          }}
                                          onChange={() => { }}
                                        />
                                      </label>
                                      {getMediaIcon(media)} {getMediaStatusIcons(media)} {media.title}
                                    </div>
                                  </div>
                                </td>
                                <td className='text-center'>{media.size ? formatSizeUnits(media.size) : '-'}</td>
                                <td>
                                  <Dropdown className="me-2">
                                    <Dropdown.Toggle as="span"
                                      bsPrefix="btn"
                                      className="no-border">
                                      <FontAwesomeIcon icon={faEllipsisVertical} />
                                    </Dropdown.Toggle>
                                    <PortalWrapper>
                                      <Dropdown.Menu>

                                        {media.mediaType !== 'inode' &&
                                          <a href={getMediaUrl(media)} target="_blank" rel="noopener noreferrer">
                                            <Dropdown.Item as="div">
                                              <FontAwesomeIcon width={30} icon={faEye} />
                                              Anzeigen
                                            </Dropdown.Item>
                                          </a>
                                        }

                                        <Dropdown.Item
                                          disabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                                          onClick={() => {
                                            setDropdownSelectedMedia(media);
                                            setShowMediumEditModal(true)
                                            setIsTopLevelModalOpen(true);
                                          }}>
                                          <FontAwesomeIcon width={30} icon={faPen} />Bearbeiten
                                        </Dropdown.Item>

                                        <DeleteModal
                                          modalTitle="Löschen?"
                                          buttonName="Löschen"
                                          entityIds={media.id}
                                          entityType='media'
                                          onSubmit={(message?: string, isError?: boolean) => handleMediaUpdateSubmit(message, isError, true)}
                                          onDeleteModalChange={setIsTopLevelModalOpen}
                                          isDropdownItem={true}>
                                        </DeleteModal>

                                      </Dropdown.Menu>
                                    </PortalWrapper>
                                  </Dropdown>
                                </td>
                              </tr>
                            )))}

                        {!isLoading && sortedMedia.length === 0 && (
                          <TableNoDataMessage
                            message="Keine Medien in Ordner"
                            breadcrumbs={breadcrumbs}
                          />
                        )}

                      </tbody>
                    </Table>

                  </div>
                </TableWithFileDropzone>
                {totalEntries > 0 && (
                  <Row>
                    <Col>
                      <PaginationInfo
                        currentPage={currentPage}
                        limit={limit}
                        totalEntries={totalEntries}
                        onLimitChange={(size) => {
                          setLimit(size);
                          setCurrentPage(1);
                        }}
                      />
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <DynamicPagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Col>
                  </Row>
                )}</Col>
              <Col md={(detailViewOpen && selectedMediaForDetailView) ? 3 : 0}>
                {(detailViewOpen && selectedMediaForDetailView) && <MediaDetails media={selectedMediaForDetailView} onClose={handlePreviewCloseClick}></MediaDetails>}
              </Col>
            </Row>
            <NotificationToast
              show={show}
              onClose={hideToast}
              message={message}
              error={error}
            />
          </Fragment>
        </Modal.Body>
      </Modal>
      {showMediumEditModal && dropdownSelectedMedia && <MediumEditModal
        modalTitle={`${dropdownSelectedMedia.mediaType === 'inode' ? 'Ordner' : "Datei"} bearbeiten`}
        media={dropdownSelectedMedia}
        onSubmit={handleMediaUpdateSubmit}
        onModalClose={() => {
          setDropdownSelectedMedia(null);
          setShowMediumEditModal(false)
          setIsTopLevelModalOpen(false)
        }}
      >
      </MediumEditModal>
      }
    </>
  );
};

export default MediaPickerModal;
