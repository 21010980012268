import { AxiosError } from "axios";
import { Activity, ApiClient, FieldConfig } from "c1g-ui-library";
import { SelectOption } from "../components/form/SelectWithSearch";
import { Permissions } from "../interfaces";
import { moduleTranslations, PermissionsEnum } from "./enum";

export const formatEuro = (value: number | string) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(Number(value));
};

export const formatNumber = (value: string) => {
  return parseFloat(value).toFixed(2).toString();
};

export const formatGermanDate = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return null;
  }

  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const formattedTime = new Intl.DateTimeFormat('de-DE', optionsTime).format(
    date
  );

  return `${formattedDate} - ${formattedTime} Uhr`;
};

export const formatDateWithoutTime = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return dateStr;
  }
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  return formattedDate;
};

export function formatDate(dateString: string, format: string) {
  const date = new Date(dateString);

  const padZero = (number: number) => (number < 10 ? '0' : '') + number;

  const replacements: any = {
    Y: date.getFullYear(),
    m: padZero(date.getMonth() + 1),
    d: padZero(date.getDate()),
    H: padZero(date.getHours()),
    i: padZero(date.getMinutes()),
    s: padZero(date.getSeconds())
  };

  return format.replace(/Y|m|d|H|i|s/g, match => replacements[match]);
}

export function formatIban(iban: string): string {
  const parts = iban.split(' ');

  if (parts.length <= 2) {
    return iban;
  }

  const firstPart = parts[0];
  const lastPart = parts[parts.length - 1];

  const middleParts = parts.slice(1, parts.length - 1);
  let maskedMiddleParts = middleParts
    .map((part) => 'X'.repeat(part.length))
    .join(' ');

  return `${firstPart} ${maskedMiddleParts} ${lastPart}`;
}

export function normalizeString(str: string) {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div?.textContent!.trim();
}

export function normalizeJsonString(jsonString: string): string {
  const parsedObj = JSON.parse(jsonString);
  const sortedObj = sortObjectKeys(parsedObj);
  return JSON.stringify(sortedObj);
}

export function sortObjectKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = sortObjectKeys(obj[key]);
        return sortedObj;
      }, {} as { [key: string]: any });
  } else {
    return obj;
  }
}

export const generateRandomHex = () => {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join(
    ''
  );
};

export function getModuleList(input: { [key in keyof Permissions]?: boolean } | '*' | null | undefined): string {
  if (input === '*') return '*';

  if (!input || typeof input !== 'object') {
    return '-';
  }

  try {
    const translatedKeys = (Object.keys(input) as Array<keyof Permissions>)
      .filter((key) => key in PermissionsEnum && input[key])
      .map((key) => moduleTranslations[key as PermissionsEnum] || key);

    return translatedKeys.join(', ');
  } catch (error: any) {
    console.error((error as AxiosError).message);
    return '';
  }
}

export function getModuleArray(input: any): { key: string, rights: string[] }[] | string {
  if (!input) return '';

  if (input === '*') return '*';  
  try {
    const parsedInput = input ?? '';
    const keys = Object.keys(parsedInput).map(key => ({
      key,
      rights: Object.entries(parsedInput[key]).filter(([, value]) => value).map(([right]) => right)
    }));
    return keys;
  } catch (error: any) {
    console.error((error as AxiosError).message);
    return [];
  }
}

export function formatApiKey(str: string) {
  if (!str) {
    return ''
  }
  if (str.length <= 10) {
    return str;
  }
  return str.slice(0, 10) + '**********';
}

export const sanitizeInput = (input: string): string => {
  return input.toLowerCase().replace(/[^a-z0-9]/g, '_');
};

/**
 * Retrieves select options for a given resource name from field configurations.
 * 
 * This function extracts options from a `FieldConfig` object that matches
 * the provided `resourceName` within the array of `FieldConfig` objects.
 * It converts the options object into an array of `SelectOption` objects,
 * which contain `value` and `label` pairs suitable for use in a dropdown.
 * 
 * @param {FieldConfig[]} fieldConfigs - An array of field configuration objects.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {SelectOption[]} - An array of `SelectOption` objects where each object
 *   represents a value-label pair from the matching `FieldConfig` options.
 *   Returns an empty array if no matching field configuration or options are found.
 */
export const getFieldOptions = (
  fieldConfigs: FieldConfig[],
  resourceName: string
): SelectOption[] => {
  const options = getFieldConfigByResourceName(fieldConfigs, resourceName)?.options ?? {};
  return Object.entries(options).map(([value, label]) => ({ value, label }));
};

/**
 * Retrieves the label for a given resource name from field configurations.
 * 
 * This function finds a `FieldConfig` object that matches the provided `resourceName`
 * within the array of `FieldConfig` objects and extracts its `fieldLabel`.
 * 
 * @param {FieldConfig[]} fieldConfigs - An array of field configuration objects.
 * @param {string} resourceName - The resource name to look for within the field configurations.
 * 
 * @returns {string} - The `fieldLabel` of the matching `FieldConfig` object.
 *   Returns an empty string if no matching configuration is found.
 */
export const getFieldLabel = (
  fieldConfigs: FieldConfig[],
  resourceName: string
): string => {
  return getFieldConfigByResourceName(fieldConfigs, resourceName)?.fieldLabel || '';
};

export const getFieldConfigByResourceName = (fieldConfigs: FieldConfig[] | undefined, resourceName: string) => {
  if (!fieldConfigs || fieldConfigs?.length === 0) {
    return {} as FieldConfig
  }
  return fieldConfigs.find((config) => config.resourceName === resourceName);
};

/**
 * getInitials Function
 * 
 * Extracts initials from a given name string. This function uses only the first letter of the first and last
 * name in the provided name. Middle names and additional words are ignored, and only the first and last name
 * parts are used.
 * 
 * - If the name contains only one part, the function returns the initial of that part.
 * - If the name is empty or not a string, it returns an empty string.
 * 
 * @param {string} name - The full name string from which to extract initials.
 * @returns {string} A two-letter string containing the initials of the first and last name, or a single letter 
 * if only one name part is provided.
 * 
 * Example Usage:
 * ```typescript
 * getInitials("John Doe") // Returns "JD"
 * ```
 */
export function getInitials(name: string): string {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const nameParts = name.trim().split(' ');
  const filteredNameParts = nameParts.filter(part => part.length > 0);

  if (filteredNameParts.length === 0) {
    return '';
  }

  if (filteredNameParts.length === 1) {
    return filteredNameParts[0][0].toUpperCase();
  }

  const firstInitial = filteredNameParts[0][0]?.toUpperCase() ?? '';
  const lastInitial = filteredNameParts[filteredNameParts.length - 1][0]?.toUpperCase() ?? '';

  return `${firstInitial}${lastInitial}`;
}

/**
 * groupActivitiesByMonthAndYear Function
 * 
 * Groups a list of activities by their creation date, using the month and year as the grouping key.
 * This function first sorts activities in descending order by date, so the most recent month appears first.
 * It then formats the date in "MMMM YYYY" (e.g., "October 2024") and organizes activities 
 * into an object where each key is a formatted month-year string and each value is an array of activities 
 * created during that month.
 * 
 * @param {Activity[]} activities - An array of activity objects, each containing a `timeOfActivity` date property.
 * @returns {Record<string, Activity[]>} An object where each key is a "MMMM YYYY" formatted date string,
 * and each value is an array of activities for that month, with the most recent month appearing at the top.
 * 
 * Example Usage:
 * ```typescript
 * const activities = [
 *   { timeOfActivity: "2024-10-01T12:34:56Z", ... },
 *   { timeOfActivity: "2024-10-15T08:00:00Z", ... },
 *   { timeOfActivity: "2024-11-01T09:00:00Z", ... }
 * ];
 * const grouped = groupActivitiesByMonthAndYear(activities);
 * // Result:
 * // {
 * //   "November 2024": [{...}],
 * //   "October 2024": [{...}, {...}]
 * // }
 * ```
 */
export const groupActivitiesByMonthAndYear = (activities: Activity[]) => {
  const sortedActivities = activities.sort((a, b) => new Date(b.timeOfActivity).getTime() - new Date(a.timeOfActivity).getTime());

  return sortedActivities.reduce((acc, activity) => {
    const date = new Date(activity.timeOfActivity);
    const monthYear = date.toLocaleDateString('de-DE', { year: 'numeric', month: 'long' });
    if (!acc[monthYear]) acc[monthYear] = [];
    acc[monthYear].push(activity);
    return acc;
  }, {} as Record<string, Activity[]>);
};

/**
 * Fetches and combines field configurations from multiple endpoints.
 *
 * This function accepts an array of endpoints, fetches the field configurations
 * from each endpoint concurrently, combines all configurations into a single array,
 * and updates the provided state setter with the combined configurations.
 *
 * The `_name` field is always fetched and saved into a state if provided.
 *
 * @param endpoints - An array of endpoint strings to fetch field configurations from.
 * @param setFieldConfigs - A state setter function to update the field configurations in the component state.
 * @param setNameState - Optional state setter function to save the `_name` string if found.
 */
export const fetchAndCombineFieldConfigs = async (
  endpoints: string[],
  setFieldConfigs: React.Dispatch<React.SetStateAction<FieldConfig[]>>,
  setNameState?: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    const responses = await Promise.all(
      endpoints.map((endpoint) => ApiClient.get(`/${endpoint}/columns`))
    );

    let nameValue: string | null = null;

    const combinedFieldConfigs = responses.flatMap((response) => {
      const data = response.data;
      return Object.keys(data)
        .flatMap((key) => {
          if (key === '_name' && !nameValue) {
            nameValue = data[key];
          }
          const fieldConfigGroup = data[key];
          return fieldConfigGroup ? Object.values(fieldConfigGroup) : [];
        });
    });

    if (setNameState && nameValue) {
      setNameState(nameValue);
    }

    setFieldConfigs(combinedFieldConfigs as FieldConfig[]);
  } catch (error: any) {
    console.error((error as AxiosError).message);
  }
};

/**
 * Adds a specified prefix to each filter in an array of filter strings.
 * 
 * This function ensures that each filter string includes a prefix.
 * If a filter already contains a dot (.), indicating that it already has a prefix,
 * it is returned as-is. Otherwise, the specified prefix is prepended to the filter.
 * 
 * @param {string[]} filters - An array of filter strings to which the prefix will be applied.
 * @param {string} prefix - The prefix to add to each filter string.
 * 
 * @returns {string[]} - A new array of filter strings with the prefix applied where necessary.
 *   Filters that already contain a prefix are not modified.
 */
export function addPrefixToFilters(filters: string[], prefix: string) {
  return filters.map((filter) => {
    return filter.includes('.') ? filter : `${prefix}.${filter}`;
  });
}

/**
 * Copies a given value to the clipboard.
 * If `isJson` is true, the value will be stringified with indentation.
 *
 * @param value - The value to copy. Can be any type if isJson is true, otherwise should be a string.
 * @param isJson - Whether to stringify the value as JSON before copying.
 */
export const copyToClipboard = (value: unknown, isJson: boolean): void => {
  const elem = document.createElement('textarea');
  document.body.appendChild(elem);

  elem.value = isJson ? JSON.stringify(value, null, 4) : String(value);
  elem.select();
  document.execCommand('copy');
  elem.remove();
};