import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useToast } from 'c1g-ui-library';

interface CreateViewModalProps {
    show: boolean;
    handleClose: () => void;
    handleSave: (viewName: string, isGlobal: boolean) => void;
    isGlobal: boolean;
}

// CreateViewModal is a component for adding a new view (either private or global).
// The user provides a name for the view, with a character limit of 15.
// It includes form validation, checking that the name isn't empty and doesn't exceed the limit.
// The modal is controlled via 'show' prop, and the user can either save or cancel their input.
// - 'handleClose' closes the modal without saving.
// - 'handleSave' submits the new view name and whether it should be global or private.
// Feedback is provided to the user through toasts for validation messages.
const CreateViewModal: React.FC<CreateViewModalProps> = ({ show, handleClose, handleSave, isGlobal }) => {
    const [viewName, setViewName] = useState<string>('');
    const [remainingChars, setRemainingChars] = useState<number>(15);
    const inputRef = useRef<HTMLInputElement>(null);
    const { showToast } = useToast();

    const handleSubmit = () => {
        if (viewName.trim() === "") {
            showToast('Name darf nicht leer sein.', true);
        } else if (viewName.length > 15) {
            showToast('Name darf nicht länger als 15 Zeichen sein.', true);
        } else {
            handleSave(viewName, isGlobal);
            handleClose();
            setViewName('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    useEffect(() => {
        if (show) {
            setViewName('');
            setRemainingChars(15);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [show]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setViewName(value);
        setRemainingChars(15 - value.length);
    };

    const getRemainingCharsClass = () => {
        if (remainingChars > 5) return 'text-success';
        if (remainingChars > 2) return 'text-warning';
        return 'text-danger';
    };

    return (
        <Modal
            centered
            size="lg"
            backdrop="static"
            keyboard={true}
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Ansicht hinzufügen ({isGlobal ? 'Global' : 'Privat'})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formViewName">
                        <Form.Label className='text-black'>Name</Form.Label>
                        <Form.Control
                            ref={inputRef}
                            className='text-black bg-grey'
                            type="text"
                            value={viewName}
                            maxLength={15}
                            onChange={handleNameChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                        />
                        <Form.Text className={getRemainingCharsClass()}>
                            {remainingChars} Zeichen verbleibend
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-soft-primary" onClick={handleClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Hinzufügen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateViewModal;
