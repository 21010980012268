import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import oneCareerLogo from '../../../assets/images/onecareer-logo.svg';

interface HeaderProps {
  app_name: string
}

const Header: React.FC<HeaderProps> = ({ app_name }) => {
  const minisidebar = (): void => {
    const asideElement = document.getElementsByTagName('ASIDE')[0];
    if (asideElement) {
      asideElement.classList.toggle('sidebar-mini');
    }
  };

  return (
    <Navbar
      expand="xl"
      className="nav iq-navbar header-hover-menu left-border default"
    >
      <Container fluid className="navbar-inner">
        <Link to="/" className="navbar-brand">
          <img
            className="img-fluid"
            src={oneCareerLogo}
            alt="onecareer-logo"
            loading="lazy"
          />
          <h4 className="logo-title ms-3 mb-0">{app_name}</h4>
        </Link>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
          onClick={minisidebar}
        >
          <i className="icon d-flex">
            <svg width="20px" viewBox="0 0 24 24" className="icon-20">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
